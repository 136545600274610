import React from 'react';
import httpService from './HttpService';
import { saveToLocalStorage } from './localStorageService';
import { BLOCK_LEVELS_LOCALSTORAGE_KEY } from '../configs/constants';



export const addKeysAndValuesToArray = (array, keysToAdd) => {
  // Ensure that the array and keysToAdd are provided
  if (!Array.isArray(array) || !Array.isArray(keysToAdd)) {
    console.error("Invalid input. Please provide an array and keys to add.");
    return array;
  }

  // Iterate through each object in the array
  const newArray = array.map((obj) => {
    // Clone the object to avoid modifying the original array
    const newObj = { ...obj };

    // Add keys and values from keysToAdd to the object
    keysToAdd.forEach(({ key, value }) => {
      newObj[key] = value;
    });

    return newObj;
  });

  return newArray;
}


export const isNull = (variable) => {
  // Check if variable is null or undefined
  if (variable === null || variable === undefined) {
    console.log('Variable is null or undefined');
    return true;
  }

  // Check if variable is an empty string
  if (variable === '') {
    console.log('Variable is an empty string');
    return true;
  }

  // Check if variable is null, undefined, or an empty string
  if (!variable) {
    console.log('Variable is null, undefined, or an empty string');
    return true;
  }
  return false;
}


export const fetchBlockLevels = async () => {
  try {
    const response = await httpService.request('GET', 'onboarding/searchBlockLevels?status=Active');
    console.log('Server Response:', response);
    if (response && response.header) {
      if (response.header.responseCode === 200) {
        saveToLocalStorage(BLOCK_LEVELS_LOCALSTORAGE_KEY, response.body.content)
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (error) {
    // Handle errors here
    console.error('Exception:' + error)
    return false;
  }
}

export const removeObjectsWithNullKey = (array, keyToRemove) => {
  // Ensure that the array is provided
  if (!Array.isArray(array)) {
    console.error("Invalid input. Please provide an array.");
    return array;
  }

  // Use filter to remove objects with the specified key as null
  const newArray = array.filter(obj => obj[keyToRemove] !== null);

  return newArray;
}



export const convertCSVToJson = (csvData) => {
  const lines = csvData.split("\n");
  const headers = lines[0].split(",");
  const result = [];

  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentLine = lines[i].split(",");

    for (let j = 0; j < headers.length; j++) {
      // Check if the currentLine[j] is defined before trimming
      obj[headers[j].trim()] = currentLine[j] ? currentLine[j].trim() : null;
    }

    result.push(obj);
  }

  return result;
};


export const filterArrayByKeyValue = (dataArray, key, value) => {
  if (!Array.isArray(dataArray)) {
    console.error('Input is not an array.');
    return [];
  }

  if (!key || value === undefined) {
    console.error('Key and value are required.');
    return [];
  }

  return dataArray.filter(item => item[key] === value);
};


// export const extractStringsFromArrayForTeachers = (dataArray, key) => {
//   // Check if dataArray is an array
//   if (!Array.isArray(dataArray)) {
//     //console.error('Input is not an array.');
//     return [];
//   }

//   // Check if key is provided
//   if (!key) {
//     //console.error('Key is required.');
//     return [];
//   }

//   // Use map to extract strings based on the specified key
//   const stringArray = dataArray.map(item => {
//     // Check if the item is an object and has the specified key
//     if (typeof item === 'object' && item.hasOwnProperty(key)) { //&& typeof item[key] === 'string'
//       return item[key]+" "+item['lastName'];
//     } else {
//       //console.warn(`Invalid or missing key (${key}) in one or more objects.`);
//       return ''; // Return an empty string for invalid or missing keys
//     }
//   }).filter(str => str !== null && str !== '');

//   return stringArray;
// };



export const extractStringsFromArray = (dataArray, key , secondaryKey = null) => {
  // Check if dataArray is an array
  if (!Array.isArray(dataArray)) {
    //console.error('Input is not an array.');
    return [];
  }

  // Check if key is provided
  if (!key) {
    //console.error('Key is required.');
    return [];
  }

  // Use map to extract strings based on the specified key
  const stringArray = dataArray.map(item => {
    // Check if the item is an object and has the specified key
    if (typeof item === 'object' && item.hasOwnProperty(key)) { //&& typeof item[key] === 'string'
      return secondaryKey ===null ?  item[key] :  item[key] + " "+item[secondaryKey]
    } else {
      //console.warn(`Invalid or missing key (${key}) in one or more objects.`);
      return ''; // Return an empty string for invalid or missing keys
    }
  }).filter(str => str !== null && str !== '');

  return stringArray;
};

export const pickSelectedObject = (dataArray, key, value) => {

  if (!Array.isArray(dataArray)) {
    return {};
  }

  if (!key || !value) {
    return {};
  }

  const selectedObject = dataArray.find(item => {
    return (
      typeof item === 'object' &&
      item.hasOwnProperty(key) &&
      item[key] === value
      // && item.hasOwnProperty('status') &&
      // item.status === 'Active'
    );
  });

  return selectedObject || {};
};


export const extractStringsFromArrayWithActiveStatus = (dataArray, key) => {
  // Check if dataArray is an array
  if (!Array.isArray(dataArray)) {
    //console.error('Input is not an array.');
    return [];
  }

  // Check if key is provided
  if (!key) {
    //console.error('Key is required.');
    return [];
  }

  // Use map to extract strings based on the specified key
  const stringArray = dataArray.map(item => {
    if (
      typeof item === 'object' &&
      item.hasOwnProperty(key) &&
      item.hasOwnProperty('status') &&
      item.status === 'Active'
    ) {
      return item[key];
    } else {
      //console.warn(`Invalid or missing key (${key}) or "status" is not "active" in one or more objects.`);
      return ''; // Return an empty string for invalid or missing keys or inactive status
    }
  }).filter(str => str !== null && str !== '');

  return stringArray;
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
};
import React from 'react'
import { FiArrowRight } from 'react-icons/fi';
import { RiHourglass2Line } from "react-icons/ri";


function LoadingDialog({message, isOpen}) {
  return (
    <div
            className={`fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-300 ${isOpen
                    ? 'opacity-100 backdrop-filter backdrop-blur-md'
                    : 'opacity-0 pointer-events-none'
                }`}
        >
            <div className="bg-white p-8 rounded-lg shadow-md flex flex-col items-center">
            <RiHourglass2Line className="rotate-180 animate-spin h-10 w-10 text-gray-500 transition-transform duration-500 transform " />
                <p className="text-sm  text-gray-500 font-semibold mt-3">{message}</p>
            </div>
        </div>
  )
}

export default LoadingDialog
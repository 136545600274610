import React, { useEffect, useState } from 'react'
import CustomButton from "../../components/CustomButton";
import CustomDropdown from "../../components/CustomDropdown"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import { FaArrowDownUpAcrossLine, FaPlus } from "react-icons/fa6";
import Modal from '../../components/Modal';
import { CiSearch } from "react-icons/ci";
import { IoSearchSharp } from "react-icons/io5";
import { BsArrowRepeat, BsCheck, BsPlus } from "react-icons/bs";
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import SuccessfulDialog from '../../components/SuccessfulDialog';
import { getFromLocalStorage, saveToLocalStorage } from '../../services/localStorageService';
import { ADMIN__ROLE, DELETED_STATUS, FORBIDEN_MESSAGE, INVALID_API_RESPONSE, LOGGED_IN_USER_TOKEN_DETAILS, SCHOOLDATA_LOCALSTORAGE_KEY, SCHOOL_ID_LOCALSTORAGE_KEY, SCHOOL_STREAMS_LOCALSTORAGE_KEY } from '../../configs/constants';
import { extractStringsFromArray, isNull, pickSelectedObject } from '../../services/Utilities';
import httpService from '../../services/HttpService';
import { all } from 'axios';

function Scores() {

  const [openSearchOptions, setOpenSearchOptions] = useState(false);
  const [openActionButtons, setOpenActionButtons] = useState(false);
  const [addExamTotalScore, setAddExamTotalScore] = useState(false);
  const classLevels = ['Pre Primary', 'Lower Primary', 'Upper primary', 'Junior Secondary', 'Senior Secondary'];
  const blockClasses = ['Play Group', 'PP1', 'PP2'];



  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPageAction, setSelectedPageAction] = useState('')
  const [schoolBlockClasses, setSchoolBlockClasses] = useState([]); //getFromLocalStorage(BLOCK_CLASSES_LOCALSTORAGE_KEY)
  const [schoolBlockClassesDropdownOptions, setSchoolBlockClassesDropdownOptions] = useState([]);
  const [selectedSchoolBlockClass, setSelectedSchoolBlockClass] = useState({})
  const [classSubjects, setClassSubjects] = useState([]); //getFromLocalStorage(BLOCK_CLASSES_LOCALSTORAGE_KEY)
  const [classSubjectsFormOptions, setClassSubjectsFormOptions] = useState([])
  const [selectedClassSubject, setSelectedClassSubject] = useState({})
  const [searchScoresParams, setSearchScoresParams] = useState([{ key: 'status', value: 'Active' }, { key: 'term', value: 0 }, { key: 'year', value: 0 }, { key: 'examId', value: 0 }, { key: 'classSubjectId', value: 0 },
  { key: 'classId', value: 0 }, { key: 'streamId', value: 0 }])
  const [searchSubjectParams, setSearchSubjectParams] = useState([{ key: 'schoolId', value: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) },
  { key: 'size', value: '30' }, { key: 'page', value: '0' }, { key: 'status', value: 'Active' }, { key: 'classId', value: '0' }])
  const [exams, setExams] = useState([])
  const [examsDropDownOptions, setExamsDropDownOptions] = useState([])
  const [selectedExam, setSelectedExam] = useState({})
  const [classStreams, setClassStreams] = useState([])
  const [selectedClassStreamOption, setSelectedClassStreamOption] = useState('')
  const [classStreamsOptions, setClassStreamsOptions] = useState([])
  const [selectedClassStream, setSelectedClassStream] = useState({})
  const [schoolDetails, setSchoolDetails] = useState(getFromLocalStorage(SCHOOLDATA_LOCALSTORAGE_KEY))
  const [subjectScores, setSubjectScores] = useState([])
  const [examTotalScore, setExamTotalScore] = useState(0);

  const [loggedInUser, setLoggedInUser] = useState(getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS));
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [userPassword, setUserPassword] = useState("");
  const [allClassStreams , setAllClassStreams] = useState([])

  const processLoginAndAccessToken = async () => {

    if (userPassword.length == 0) {
      setErrorMessage('Please enter your password')
      setShowError(true)
      return;
    }


    try {
      const payLoad = {
        "username": loggedInUser.mobileNumber,
        "password": userPassword
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken //this token isnt really needed
      };
      const response = await httpService.request('POST', 'auth/schoolStaffLogin', payLoad, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          saveToLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS, response.body)
          setLoggedInUser(response.body)
          setIsSessionExpired(false)

          setSuccessMessage("Successfully logged in")
          setShowSuccessMessage(true)

          //action after refreshing token
          fetchBlockClasses()

        }

        else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      console.error('Exception:' + error)
      // setErrorMessage(INVALID_API_RESPONSE);
      // setShowError(true)

      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }


    }
  };



  function updateStudentSubjectScore(subjectScoreId, studentScoreEntered, totalScore) {

    console.log('examTotalScore', totalScore)
    if (parseInt(totalScore) == 0) {
      setErrorMessage("Please set the total score first")
      setShowError(true);
      return;
    }
    if (parseInt(studentScoreEntered) > parseInt(totalScore)) {
      setErrorMessage("Student score cant be greater than the exam's total score, please update")
      setShowError(true);
      return;
    }
    setSubjectScores(prevScores => {
      return prevScores.map(score => {
        if (score.subjectScoreId === subjectScoreId) {
          return {
            ...score,
            studentScore: studentScoreEntered,
            status: studentScoreEntered.length < 1 ? 'Missing' : 'Captured'
          };
        }

        return score;
      });
    });

  }

  useEffect(() => {
    updateExamTotalScore()
  }, [examTotalScore]);

  useEffect(() => {
    setExamsDropDownOptions(extractStringsFromArray(exams, 'examName'))
  }, [exams]);


  



  function updateExamTotalScore() {
    setSubjectScores(prevScores => {
      return prevScores.map(score => {
        return { ...score, totalScores: examTotalScore };
      });
    });

  }


  useEffect(() => {
    fetchBlockClasses()
    //console.log('schoolDetails {{{]}}}} ', schoolDetails)

  }, []);
  useEffect(() => {
    setSchoolBlockClassesDropdownOptions(extractStringsFromArray(schoolBlockClasses, "className"))
  }, [schoolBlockClasses]);

  useEffect(() => {
    console.log('selectedSchoolBlockClass', selectedSchoolBlockClass)
    if (selectedSchoolBlockClass.classId !== undefined) {
      updateOrAddSearchScoresParams('classId', { key: 'classId', value: selectedSchoolBlockClass.classId })
      updateOrAddSearchScoresParams('term', { key: 'term', value: schoolDetails.schoolTerm })
      updateOrAddSearchScoresParams('year', { key: 'year', value: schoolDetails.schoolYear })

    }
    if (selectedSchoolBlockClass.academicBlockId !== undefined) {
      fetchBlockExams()
      // setClassStreamsOptions(extractStringsFromArray(classStreams, 'streamName'))
      updateOrAddSearchSubjectsParams('classId', { key: 'classId', value: selectedSchoolBlockClass.classId });
    }
    if (allClassStreams.length < 1) {
      fetchSchoolStreams()
    }

    setSubjectScores([])
    //filter streams
    setClassStreams(allClassStreams.filter(data => data.classId === selectedSchoolBlockClass.classId && data.schoolAcademicBlockId !== 0))

  }, [selectedSchoolBlockClass]);

  

  useEffect(() => {
    console.log("classStreams", classStreams)
    const filteredStreams = classStreams.filter((stream)=> stream.classId === selectedSchoolBlockClass.classId && stream.schoolAcademicBlockId !== 0);
    setClassStreamsOptions(extractStringsFromArray(filteredStreams, 'streamName'))
  }, [classStreams]);

  useEffect(() => {
    console.log("classStreamsOptions", classStreamsOptions)
  }, [classStreamsOptions]);

  useEffect(() => {
    console.log("allClassStreams", allClassStreams)
  }, [allClassStreams]);

  

  useEffect(() => {
    const filteredStreams = classStreams.filter((stream)=> stream.classId === selectedSchoolBlockClass.classId && stream.schoolAcademicBlockId !== 0);
    setSelectedClassStream(pickSelectedObject(filteredStreams, 'streamName', selectedClassStreamOption))
  }, [selectedClassStreamOption]);

  useEffect(() => {
    console.log('selectedClassStream', selectedClassStream)
    if (!isNull(selectedClassStream.streamId)) {
      updateOrAddSearchScoresParams('streamId', { key: 'streamId', value: selectedClassStream.streamId })
    }

  }, [selectedClassStream]);


  useEffect(() => {
    searchSubjects()
  }, [searchSubjectParams]);

  useEffect(() => {
    console.log('classSubjects >> ', classSubjects)
    setClassSubjectsFormOptions(removeDuplicates(extractStringsFromArray(classSubjects, 'subjectName')))
    // if(classSubjects.length<1){
    //   setErrorMessage("No subjects")
    // }
  }, [classSubjects]);


  function removeDuplicates(arr) {
    // Use a Set to automatically remove duplicates
    const uniqueSet = new Set(arr);
  
    // Convert the Set back to an array
    const uniqueArray = [...uniqueSet];
  
    return uniqueArray;
  }

  useEffect(() => {
    if (selectedClassSubject.classSubjectId !== undefined) {
      setSubjectScores([])
      updateOrAddSearchScoresParams('classSubjectId', { key: 'classSubjectId', value: selectedClassSubject.classSubjectId })
    }
  }, [selectedClassSubject]);

  useEffect(() => {
    if (selectedExam.examId !== undefined) {
      setSubjectScores([])
      updateOrAddSearchScoresParams('examId', { key: 'examId', value: selectedExam.examId })
    }
  }, [selectedExam]);

  useEffect(() => {
    console.log('searchScoresParams', searchScoresParams)
    //console.log('schoolDetails:', schoolDetails)
  }, [searchScoresParams]);

  useEffect(() => {
    if (selectedClassStream.streamId !== undefined) {
      setSubjectScores([])
      updateOrAddSearchScoresParams('streamId', { key: 'streamId', value: selectedClassStream.streamId })
    }
  }, [selectedClassStream]);










  const updateOrAddSearchSubjectsParams = (keyToUpdate, newObject) => {
    const index = searchSubjectParams.findIndex(obj => {
      return obj.key === keyToUpdate;
    });

    if (index !== -1) {
      // If exists, update the existing object
      setSearchSubjectParams(prevParams => [
        ...prevParams.slice(0, index),
        { ...prevParams[index], ...newObject },
        ...prevParams.slice(index + 1),
      ]);
    } else {
      // If doesn't exist, add the new object
      setSearchSubjectParams(prevParams => [...prevParams, newObject]);
    }
  };


  const updateOrAddSearchScoresParams = (keyToUpdate, newObject) => {
    const index = searchScoresParams.findIndex(obj => {
      return obj.key === keyToUpdate;
    });

    if (index !== -1) {
      // If exists, update the existing object
      setSearchScoresParams(prevParams => [
        ...prevParams.slice(0, index),
        { ...prevParams[index], ...newObject },
        ...prevParams.slice(index + 1),
      ]);
    } else {
      // If doesn't exist, add the new object
      setSearchScoresParams(prevParams => [...prevParams, newObject]);
    }
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmAction = () => {
    // Your confirmation action logic here
    console.log('Confirmed action: ', selectedPageAction);
    processPageAction();
    setIsConfirmationOpen(false);
  };

  function processPageAction() {

  }

  const getSearchString = (item, searchParams) => {
    const searchString = searchParams.map((param) => `${param.key}=${param.value}`).join('&');
    return `${item}?${searchString}`; //i.e item="search?"
  };

  const searchSubjects = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      // const response = await httpService.request('GET', getSearchString('onboarding/searchSubjects', searchSubjectParams), null, headers);
      const response = await httpService.request('GET', getSearchString('onboarding/searchTeacherSubjects', searchSubjectParams), null, headers);
      
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          //filter subjects to show only the ones mapped to
          // const filteredSubjects = [];
          // response.body.content.map((subject) => {

          // })
          // setClassSubjects(response.body.content);
          if(loggedInUser.primaryRole === ADMIN__ROLE){
            setClassSubjects(response.body.filter(obj => obj.status !== DELETED_STATUS)); //maths id 95 | 
          }else{
            // if(!isNull(selectedSchoolBlockClass.classId) && response.body.content.length<1){
            //   setErrorMessage("No subjects found! You are either not assigned any subject in "+selectedSchoolBlockClass.className+" or there are no subjects added to "+selectedSchoolBlockClass.className)
            //   setShowError(true)
            // }
            // setClassSubjects(response.body.filter(subject => subject.teacherId === loggedInUser.staffId))
            //setResultsDetailsComputedArray(response.body.data.filter(obj => obj.status !== DELETED_STATUS))
            setClassSubjects(response.body.filter(subject => 
              subject.teacherId === loggedInUser.staffId && subject.classId === selectedSchoolBlockClass.classId && subject.status !== DELETED_STATUS
            ));
            
          }

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }

  const fetchBlockExams = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/getSchoolAcademicBlockExams/' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + '/' + selectedSchoolBlockClass.academicBlockId, null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          //setExams(response.body); //apply filter here by classId
          setExams(response.body.filter((data) => data.classId === selectedSchoolBlockClass.classId));
          // setExamsDropDownOptions(extractStringsFromArray(response.body, 'examName'))
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }

  const fetchBlockClasses = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/getSchoolBlockClasses/' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY), null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSchoolBlockClasses(response.body);

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }

  const fetchSubjectScores = async () => {
    setExamTotalScore('0')
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', getSearchString('exam-analysis/searchScores', searchScoresParams), null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSubjectScores(response.body);

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }


  const crudScores = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'exam-analysis/crudScores', subjectScores, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSuccessMessage("Successfully posted")
          setShowSuccessMessage(true)

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }


  const fetchSchoolStreams = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/searchSchoolStream?schoolId=' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + '&status=Active&size=150', null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setAllClassStreams(response.body.content)
          //setSchoolStreamsArray(response.body);
          //saveToLocalStorage(SCHOOL_STREAMS_LOCALSTORAGE_KEY , response.body)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }





  return (
    <div className='p-5 h-screen bg-gray-100 w-full overflow-y-auto'>
      <Modal isVisible={isSessionExpired} onClose={() => setIsSessionExpired(false)}>
        <div className='flex flex-col p-4'>
          <p className={`text-xs text-red-500`}>Session expired, please login  again to refresh your session</p>
          <div className='grid grid-cols-1 md:grid-cols-2 mt-2 gap-1 md:gap-2'>
            <div className="flex flex-col py-2 ">
              <label className=" text-black text-xs">Phone number</label>
              <input
                value={loggedInUser.mobileNumber}
                id="phone"
                className={` border-red-500 text-xs rounded-lg bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none`}
                type={"phone"}
              ></input>

            </div>
            <div className="flex flex-col py-2">
              <label className="text-black text-xs">Password</label>
              <input
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                id="password"
                className="rounded-lg text-xs bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none"
                type={"password"}
              ></input>
            </div>
          </div>
          <CustomButton
            // bg-gradient-to-b from-[#FEE6F7] to-[#f8aee2]
            buttonStyle={
              "w-full my-5 py-4 bg-blue-400 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
            }
            buttonText={"Login"}
            onClick={() => {
              processLoginAndAccessToken()
            }}
          />

        </div>
      </Modal>
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmAction}
        message={confirmationMessage}
      />
      <LoadingDialog message={'Processing'} isOpen={isLoading} />
      <ErrorDialog message={errorMessage} isOpen={showError} onClose={() => setShowError(false)} />
      <SuccessfulDialog message={successMessage} isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} />

      <Modal isVisible={addExamTotalScore} onClose={() => setAddExamTotalScore(false)}>
        <div className='flex flex-row items-end space-x-2'>
          <div className='w-full'>
            <p className='text-sm'>Set exam total Score</p>
            <input type="text" value={examTotalScore} onChange={(e) => setExamTotalScore(e.target.value)} className='border p-2 rounded-lg w-full' name="" id="" />
          </div>
          {/* <div className={` rounded-lg  py-3 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full  bg-green-600 text-white space-x-1`}>
            <FaPlus />
            <p>Save</p>
          </div> */}
        </div>
      </Modal>
      <div className='flex flex-col'>
        <div id="menu-controller" className="flex w-full justify-end space-x-2 p-2 items-center">
          {/* <div className='flex flex-row space-x-2'>
            <span className=' bg-slate-500 text-white text-sm p-2 rounded-lg'>Term 1</span>
            <span className='bg-slate-500 text-white text-sm p-2 rounded-lg'>Year 2023</span>
          </div> */}
          <div onClick={() => setOpenSearchOptions(!openSearchOptions)} className="flex items-center rounded-lg border p-2 cursor-pointer text-sm bg-white">
            <p className="text-sm text-gray-600">Search options</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openSearchOptions && "rotate-180"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
          <div onClick={() => setOpenActionButtons(!openActionButtons)} className="flex items-center rounded-lg border cursor-pointer p-2 text-sm bg-white">
            <p className="text-sm text-gray-600">Actions</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openActionButtons && "rotate-180"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
        </div>
        <div id="class-filters" className="flex flex-col md:flex-row  items-end space-x-0  md:space-x-4 justify-end  w-full md:w-auto">
          <div className={`flex flex-col md:flex-row items-end space-x-0 md:space-x-4 w-full md:w-auto transition-opacity duration-700 ${!openSearchOptions ? "fade-out" : "fade-in"}`}>

            <div className='text-sm w-full md:w-auto'>
              <p>Select Class</p>
              <CustomDropdown
                options={schoolBlockClassesDropdownOptions}
                onOptionSelect={(valueSelected) => {
                  const selected = pickSelectedObject(schoolBlockClasses, 'className', valueSelected);
                  setSelectedSchoolBlockClass(selected);
                }}
                style={'w-full '} />
            </div>
            <div className='text-sm w-full md:w-auto'>
              <p>Select Exam</p>
              <CustomDropdown
                options={examsDropDownOptions}
                onOptionSelect={(valueSelected) => {
                  const selected = pickSelectedObject(exams, 'examName', valueSelected);
                  setSelectedExam(selected);
                }} style={'w-full '} />
            </div>
            <div className='text-sm w-full md:w-auto'>
              <p>Select Stream</p>
              <CustomDropdown options={classStreamsOptions}
                onOptionSelect={(valueSelected) => {
                  const selected = pickSelectedObject(classStreams, 'streamName', valueSelected);
                  console.log('selected class stream: ', selected)
                  setSelectedClassStream(selected);
                }}
                style={'w-full md:w-40'} />
            </div>
            <div className='text-sm w-full md:w-auto'>
              <p>Select Learning Area</p>
              <CustomDropdown 
              options={classSubjectsFormOptions}
                onOptionSelect={(valueSelected) => {
                  setSelectedClassSubject(pickSelectedObject(classSubjects, 'subjectName', valueSelected))
                }} style={'w-full md:w-40'} />
            </div>

            {/* <div className='text-sm w-full md:w-auto'>
              <p>Select Term</p>
              <CustomDropdown options={schoolAcademicTerms} style={'w-full md:w-20'} />
            </div>
            <div className='text-sm w-full md:w-auto'>
              <p>Select Year</p>
              <CustomDropdown options={academicYears} style={'w-full md:w-28'} />
            </div> */}
            <div onClick={() => {
              //console.log('>>>>>>>>>>>>>>>. check schoolDetails', schoolDetails)

              if(!isNull(selectedSchoolBlockClass.classId) && classSubjects.length<1){
              setErrorMessage("No subjects found! You are either not assigned any subject in "+selectedSchoolBlockClass.className+" or there are no subjects added to "+selectedSchoolBlockClass.className)
              setShowError(true)
            }else

              //first validate if teacher is assigned this subject
              if(selectedClassSubject.teacherId !== loggedInUser.staffId && loggedInUser.primaryRole !== ADMIN__ROLE){
                setErrorMessage("You are not allowed to capture scores for "+selectedClassSubject.subjectName+" in this class.")
                setShowError(true);
              }else{
                fetchSubjectScores()
              }

            }} className="rounded-lg m-2 py-4 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full  md:w-32 bg-green-600 text-white space-x-1">
              <IoSearchSharp />
              <p>View</p>
            </div>
          </div>
          <div className={`${!openActionButtons ? "fade-out" : "fade-in"} w-full md:w-auto  flex flex-col md:flex-row space-x-0  md:space-x-4 items-center justify-end`}>
            <div onClick={() => setAddExamTotalScore(true)} className={` rounded-lg m-2 py-3.5 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full md:w-40 bg-green-600 text-white space-x-1`}>
              <p>Set Exam Total Score</p>
            </div>
            <div onClick={() => {
              if(classSubjects.length<0 || isNull(selectedClassSubject.subjectName)){
                setErrorMessage("No Subject selected")
                setShowError(true);
              }else{
                crudScores()
              }
              
            }

              
              
              } className={` rounded-lg m-2 py-3.5 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full md:w-32 bg-green-600 text-white space-x-1`}>
              <FaPlus />
              <p>Save Scores</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full overflow-auto rounded-lg shadow ">
        <table className="w-full mb-10">
          <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Student Name</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Admission No</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Subject</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Period</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Student Score</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Exam Total Score</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {subjectScores.length == 0 ?

              (<tr className=""  >
                <td className="p-3 text-sm text-gray-700 whitespace-nowrap ">
                  <span className="p-1.5 text-xs font-medium uppercase tracking-wider rounded-lg bg-opacity-50">No Scores found </span>
                </td></tr>)

              : subjectScores.map((score, index) =>
                <tr className="bg-white" key={index} >
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap ">
                    <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{score.studentName}</span>
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap ">
                    <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-blue-200 rounded-lg bg-opacity-50">{score.schoolAdmissionNumber}</span>
                  </td>
                  
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    {selectedClassSubject.subjectName}
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    Term {score.term} {score.year}
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    <input onChange={e => updateStudentSubjectScore(score.subjectScoreId, e.target.value, score.totalScores)} type="number" value={score.status === 'Missing' ? '' : score.studentScore} className='border rounded-lg p-2' />
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    {score.totalScores}
                  </td>
                </tr>

              )}
          </tbody>
        </table>
      </div>




    </div>
  )
}

export default Scores
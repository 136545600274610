import React from 'react'
import { BsXCircleFill } from "react-icons/bs";


function ErrorDisplay({ errorMessage, width, isOpen, onClose }) {
    return (
        <>
        {/* w-full */}
            <div className= {`flex flex-row justify-between items-start bg-red-500 dark:bg-red-500 mb-2 ${width} p-2 rounded-lg duration-300  ${!isOpen ? "fade-out" : "fade-in"}`}> 
            <p className="flex text-xs text-white dark:text-xs mb-2 mx-2 justify-center">{errorMessage}</p>
                <div onClick={onClose} className="flex flex-row justify-end text-white cursor-pointer mr-1 mt-1">
                    <BsXCircleFill />
                </div>
            </div>
        </>
    )
}

export default ErrorDisplay
import React, { useEffect } from "react";
import loginImage from "../assets/element5-digital-OyCl7Y4y0Bk-unsplash.jpg";
import CustomButton from "../components/CustomButton";
import { useState } from "react";
import { saveToLocalStorage } from "../services/localStorageService";
import {
  LOGGED_IN_USER_DATA_OBJECT_KEY, USER_ROLES, INVALID_API_RESPONSE, KENYA_PHONE_NUMBER_REGEX, BLOCK_CLASSES_LOCALSTORAGE_KEY,
  SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY, SCHOOL_STREAMS_LOCALSTORAGE_KEY, USER_ID_LOCALSTORAGE_KEY, SCHOOL_ID_LOCALSTORAGE_KEY, SCHOOL_NAME_LOCALSTORAGE_KEY, SCHOOL_TERM_LOCALSTORAGE_KEY,
  SCHOOL_YEAR_LOCALSTORAGE_KEY, USER_ROLE_LOCALSTORAGE_KEY,SCHOOLDATA_LOCALSTORAGE_KEY
} from '../configs/constants';
import { useNavigate } from "react-router-dom";
import httpService from '../services/HttpService';
import CustomDropdown from "../components/CustomDropdown"
import ErrorDisplay from "../components/ErrorDisplay";


function Login({ onLogin }) {
  const navigate = useNavigate();
  const [userPhone, setUserPhone] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userRole, setUserRole] = useState("")
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);

  const handlePhoneNumberInputChange = (e) => {

    const value = e.target.value;

    setUserPhone(value);
    console.log('hello, im checking entered phone no:' + value)

    // Define your regex pattern
    const regexPattern = KENYA_PHONE_NUMBER_REGEX;

    const isValidInput = regexPattern.test(value);
    setPhoneNumberValid(isValidInput);

  };



  function saveUserLoggedIn() {
    console.log(userPhone)
    console.log(userPassword)
    //saveToLocalStorage(LOGGED_IN_USER_DATA_OBJECT_KEY, { userPhone: userPhone, userPassword: userPassword })
    // navigate('/home')
    //login();

    processLogin()
  }

  const openRegisterPage = () => {
    navigate('/register')
  }

  function handleSubmit(e) {
    e.preventDefault();
    console.log("form submitted");
    // setUserPassword("");
    // setUserPhone("");
  }


  const processLogin = async () => {
    if (userRole.length == 0) {
      setErrorMessage('Please select your role')
      setShowError(true)
      return;
    }
    if (userPhone.length == 0) {
      setErrorMessage('Please enter your phone number')
      setShowError(true)
      return;
    }
    if (userPassword.length == 0) {
      setErrorMessage('Please enter your password')
      setShowError(true)
      return;
    }
    if (!phoneNumberValid) {
      setErrorMessage('Invalid phone number')
      setShowError(true)
      return;
    }
    try {
      const data = {
        "email": "",
        "phoneNumber": userPhone,
        "password": userPassword,
        "role": userRole
      };


      const response = await httpService.request('POST', 'onboarding/login', data);
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {


          saveToLocalStorage(SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY, response.body.academicBlocks)
          saveToLocalStorage(BLOCK_CLASSES_LOCALSTORAGE_KEY, response.body.blockClasses)
          saveToLocalStorage(SCHOOL_STREAMS_LOCALSTORAGE_KEY, response.body.schoolStreams)
          saveToLocalStorage(USER_ID_LOCALSTORAGE_KEY, response.body.teacherId)
          saveToLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY, response.body.schoolId)
          saveToLocalStorage(SCHOOL_NAME_LOCALSTORAGE_KEY, response.body.schoolName)
          saveToLocalStorage(SCHOOL_TERM_LOCALSTORAGE_KEY, response.body.schoolTerm)
          saveToLocalStorage(SCHOOL_YEAR_LOCALSTORAGE_KEY, response.body.schoolYear)
          saveToLocalStorage(USER_ROLE_LOCALSTORAGE_KEY, response.body.role)
          saveToLocalStorage(SCHOOLDATA_LOCALSTORAGE_KEY, response.body.school)
          onLogin();
          navigate('/home')


        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      console.error('Exception:' + error)
      setErrorMessage(INVALID_API_RESPONSE);
      setShowError(true)
    }
  };



  return (
    <div className=" grid grid-cols-1 sm:grid-cols-2 h-screen w-full">
      <div className=" hidden sm:block">
        <img
          className=" w-full h-screen object-cover"
          src={loginImage}
          alt="login"
        ></img>
      </div>

      <div className=" bg-dark-purple flex flex-col items-start justify-center p-6">
        <div className="max-w-[400px] w-full mx-auto">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={`w-6 h-6 bg-amber-300 text-4xl rounded cursor-pointer block float-left mr-2 duration-500 `}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
            />
          </svg>
          <h1
            className={`text-white origin-left font-medium text-2xl duration-300 s`}
          >
            DigiSchool
          </h1>
        </div>
        {/* ErrorDisplay({ errorMessage, width, isOpen, onClose }) { */}
        <form
          onSubmit={handleSubmit}
          className=" max-w-[400px] w-full mx-auto p-8 px-8 bg-light-white mt-4 rounded-lg"
        >
          <ErrorDisplay
            errorMessage={errorMessage}
            width={'w-full'}
            isOpen={showError}
            onClose={() => setShowError(false)}
          />
          <h2 className=" text-xl text-white font-semibold text-start ">
            Login
          </h2>
          <div className=" w-full flex flex-col text-gray-400 py-2 mt-3">
            <p className="text-white">Pick your role</p>
            <CustomDropdown options={USER_ROLES} style={'w-full'} onOptionSelect={setUserRole} />
          </div>
          <div className="flex flex-col text-gray-400 py-2 mt-2">
            <label className=" text-white text-sm">Phone number</label>
            <input
              value={userPhone}
              onChange={handlePhoneNumberInputChange}
              id="phone"
              className={` border-red-500 rounded-lg bg-gray-200 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none`}
              type={"phone"}
            ></input>
            <p className={`text-xs text-red-500 ${phoneNumberValid ? 'fade-out' : 'fade-in'}`}>Invalid phone number</p>
          </div>
          <div className="flex flex-col text-gray-400 py-2">
            <label className="text-white text-sm">Password</label>
            <input
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
              id="password"
              className="rounded-lg bg-gray-200 mt-2 p-2 focus:border-blue-500 focus:bg-gray-800 focus:outline-none"
              type={"password"}
            ></input>
          </div>

          <div className="flex flex-row space-x-2">
            <CustomButton
              buttonStyle={
                "w-1/2 my-5 py-4 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
              }
              buttonText={"Register"}
              onClick={() => openRegisterPage()}
            />
            <CustomButton
              buttonStyle={
                "w-1/2 my-5 py-4 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-xs text-white"
              }
              buttonText={"Log In"}
              onClick={() => saveUserLoggedIn()}
            />
          </div>

          <div className="w-full flex flex-row justify-end text-xs text-white space-x-2">
            <p className="font-semibold">Forgot password?</p>
            <span className=" border-b-2 cursor-pointer">Reset password</span>
          </div>
          {/* <button className=' w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg'>
              Sign In
            </button> */}
        </form>
      </div>
    </div>
  );
}

export default Login;

import React, { useEffect, useState } from 'react'
import CustomButton from "../../components/CustomButton";
import CustomDropdown from "../../components/CustomDropdown"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import { FaPlus } from "react-icons/fa6";
import Modal from '../../components/Modal';
import { CiSearch } from "react-icons/ci";
import { IoSearchSharp } from "react-icons/io5";
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import SuccessfulDialog from '../../components/SuccessfulDialog';
import httpService from '../../services/HttpService';
import { ACTIVE_STATUS, ADMIN__ROLE, DELETED_STATUS, FORBIDEN_MESSAGE, INVALID_API_RESPONSE, LOGGED_IN_USER_TOKEN_DETAILS, ONLY_PERFORMED_BY_ADMIN_MESSAGE, SCHOOL_ID_LOCALSTORAGE_KEY } from '../../configs/constants';
import { extractStringsFromArray, pickSelectedObject } from '../../services/Utilities';
import { getFromLocalStorage, saveToLocalStorage } from '../../services/localStorageService';




function Exams() {

  const [openSearchOptions, setOpenSearchOptions] = useState(false);
  const [openActionButtons, setOpenActionButtons] = useState(false);
  const blockClasses = ['Play Group', 'PP1', 'PP2'];
  // const blockExamsArray = [
  //   {
  //     examId: 1,
  //     academicBlockId: 101,
  //     schoolId: 201,
  //     examName: "Midterm Exam",
  //     status: "ACTIVE",
  //   },
  //   {
  //     examId: 2,
  //     academicBlockId: 102,
  //     schoolId: 201,
  //     examName: "Final Exam",
  //     status: "INACTIVE",
  //   },
  //   // Add more objects as needed
  // ];

  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [schoolBlockClasses, setSchoolBlockClasses] = useState([]); //getFromLocalStorage(BLOCK_CLASSES_LOCALSTORAGE_KEY)
  const [schoolBlockClassesDropdownOptions, setSchoolBlockClassesDropdownOptions] = useState([]);
  const [selectedSchoolBlockClass, setSelectedSchoolBlockClass] = useState({})
  const [searchClassExamsParams, setSearchClassExamsParams] = useState([{ key: 'status', value: 'Active' }, { key: 'size', value: '50' }, { key: 'classId', value: 0 }]) //{key:'academicBlockId',value:0}
  const [blockExamsArray, setBlockExamsArray] = useState([]);
  const [pageAction, setPageAction] = useState('');
  const pageActions = ['addBlockExam', 'editBlockExam', 'deleteBlockExam'];
  const [examName, setExamName] = useState('');
  const [selectedBlockExam, setSelectedBlockExam] = useState({});

  const [loggedInUser, setLoggedInUser] = useState(getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS));
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [userPassword, setUserPassword] = useState("");

  const processLoginAndAccessToken = async () => {

    if (userPassword.length == 0) {
      setErrorMessage('Please enter your password')
      setShowError(true)
      return;
    }


    try {
      const payLoad = {
        "username": loggedInUser.mobileNumber,
        "password": userPassword
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken //this token isnt really needed
      };
      const response = await httpService.request('POST', 'auth/schoolStaffLogin', payLoad, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          saveToLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS, response.body)
          setLoggedInUser(response.body)
          setIsSessionExpired(false)

          setSuccessMessage("Successfully logged in")
          setShowSuccessMessage(true)

          //action after refreshing token
          fetchBlockClasses()

        }

        else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      console.error('Exception:' + error)
      // setErrorMessage(INVALID_API_RESPONSE);
      // setShowError(true)

      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }


    }
  };




  const getSearchString = (item, searchParams) => {
    const searchString = searchParams.map((param) => `${param.key}=${param.value}`).join('&');
    return `${item}?${searchString}`; //i.e item="search?"
  };

  const updateOrAddSearchClassExamsParams = (keyToUpdate, newObject) => {
    const index = searchClassExamsParams.findIndex(obj => {
      return obj.key === keyToUpdate;
    });

    if (index !== -1) {
      // If exists, update the existing object
      setSearchClassExamsParams(prevParams => [
        ...prevParams.slice(0, index),
        { ...prevParams[index], ...newObject },
        ...prevParams.slice(index + 1),
      ]);
    } else {
      // If doesn't exist, add the new object
      setSearchClassExamsParams(prevParams => [...prevParams, newObject]);
    }
  };

  useEffect(() => {
    setSchoolBlockClassesDropdownOptions(extractStringsFromArray(schoolBlockClasses, "className"))
  }, [schoolBlockClasses]);

  useEffect(() => {
    console.log('selectedSchoolBlockClass', selectedSchoolBlockClass)
    if (selectedSchoolBlockClass.classId !== undefined) {
      updateOrAddSearchClassExamsParams('classId', { key: 'classId', value: selectedSchoolBlockClass.classId })
      //updateOrAddSearchClassExamsParams('academicBlockId', {key:'academicBlockId',value:selectedSchoolBlockClass.academicBlockId})
    }
  }, [selectedSchoolBlockClass]);

  useEffect(() => {
    if (selectedSchoolBlockClass.classId !== null && selectedSchoolBlockClass.classId !== undefined) {
      searchExams()
    }
  }, [searchClassExamsParams]);



  useEffect(() => {
    console.log('searchClassExamsParams ---- ', searchClassExamsParams)
  }, [searchClassExamsParams]);


  useEffect(() => {
    fetchBlockClasses()
  }, []);


  const searchExams = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', getSearchString('onboarding/searchBlockExams', searchClassExamsParams), null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setBlockExamsArray(response.body);

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }



  const fetchBlockClasses = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/getSchoolBlockClasses/' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY), null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSchoolBlockClasses(response.body);

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }


  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmAction = () => {
    setIsConfirmationOpen(false);
    processPageAction()
  };

  // private int academicBlockId;
  //   private int schoolId;
  //   private String examName;
  //   @Enumerated(value = EnumType.STRING)
  //   private Status status;

  function processPageAction() {
    if (pageAction == pageActions[0]) { //add
      if (selectedSchoolBlockClass.academicBlockId !== null && selectedSchoolBlockClass.academicBlockId !== undefined) {
        const payload = {
          'academicBlockId': selectedSchoolBlockClass.academicBlockId,
          'schoolId': getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY),
          'examName': examName,
          'status': ACTIVE_STATUS,
          'classId': selectedSchoolBlockClass.classId
        };
        crudBlockExam(payload)
      } else {
        setShowError(true)
        setErrorMessage("Please select class first")
      }

    }
    if (pageAction == pageActions[1]) { //edit
      selectedBlockExam.examName = examName;
      crudBlockExam(selectedBlockExam);
    }
    if (pageAction == pageActions[2]) { //delete
      selectedBlockExam.status = DELETED_STATUS;
      crudBlockExam(selectedBlockExam);
    }
  }

  const crudBlockExam = async (data) => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'onboarding/crud/crudBlockExams', data, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          searchExams()
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }

  return (
    <div className='p-5 h-screen bg-gray-100 w-full overflow-y-auto'>
      <Modal isVisible={isSessionExpired} onClose={() => setIsSessionExpired(false)}>
        <div className='flex flex-col p-4'>
          <p className={`text-xs text-red-500`}>Session expired, please login  again to refresh your session</p>
          <div className='grid grid-cols-1 md:grid-cols-2 mt-2 gap-1 md:gap-2'>
            <div className="flex flex-col py-2 ">
              <label className=" text-black text-xs">Phone number</label>
              <input
                value={loggedInUser.mobileNumber}
                id="phone"
                className={` border-red-500 text-xs rounded-lg bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none`}
                type={"phone"}
              ></input>

            </div>
            <div className="flex flex-col py-2">
              <label className="text-black text-xs">Password</label>
              <input
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                id="password"
                className="rounded-lg text-xs bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none"
                type={"password"}
              ></input>
            </div>
          </div>
          <CustomButton
            // bg-gradient-to-b from-[#FEE6F7] to-[#f8aee2]
            buttonStyle={
              "w-full my-5 py-4 bg-blue-400 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
            }
            buttonText={"Login"}
            onClick={() => {
              processLoginAndAccessToken()
            }}
          />

        </div>
      </Modal>
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmAction}
        message={confirmationMessage}
      />
      <LoadingDialog message={'Processing'} isOpen={isLoading} />
      <ErrorDialog message={errorMessage} isOpen={showError} onClose={() => setShowError(false)} />
      <SuccessfulDialog message={successMessage} isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} />

      <div className='flex flex-col'>
        <div id="menu-controller" className="flex w-full justify-end space-x-2 p-2 ">
          <div onClick={() => setOpenSearchOptions(!openSearchOptions)} className="flex items-center rounded-lg border p-2 cursor-pointer text-sm bg-white">
            <p className="text-sm text-gray-600">Search options</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openSearchOptions && "rotate-180"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
          <div onClick={() => {
            setOpenActionButtons(!openActionButtons)
            setExamName('')
            setPageAction(pageActions[0])
          }} className="flex items-center rounded-lg border cursor-pointer p-2 text-sm bg-white">
            <p className="text-sm text-gray-600">Actions</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openActionButtons && "rotate-180"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
        </div>
        <div id="class-filters" className="flex flex-col md:flex-row  items-end space-x-0  md:space-x-4 justify-end  w-full md:w-auto">
          <div className={`flex flex-col md:flex-row items-end space-x-0 md:space-x-4 w-full md:w-auto transition-opacity duration-300 ${!openSearchOptions && "hidden"}`}>
            <div className='text-sm w-full md:w-auto'>
              <p>Search Exams By Class</p>
              <CustomDropdown
                options={schoolBlockClassesDropdownOptions}
                onOptionSelect={(valueSelected) => {
                  const selected = pickSelectedObject(schoolBlockClasses, 'className', valueSelected);
                  setSelectedSchoolBlockClass(selected);
                }}
                style={'w-full md:w-52'} />
            </div>
            {/* <div className="rounded-lg m-2 py-4 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full md:w-52 bg-green-600 text-white space-x-1">
              <IoSearchSharp />
              <p>View class exams</p>
            </div> */}
          </div>
          <div className={`${!openActionButtons && "hidden"} w-full flex flex-col md:flex-row space-x-0  md:space-x-4  items-center justify-end`}>
            {/* <div className='text-sm w-full md:w-auto'>
              <p>Select Class</p>
              <CustomDropdown options={blockClasses} style={'w-full md:w-52'} />
            </div> */}
            <div className="relative w-full md:w-auto">
              <input type="text" value={examName} onChange={(e) => setExamName(e.target.value)} name='examName' id="examName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
              <label htmlFor="examName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-100 dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Exam name</label>
            </div>
            <div onClick={() => {
               if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                setShowError(true)
              } else {
              processPageAction()
              }
            }
              } className={` rounded-lg m-2 py-3.5 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full md:w-52 bg-green-600 text-white space-x-1`}>
              <FaPlus />
              <p> {pageAction == pageActions[1] ? 'Edit' : 'Add'} Exam</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full overflow-auto rounded-lg shadow ">
        <table className="w-full mb-10">
          <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Exam Name</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {blockExamsArray.length == 0 ?
              (
                <tr className="" >
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    <span className="p-1.5 text-xs font-medium uppercase tracking-wider  rounded-lg bg-opacity-50">No exams to display</span>
                  </td>
                </tr>
              )

              : blockExamsArray.map((exam, index) => (
                <tr className="bg-white" key={index} >
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{exam.examName}</span>
                  </td>
                  <td className="p-3 flex space-x-2">
                    {/* onClick={toggleShowStudentDetailsModal} */}
                    <button onClick={() => {
                       if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                        setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                        setShowError(true)
                      } else {
                      setPageAction(pageActions[1])
                      setSelectedBlockExam(exam)
                      setOpenActionButtons(true)
                      setExamName(exam.examName)
                      }
                    }} className="border p-2 rounded-lg text-sm">Edit</button>
                    <button onClick={() => {
                       if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                        setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                        setShowError(true)
                      } else {
                      setPageAction(pageActions[2])
                      setSelectedBlockExam(exam)
                      setIsConfirmationOpen(true)
                      setConfirmationMessage("Confirm you wannt to delete " + exam.examName + "?")
                      }
                    }} className="border p-2 rounded-lg text-sm">Delete</button>
                  </td>
                </tr>
              ))}

          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Exams
import React, { useEffect, useState, useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import CustomButton from "../components/CustomButton";
import CustomDropdown from "../components/CustomDropdown"
import ConfirmationDialog from "../components/ConfirmationDialog"
import { FaPlus } from "react-icons/fa6";
import Modal from '../components/Modal';
import { CiSearch } from "react-icons/ci";
import { IoHourglassOutline, IoSearchSharp } from "react-icons/io5";
import { BsArrowRepeat, BsCheck, BsFillRecord2Fill, BsCreditCard2Back, BsPeople, BsMortarboard, BsFillCaretDownFill, BsGear, BsLayers, BsFillCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { getFromLocalStorage, saveToLocalStorage } from '../services/localStorageService';
import { ADMIN__ROLE, DELETED_STATUS, FAILED_STATUS, FILES_DOWNLOAD_LINK, FORBIDEN_MESSAGE, GATEWAY_BASE_URL, LOGGED_IN_USER_TOKEN_DETAILS, ONLY_PERFORMED_BY_ADMIN_MESSAGE, PENDING_STATUS, PROCESSED_STATUS, SCHOOLDATA_LOCALSTORAGE_KEY, SCHOOL_ID_LOCALSTORAGE_KEY, SCHOOL_LOGO_IMAGE_CATEGORY, UNAUTHORIZED_MESSAGE } from '../configs/constants';
import httpService from '../services/HttpService';
import { INVALID_API_RESPONSE } from '../configs/constants';
import LoadingDialog from '../components/LoadingDialog';
import ErrorDialog from '../components/ErrorDialog';
import SuccessfulDialog from '../components/SuccessfulDialog';
import httpServicev2 from '../services/HttpServicev2';
import ImageWithLoading from '../components/ImageWithLoading';
import { useNavigate } from 'react-router-dom';



function Dashboard({ logout }) {
  console.log('Dashboard rendered');
  const navigate = useNavigate();
  const [showDropDownMenu, setShowDropDownMenu] = useState(false)
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [actionCode, setActionCode] = useState('');
  const actionCodes = ['LOGOUT', 'SetTerm', 'RegenerateResults', 'sendResultsViaSms'];
  const [editSchoolTermDetails, setEditSchoolTermDetails] = useState(false);
  const [editSchoolDetails, setEditSchoolDetails] = useState(false)
  const [changeSchoolLogo, setChangeSchoolLogo] = useState(false);
  const [schoolData, setSchoolData] = useState(getFromLocalStorage(SCHOOLDATA_LOCALSTORAGE_KEY))
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //https://digischool.app/gateway/exam-analysis/searchResults?schoolId=14&size=40&page=0&classId=0&term=0&year=0
  const [searchResultsParams, setSearchResultsParams] = useState([{ key: 'size', value: '2000' }, { key: 'page', value: '0' }, { key: 'schoolId', value: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) }]);
  const [resultsDetailsComputedArray, setResultsDetailsComputedArray] = useState([])
  const [file, setFile] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [schoologoImage, setSchoologoImage] = useState('');
  const loadingIconUrl = '';
  const [loggedInUser, setLoggedInUser] = useState(getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS));
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [userPassword, setUserPassword] = useState("");
  const [selectedReport, setSelectedReport] = useState({})
  const [recipientEmail, setRecipientEmail] = useState('')
  const [generatedReports, setGeneratedReports] = useState([])
  const [viewAllAnalysisOptions, setViewAllAnalysisOptions] = useState(false);


  useEffect(() => {
    console.log('changed loggedInUser data ', loggedInUser)
    processFetchRecentResults();
  }, [loggedInUser]);

  
  


  const processLoginAndAccessToken = async () => {

    if (userPassword.length == 0) {
      setErrorMessage('Please enter your password')
      setShowError(true)
      return;
    }


    try {
      const payLoad = {
        "username": loggedInUser.mobileNumber,
        "password": userPassword
      };

      const response = await httpService.request('POST', 'auth/schoolStaffLogin', payLoad);
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          console.log('Hey ,i want to call processFetchRecentResults()')
          saveToLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS, response.body)
          setLoggedInUser(response.body)
          setIsSessionExpired(false)
          processFetchRecentResults();
          // setSchoologoImage('http://localhost:8080/onboarding/download/' + schoolData.schoolId + '/SchoolLogo')
          setSchoologoImage(GATEWAY_BASE_URL + '/onboarding/download/' + schoolData.schoolId + '/SchoolLogo')
          setSuccessMessage("Successfully logged in")
          setShowSuccessMessage(true)
        }

        else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      console.error('Exception:' + error)
      setErrorMessage(INVALID_API_RESPONSE);
      setShowError(true)
    }
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedImage(file);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });
  const handleSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append('image', selectedImage);
      // Handle success or navigate to another page
      console.log('Image successfully submitted');
    } catch (error) {
      console.error('Error submitting image', error);
      // Handle error accordingly
    }
  };



  // Function to add or update an object in the searchResultsParams state
  const addOrUpdateParam = (key, value) => {
    setSearchResultsParams((prevParams) => {
      const paramIndex = prevParams.findIndex((param) => param.key === key);

      // If the key already exists, update the value
      if (paramIndex !== -1) {
        const updatedParams = [...prevParams];
        updatedParams[paramIndex] = { key, value };
        return updatedParams;
      }

      // If the key doesn't exist, add a new object
      return [...prevParams, { key, value }];
    });
  };


  const getResultsSearchString = () => {
    const searchString = searchResultsParams.map((param) => `${param.key}=${param.value}`).join('&');
    return `exam-analysis/searchResults?${searchString}`;
  };

  const processFetchRecentResults = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const completeSearchString = getResultsSearchString();
      const response = await httpService.request('GET', completeSearchString, null, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {

          //setResultsDetailsComputedArray(response.body.data)
          //setResultsDetailsComputedArray(response.body.data.filter(obj => obj.status !== DELETED_STATUS))
          setResultsDetailsComputedArray(response.body.data.filter(obj => obj.status !== DELETED_STATUS))

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };

  function handleRefresh() {
    window.location.reload();
  }


  const uploadSchoolLogo = async () => {
    //const apiUrl = 'http://localhost:8080/onboarding/upload/school-logo';

    // Create a FormData object
    const formData = new FormData();

    // Append the file and other form data
    formData.append('image', selectedImage); // Assuming 'file' is the selected file
    formData.append('mapperId', schoolData.schoolId);
    formData.append('imageCategory', SCHOOL_LOGO_IMAGE_CATEGORY);

    try {
      setIsLoading(true)
      const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      // Make a POST request using the fetch API
      const response = await httpServicev2.request('POST', 'onboarding/upload/school-logo', formData, headers);

      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          setSuccessMessage("Successfully updated")
          setShowSuccessMessage(true)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }
    } catch (error) {
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };

  const crudSchool = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'onboarding/crud/School', schoolData, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          saveToLocalStorage(SCHOOLDATA_LOCALSTORAGE_KEY, schoolData)
          setEditSchoolTermDetails(false)


          //show success message
          setSuccessMessage("Successfully updated")
          setShowSuccessMessage(true)

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };




  const confirmLogOut = () => {
    setConfirmationMessage('Confirm you want to logout?');
    setActionCode(actionCodes[0]);
    setShowDropDownMenu(false)
    setIsConfirmationOpen(true);
    logout();

  }

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmAction = () => {
    // Your confirmation action logic here
    console.log('Confirmed action');
    if (actionCode === actionCodes[2]) { //regenerate results
      regenerateAnalysis()
    }
    if (actionCode === actionCodes[3]) { //regenerate results
      sendResultsViaSms()
    }
    setIsConfirmationOpen(false);
  };

  // showDropDownMenu

  const schoolAcademicTerms = ['1', '2', '3'];
  const examsIncluded = ['MidTerm, Term 1 2022', 'End Term, Term 1 2022'];
  const academicYears = ['2024', '2025', '2026', '2027'];



  useEffect(() => {
    processFetchRecentResults();
    // setSchoologoImage('http://localhost:8080/onboarding/download/' + schoolData.schoolId + '/SchoolLogo')
    setSchoologoImage(GATEWAY_BASE_URL + '/onboarding/download/' + schoolData.schoolId + '/SchoolLogo')
  }, []);

  useEffect(() => {
    console.log('changed school data ', schoolData)
  }, [schoolData]);

  useEffect(() => {
    if (!changeSchoolLogo) {
      setSelectedImage(null)
    }
  }, [changeSchoolLogo]);

  const regenerateAnalysis = async (results) => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'exam-analysis/regenerateAnalysis/' + selectedReport.resultsId, null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {

          processFetchRecentResults();
          setSuccessMessage("Successfull. Please hold as we process your request , you will be notified at " + schoolData.email);
          setShowSuccessMessage(true);

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }


  function updateSchoolData(valueKey, value) {
    setSchoolData(prevData => {
      return {
        ...prevData,
        [valueKey]: value  // Use [valueKey] to use variable as key
      };
    });
  }





  useEffect(() => {

    // Your code here to run when editSchoolTermDetails changes
    console.log('editSchoolTermDetails changed:', editSchoolTermDetails);
    if (editSchoolTermDetails) {
      setShowDropDownMenu(false);
    }


    // Cleanup function (optional)
    return () => {
      // Code to run when component unmounts or when editSchoolTermDetails changes
      console.log('Cleanup or effect for unmounting');
    };
  }, [editSchoolTermDetails]);

  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
    setSelectedFile(e.target.files[0]);
  }

  const fetchResultDocuments = async (resultsId) => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'exam-analysis/getResultsAllGeneratedFiles/' + resultsId, null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setGeneratedReports(response.body)
          setViewAllAnalysisOptions(true)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }

  const openNewTab = (url) => {
    window.open(url, '_blank');
  }

  const sendResultsViaSms = async (results) => {
    setActionCode('')
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'exam-analysis/requestSendResultsViaSms', selectedReport, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {

          setSuccessMessage(response.header.customerMessage);
          setShowSuccessMessage(true);

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }





  return (

    <div className='p-5 h-screen bg-gray-100 w-full overflow-y-auto'>

      <Modal isVisible={viewAllAnalysisOptions} onClose={() => setViewAllAnalysisOptions(false)}>
        <div className="m-6 flex flex-col">
          <div className=' border-b-4'>
            <p className='text-sm mb-3'>Download Analysis Files</p>
          </div>
          {/* <div className='flex flex-col text-xs'>
            <p>Recipient Email</p>
            <div className="relative w-1/2 mb-2.5 mt-5">
              <input value={recipientEmail} onChange={(e) => setRecipientEmail(e.target.value)} type="text" name='recipientEmail' id="recipientEmail" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
              <label htmlFor="recipientEmail" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Recipient Email</label>
            </div>
          </div> */}
          {/* <div className='max-h-96 overflow-auto'>
            <p className=' text-9xl'>hello</p>
            <p className=' text-9xl'>hello</p>
            <p className=' text-9xl'>hello</p><p className=' text-9xl'>hello</p>
            <p className=' text-9xl'>hello</p>
            <p className=' text-9xl'>hello</p>

          </div> */}
          <div className='max-h-96 overflow-auto grid grid-cols-1 md:grid-cols-2 gap-3 mt-3'>
            {/* <div className='flex flex-wrap mt-3'> */}

            {generatedReports.map((generatedReport, index) => (
              <div
                onClick={() => {
                  console.log('generatedReport details ', generatedReport)
                  setViewAllAnalysisOptions(false)
                  if (generatedReport.status == PROCESSED_STATUS) {
                    //hit download funnction here
                    openNewTab(FILES_DOWNLOAD_LINK + generatedReport.generatedDocument)
                  } else {

                    setErrorMessage("File not ready for download")
                    setShowError(true)
                  }
                }}
                className={`shadow rounded-lg border-2 px-6 py-10  ${generatedReport.status !== PROCESSED_STATUS ? 'bg-grey-200 text-black' : 'bg-blue-700 text-white cursor-pointer'} `}
                key={index}
              >
                <p className='text-xs flex-wrap'>{generatedReport.examAnalysisOption}</p>
                <p className={`${!generatedReport.filterByClassStream && 'hidden'} text-xs`}> ({generatedReport.streamName})</p>
              </div>
            ))}
          </div>
        </div>
      </Modal>


      <Modal isVisible={isSessionExpired} onClose={() => setIsSessionExpired(false)}>
        <div className='flex flex-col p-4'>
          <p className={`text-xs text-red-500`}>Session expired, please login  again to refresh your session</p>
          <div className='grid grid-cols-1 md:grid-cols-2 mt-2 gap-1 md:gap-2'>
            <div className="flex flex-col py-2 ">
              <label className=" text-black text-xs">Phone number</label>
              <input
                value={loggedInUser.mobileNumber}
                id="phone"
                className={` border-red-500 text-xs rounded-lg bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none`}
                type={"phone"}
              ></input>

            </div>
            <div className="flex flex-col py-2">
              <label className="text-black text-xs">Password</label>
              <input
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                id="password"
                className="rounded-lg text-xs bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none"
                type={"password"}
              ></input>
            </div>
          </div>
          <CustomButton
            // bg-gradient-to-b from-[#FEE6F7] to-[#f8aee2]
            buttonStyle={
              "w-full my-5 py-4 bg-blue-400 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
            }
            buttonText={"Login"}
            onClick={() => {
              processLoginAndAccessToken()
            }}
          />
          {/* <div className='flex flex-row items-center justify-end  w-full'>
            <p onClick={()=>{
              localStorage.clear()
              navigate('/');           
               }} className='border-b text-xs cursor-pointer'>Log out here</p>
          </div> */}

        </div>
      </Modal>
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmAction}
        message={confirmationMessage}
      />
      <LoadingDialog message={'Processing'} isOpen={isLoading} />
      <ErrorDialog message={errorMessage} isOpen={showError} onClose={() => setShowError(false)} />
      <SuccessfulDialog message={successMessage} isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} />
      <Modal isVisible={changeSchoolLogo} onClose={() => setChangeSchoolLogo(false)}>
        <div className='flex flex-col'>
          <p className='text-xs border-b-2 p-2'>Upload School Logo</p>

          <div>
            <div {...getRootProps()} style={dropzoneStyles}>
              <input {...getInputProps()} />
              <p>Drag & drop an image here, or click to select one</p>
            </div>
            {selectedImage && (
              <div>
                <img src={URL.createObjectURL(selectedImage)} alt="Selected" style={imageStyles} />
                {/* <button onClick={handleSubmit}>Submit Image</button> */}
                {selectedImage && (
                  <CustomButton
                    buttonStyle={
                      "w-full  my-5 py-4 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-xs text-white"
                    }
                    buttonText={"Submit"}
                    onClick={() => {
                      setChangeSchoolLogo(false);
                      uploadSchoolLogo()
                    }}
                  />
                )}
              </div>
            )}
          </div>

          {/* <div className='flex flex-col items-center text-xs'>
            <img className='m-10 max-h-72 max-w-72' src={file} />
            <input type="file" onChange={handleChange} accept=".jpg, .jpeg, .png, .webp" />
            {file && (
              <CustomButton
                buttonStyle={
                  "w-full max-w-60 my-5 py-4 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-xs text-white"
                }
                buttonText={"Submit"}
                onClick={() =>{
                  setChangeSchoolLogo(false);
                  uploadSchoolLogo()
                }}
              />
            )}
          </div> */}
        </div>
      </Modal>

      <Modal isVisible={editSchoolTermDetails} onClose={() => setEditSchoolTermDetails(false)}>
        <div className='flex flex-col'>
          <div className=' border-b-2 p-2'>
            <p className='text-xs'>Edit school term details</p>
          </div>
          <div className='grid grid-cols-2 gap-2 mx-1 mt-4'>
            <div className='flex flex-col text-xs'>
              <p>School Closing Day</p>
              <div className="relative w-full mb-2.5 mt-5">

                <input value={schoolData.closingDay} onChange={(e) => updateSchoolData("closingDay", e.target.value)} type="text" name='closingDay' id="closingDay" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="closingDay" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Closing Day</label>
              </div>
            </div>
            <div className='flex flex-col text-xs'>
              <p>School Opening Day</p>
              <div className="relative w-full mb-2.5 mt-5">

                <input value={schoolData.openingDay} onChange={(e) => updateSchoolData("openingDay", e.target.value)} type="text" name='openingDay' id="openingDay" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="openingDay" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Opening Day</label>
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-2 mx-1 mt-4'>
            <div className='w-full'>
              <p className='text-xs'>Select Term</p>
              <CustomDropdown options={schoolAcademicTerms} style={'w-full'}
                onOptionSelect={(selectedTerm) => {
                  setSchoolData(prevSchoolData => {
                    return { ...prevSchoolData, schoolTerm: selectedTerm };
                  })
                }} />
            </div>
            <div className='w-full'>
              <p className='text-xs'>Select Year</p>
              <CustomDropdown options={academicYears} style={'w-full '}
                onOptionSelect={(selectedYear) => {
                  setSchoolData(prevSchoolData => {
                    return { ...prevSchoolData, schoolYear: selectedYear };
                  })
                }} />
            </div>
          </div>
          <div className=' mt-0'>
            <CustomButton
              buttonStyle={
                "w-full my-5 py-4 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-xs text-white"
              }
              buttonText={"Save"}
              onClick={() => { crudSchool() }}
            />
          </div>
        </div>
      </Modal>
      <Modal isVisible={editSchoolDetails} onClose={() => setEditSchoolDetails(false)}>
        <div className='flex flex-col'>
          <div className=' border-b-2 p-2'>
            <p className='text-xs'>Edit school details</p>
          </div>
          <div className='grid grid-cols-2 gap-2 mx-1 mt-4'>
            <div className='flex flex-col text-xs'>
              <p>Email</p>
              <div className="relative w-full mb-2.5 mt-3">

                <input value={schoolData.email} onChange={(e) => updateSchoolData("email", e.target.value)} type="text" name='email' id="email" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="email" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Email</label>
              </div>
            </div>
            <div className='flex flex-col text-xs'>
              <p>Phone Number</p>
              <div className="relative w-full mb-2.5 mt-3">

                <input value={schoolData.phoneNumber} onChange={(e) => updateSchoolData("phoneNumber", e.target.value)} type="text" name='phoneNumber' id="phoneNumber" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="phoneNumber" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Phone Number</label>
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-2 mx-1 mt-4'>
            <div className='flex flex-col text-xs'>
              <p>School Address Details</p>
              <div className="relative w-full mb-2.5 mt-3">

                <input value={schoolData.addressDetails} onChange={(e) => updateSchoolData("addressDetails", e.target.value)} type="text" name='addressDetails' id="addressDetails" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="addressDetails" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Address Details</label>
              </div>
            </div>
            <div className='flex flex-col text-xs'>
              <p>School Motto</p>
              <div className="relative w-full mb-2.5 mt-3">

                <input value={schoolData.motto} onChange={(e) => updateSchoolData("motto", e.target.value)} type="text" name='motto' id="motto" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="motto" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Motto</label>
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-2 mx-1 mt-4'>
            <div className='flex flex-col text-xs'>
              <p>BulkSms Sender Id</p>
              <div className="relative w-full mb-2.5 mt-3">
                <input value={schoolData.senderId} readOnly onChange={(e) => updateSchoolData("senderId", e.target.value)} type="text" name='senderId' id="senderId" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="senderId" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Sms Sender Id</label>
              </div>
            </div>

          </div>
          <div className=' mt-0'>
            <CustomButton
              buttonStyle={
                "my-2 text-xs w-full text-white px-4 py-4 cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
              }
              buttonText={"Save"}
              onClick={() => {
                setEditSchoolDetails(false)
                crudSchool()
              }}
            />

          </div>
        </div>
      </Modal>

      <div id='dropdown-menu-bar' className='fixed top-0 right-0 p-2  flex flex-col space-y-2 items-end'>
        <div onClick={() => setShowDropDownMenu(!showDropDownMenu)} className='bg-white bg-opacity-90 flex flex-row border cursor-pointer p-2 md:p-4 items-center space-x-2 rounded-lg text-sm border-green-600'>
          <ImageWithLoading imageUrl={schoologoImage} loadingIconUrl={loadingIconUrl} />
          <p>{schoolData.schoolName}</p>
          <div className={`duration-300 ${showDropDownMenu && 'rotate-180'}`}>
            <BsFillCaretDownFill />
          </div>
        </div>
        <div id='list-options' className={`w-48 shadow-md ${showDropDownMenu ? 'fade-in' : 'fade-out'}`}>
          <div className='border p-2 bg-white rounded-lg w-48'>
            <p className=' text-xs'>School Term Details</p>
            <p className='text-sm'>Term {schoolData.schoolTerm} Year {schoolData.schoolYear}</p>
          </div>
          <ul className='space-y-1 mt-2'>
            <li onClick={() => {
              if (loggedInUser.primaryRole === ADMIN__ROLE) {
                setShowDropDownMenu(false);
                setChangeSchoolLogo(true)
              }

            }} className='border p-2 w-full bg-slate-200 rounded-lg text-sm flex space-x-2 items-center hover:bg-black hover:text-white cursor-pointer'>
              <BsGear />
              <p className=''>Change School Logo</p>
            </li>
            <li onClick={() => {
              if (loggedInUser.primaryRole === ADMIN__ROLE) {
                setEditSchoolTermDetails(true)
              }
            }} className='border p-2 w-full bg-slate-200 rounded-lg text-sm flex space-x-2 items-center hover:bg-black hover:text-white cursor-pointer'>
              <BsGear />
              <p className=''>Edit term Details</p>
            </li>
            <li onClick={() => {
              if (loggedInUser.primaryRole === ADMIN__ROLE) {
                setEditSchoolDetails(true)
              }
            }} className='border p-2 w-full bg-slate-200 rounded-lg text-sm flex space-x-2 items-center hover:bg-black hover:text-white cursor-pointer'>
              <BsGear />
              <p className=''>Edit Other School Details</p>
            </li>
            <li onClick={() => confirmLogOut()} className='border p-2 w-full bg-slate-200 rounded-lg flex space-x-2 items-center hover:bg-black hover:text-white cursor-pointer'>
              <div className='text-2xl'>
                <BsFillRecord2Fill />
              </div>
              <p className='text-sm'>Logout</p>
            </li>
          </ul>
        </div>
      </div>

      <div className='flex flex-col mt-8  md:flex-row space-x-0 md:space-x-2 space-y-2 md:space-y-0'>
        <div className='bg-yellow-500 p-7 rounded-lg text-white shadow w-full md:w-60 flex justify-between duration-200 hover:shadow-lg hover:bg-yellow-600 '>
          <div>
            <p className=' text-2xl'><span className=' font-semibold'>Ksh</span> {schoolData.floatBalance}</p>
            <p>Sms Balance</p>
          </div>
          <div className='text-3xl'>
            <BsCreditCard2Back />
          </div>
        </div>
        <div className=' bg-blue-400 p-7 rounded-lg text-white shadow w-full md:w-60 flex justify-between duration-200 hover:shadow-lg hover:bg-blue-600'>
          <div>
            <p className=' text-2xl'><span className='font-semibold'>{schoolData.totalStudents}</span></p>
            <p>Students</p>
          </div>
          <div className='text-3xl'>
            <BsMortarboard />
          </div>
        </div>
        <div className=' bg-green-400 p-7 rounded-lg text-white shadow w-full md:w-60 flex justify-between duration-200 hover:shadow-lg hover:bg-green-600'>
          <div>
            <p className=' text-2xl'>{schoolData.totalTeachers}</p>
            <p>Teachers</p>
          </div>
          <div className='text-3xl'>
            <BsPeople />
          </div>
        </div>
        <div className=' bg-red-400 p-7 rounded-lg text-white shadow w-full md:w-60 flex justify-between duration-200 hover:shadow-lg hover:bg-red-600'>
          <div>
            <p className=' text-2xl'>{schoolData.totalReportsGenerated}</p>
            <p>Reports</p>
          </div>
          <div className='text-3xl'>
            <BsLayers />
          </div>
        </div>
      </div>



      <div className="w-full overflow-auto rounded-lg shadow ">
        <table className="w-full mb-10">
          <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Results Title</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Period</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Computation</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Exams Included</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Total L. Areas</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Ranking Option</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Progress Status</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Status</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {resultsDetailsComputedArray.length == 0 ?

              (<tr className=""  >
                <td className="p-3 text-xs text-gray-700 whitespace-nowrap ">
                  <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">No results to display</span>
                </td></tr>)



              : resultsDetailsComputedArray.map((results, index) =>
                <tr className="bg-white" key={index} >
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap ">
                    <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{results.resultsTitle}</span>
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    {"Term " + results.term + "," + results.year}
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    <span className={`p-1.5 text-xs font-medium uppercase tracking-wider text-green-800  ${results.computeToHundred ? 'bg-green-200' : 'bg-blue-400'} rounded-lg bg-opacity-50`}>{results.computeToHundred ? 'To 100%' : 'Exam total score'}</span>
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    {results.examsIncludedInResults.map((exam, index) =>

                      <p>
                        <span className='p-1 font-serif font-semibold'>{index + 1}. {exam.examName} { results.computeToHundred ? '('+exam.coveragePercentage+'%)' : ""}</span>
                        <p className='text-xs'>Term {exam.term} Year {exam.year}</p>
                      </p>
                    )}
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    {results.totalRankingSubjects}
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    By {results.rankingOption}
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    <div className='flex flex-row space-x-2 items-center'>
                      <p>{results.status}</p>
                      {results.status == PENDING_STATUS && (<IoHourglassOutline className="rotate-180 animate-spin h-4 w-4 text-gray-500 transition-transform duration-500 transform " />)}
                      {results.status == PROCESSED_STATUS && (<BsFillCheckCircleFill className=" h-4 w-4 text-gray-500 transition-transform duration-500 transform " />)}
                      {results.status == FAILED_STATUS && (<BsXCircleFill className="  h-4 w-4 text-gray-500 " />)}
                    </div>
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    <span>{results.statusDescription}</span>
                  </td>
                  <td className="p-3 flex space-x-2">
                    {/* onClick={toggleShowStudentDetailsModal} */}

                    {/* <button
                      onClick={() => {
                        if (loggedInUser.primaryRole === ADMIN__ROLE) {
                          setSelectedReport(results)
                          setActionCode(actionCodes[2])
                          setIsConfirmationOpen(true)
                          setConfirmationMessage("Confirm you want to regenerate " + results.resultsTitle + "?")
                        } else {
                          setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                          setShowError(true)
                        }

                      }}
                      className="border p-2 rounded-lg text-xs">Regenerate</button> */}

                    <button
                      onClick={() => {

                        if (results.status !== PROCESSED_STATUS) {
                          const messaage = results.status === PENDING_STATUS ?
                            "Your files arent ready for download, please hold on as we process. Thank you" :
                            "We couldnt process your data, please try to regenerate , if problem persists kindly contact us for assistance. Thank you";
                          setErrorMessage(messaage)
                          setShowError(true);
                        } else {
                          setSelectedReport(results)
                          setRecipientEmail(schoolData.email)
                          fetchResultDocuments(results.resultsId)
                        }

                      }}
                      className="border p-2 rounded-lg text-xs">Download Files</button>
                    <button
                      onClick={() => {
                        if (loggedInUser.primaryRole !== ADMIN__ROLE) {

                          setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                          setShowError(true)

                        } else {
                          if (results.status !== PROCESSED_STATUS) {
                            setErrorMessage("You can only send sms after results are fully generated.")
                            setShowError(true);
                          } else {
                            setSelectedReport(results)
                            setActionCode(actionCodes[3])
                            setIsConfirmationOpen(true)
                            setConfirmationMessage("Do you really want to send this messages to guardians?")
                          }
                        }
                      }}
                      className='border p-2 rounded-lg text-xs'>Send Sms</button>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>

    </div>


  )
}

const dropzoneStyles = {
  border: '2px dashed #888',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
};

const imageStyles = {
  maxWidth: '100%',
  marginTop: '10px',
};

export default Dashboard
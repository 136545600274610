import React from 'react'

function StudentDetailsModal({ isVisible , onClose }) {

    const handleCloseDialog = (e) => {
        if(e.target.id === 'modal') {
          onClose();
        }
      }

    if(!isVisible){return null}
    return (
        <div onClick={handleCloseDialog} id="modal" className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center">
            <div className="w-[600px] flex flex-col">
                <button onClick={onClose} class="text-sm bg-white rounded-full w-6 h-6 p-1 m-2 flex items-center place-self-end">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round"  d="M6 18 18 6M6 6l12 12" />
                    </svg>

                </button>
                <div class="bg-white p-2 rounded">
                    Modal
                </div>
            </div>
        </div>
    );
}

export default StudentDetailsModal
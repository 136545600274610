import React, { useEffect, useState } from 'react'
import CustomButton from "../../components/CustomButton";
import CustomDropdown from "../../components/CustomDropdown"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import { FaPlus } from "react-icons/fa6";
import Modal from '../../components/Modal';
import { CiSearch } from "react-icons/ci";
import { IoSearchSharp } from "react-icons/io5";
import { BsArrowRepeat, BsCheck, BsPlus, BsFillChatTextFill } from "react-icons/bs";
import CustomDropdownWithCancelIcon from '../../components/CustomDropdownWithCancelIcon';
import { extractStringsFromArray, isNull, pickSelectedObject } from '../../services/Utilities';
import { FORBIDEN_MESSAGE, INVALID_API_RESPONSE, LOGGED_IN_USER_TOKEN_DETAILS, SCHOOLDATA_LOCALSTORAGE_KEY, SCHOOL_ID_LOCALSTORAGE_KEY } from '../../configs/constants';
import { getFromLocalStorage, saveToLocalStorage } from '../../services/localStorageService';
import httpService from '../../services/HttpService';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import SuccessfulDialog from '../../components/SuccessfulDialog';

function Sms() {
  const [userPassword, setUserPassword] = useState("");
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [schoolData, setSchoolData] = useState(getFromLocalStorage(SCHOOLDATA_LOCALSTORAGE_KEY))
  const [loggedInUser, setLoggedInUser] = useState(getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS));
  const [openSearchOptions, setOpenSearchOptions] = useState(false);
  const [openActionButtons, setOpenActionButtons] = useState(false);
  const [selectedBlockClassOption, setSelectedBlockClassOption] = useState('');
  const [selectedBlockClass, setSelectedBlockClass] = useState({})
  const [paginationTotalPages, setPaginationTotalPages] = useState(0)
  const [paginationPageNo, setPaginationPageNo] = useState(1)
  const [paginationPageSize, setPaginationPageSize] = useState(30);
  const [schoolBlockClassesDropdownOptions, setSchoolBlockClassesDropdownOptions] = useState([]);
  const [schoolClasses, setSchoolClasses] = useState([])
  const [searchSmsParams, setSearchSmsParams] = useState([{key: 'schoolId' , value:getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY)}]);
  const [smsRecipient, setSmsRecipient] = useState("");
  const [smsStudentIdentifier, setSmsStudentIdentifier] = useState("")
  const [searchSms, setSearchSms] = useState("");
  const [sms , setSms] = useState([])

  // const [resultsTitles , setResultsTitles] = useState

  //const resultsTitles = ['Form One Results','Form Two Results'];
  const messageTitles = ['BOG Meeting invite', 'Fundraising Invite', 'Form One Result'];
  const blockClasses = ['PP1', 'PP2'];
  const schoolAcademicTerms = ['1', '2', '3'];
  const academicYears = ['2024', '2025', '2026'];
  const resultsDetailsComputedArray = [
    {
      resultsId: 1,
      resultsTitle: "First Result",
      classId: 101,
      schoolId: 201,
      term: 2,
      year: 2022,
      addedBy: 301,
      totalRankingSubjects: 5,
      computeToHundred: true,
      subjectResultsComputed: false,
      subjectRankingComputed: false,
      overallRankingComputed: false,
      status: "ACTIVE",
      rankingOption: "Marks",
      statusDescription: "Record is active",
      dateCreated: new Date("2022-01-01"),
      lastModified: new Date("2022-01-02"),
    },
    {
      resultsId: 2,
      resultsTitle: "Second Result",
      classId: 102,
      schoolId: 202,
      term: 1,
      year: 2023,
      addedBy: 302,
      totalRankingSubjects: 4,
      computeToHundred: false,
      subjectResultsComputed: true,
      subjectRankingComputed: true,
      overallRankingComputed: true,
      status: "INACTIVE",
      rankingOption: "Points",
      statusDescription: "Record is inactive",
      dateCreated: new Date("2023-02-15"),
      lastModified: new Date("2023-02-20"),
    },
    // Add more objects as needed
  ];
  const messagesSent = [
    {
      title: "Title",
      level: "Level",
      class: "Class",
      message: "Message",
      recipient: "Recipient",
      status: "Status",
      actions: "Actions",
    },
    {
      title: "Title 2",
      level: "Level 2",
      class: "Class 2",
      message: "Message 2",
      recipient: "Recipient 2",
      status: "Status 2",
      actions: "Actions 2",
    },
    {
      title: "Title 3",
      level: "Level 3",
      class: "Class 3",
      message: "Message 3",
      recipient: "Recipient 3",
      status: "Status 3",
      actions: "Actions 3",
    },
  ];

  function removeObjectFromArray(array, keyToRemove) {
    // Find the index of the object with the provided key
    const indexToRemove = array.findIndex(obj => obj.key === keyToRemove);
    // If the object with the key is found, remove it from the array
    if (indexToRemove !== -1) {
      const newArray = [...array.slice(0, indexToRemove), ...array.slice(indexToRemove + 1)];
      return newArray;
    }
    // If the key is not found, return the original array
    return array;
  }


  const updateOrAddSearchSmsParams = (keyToUpdate, newObject) => {
    const index = searchSmsParams.findIndex(obj => {
      return obj.key === keyToUpdate;
    });

    if (index !== -1) {
      // If exists, update the existing object
      setSearchSmsParams(prevParams => [
        ...prevParams.slice(0, index),
        { ...prevParams[index], ...newObject },
        ...prevParams.slice(index + 1),
      ]);
    } else {
      // If doesn't exist, add the new object
      setSearchSmsParams(prevParams => [...prevParams, newObject]);
    }
  };

  useEffect(() => {
    fetchSchoolClasses()
  }, []);


  const processLoginAndAccessToken = async () => {

    if (userPassword.length == 0) {
      setErrorMessage('Please enter your password')
      setShowError(true)
      return;
    }

    setIsLoading(true)

    try {
      const payLoad = {
        "username": loggedInUser.mobileNumber,
        "password": userPassword
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken //this token isnt really needed
      };
      const response = await httpService.request('POST', 'auth/schoolStaffLogin', payLoad, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {

          saveToLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS, response.body)
          setLoggedInUser(response.body)
          setIsSessionExpired(false)
          setSuccessMessage("Successfully logged in")
          setShowSuccessMessage(true)
          fetchSchoolClasses();


        }

        else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      // setErrorMessage(INVALID_API_RESPONSE);
      // setShowError(true)

      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }


    }
  };

  const fetchSchoolClasses = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/searchBlockClass?schoolId=' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + '&status=Active', null, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          setSchoolClasses(response.body.content);
          searchSmsApiCall()
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };


  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmAction = () => {
    console.log('Confirmed action');
    setIsConfirmationOpen(false);


  };

  useEffect(() => {
    setSchoolBlockClassesDropdownOptions(extractStringsFromArray(schoolClasses, "className"))
  }, [schoolClasses]);

  useEffect(() => {
    console.log('searchSmsParams >>> ', searchSmsParams)
  }, [searchSmsParams]);

  useEffect(() => {
    console.log('sms data ', sms)
  }, [sms]);


  useEffect(() => {
    if (isNull(smsRecipient)) {
      console.log('smsRecipient is nun')
      setSearchSmsParams(removeObjectFromArray(searchSmsParams, 'recipient'))
    } else {
      console.log('smsRecipient is not nun')
      updateOrAddSearchSmsParams('recipient', { key: 'recipient', value: smsRecipient })
    }
  }, [smsRecipient]);

  useEffect(() => {

    if (isNull(smsStudentIdentifier)) {
      console.log('studentIdentifier is nun')
      setSearchSmsParams(removeObjectFromArray(searchSmsParams, 'studentIdentifier'))
    } else {
      console.log('smsStudentIdentifier is not nun')
      updateOrAddSearchSmsParams('studentIdentifier', { key: 'studentIdentifier', value: smsStudentIdentifier })
    }

  }, [smsStudentIdentifier]);

  useEffect(() => {
    if (isNull(searchSms)) {
      console.log('searchSms is nun')
      setSearchSmsParams(removeObjectFromArray(searchSmsParams, 'sms'))
    } else {
      console.log('searchSms is not nun')
      updateOrAddSearchSmsParams('sms', { key: 'sms', value: searchSms })
    }
  }, [searchSms]);

  const getSearchSmsString = () => {
    const searchString = searchSmsParams.map((param) => `${param.key}=${param.value}`).join('&');
    console.log('serach searchString ', `print-outs/searchQueuedSms?${searchString}`)
    return `print-outs/searchQueuedSms?${searchString}`;
  };

  const handleNextPageV2 = () => {
    if (paginationPageNo < paginationTotalPages) {
      setPaginationPageNo(prevPageNo => prevPageNo + 1);
    }
  }


  const handlePreviousPageV2 = () => {
    if (paginationPageNo > 1) {
      setPaginationPageNo(prevPageNo => prevPageNo - 1);
    }
  }


  const searchSmsApiCall = async () => {
    const searchString = getSearchSmsString() + "&page=" + paginationPageNo + "&size=" + paginationPageSize; //+ "&schoolId=" + schoolData.schoolId

    //  const pageCrudActions = ['AddStudent', 'EditStudent', 'DeleteStudent', 'BulkUploadStudents' , 'fetchStudents', 'searchStudents'];
    // setPageAction(pageCrudActions[])
    setIsLoading(true)
    //setPageAction(pageCrudActions[5])
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', searchString, null, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          setSms(response.body.content);
          setPaginationTotalPages(response.body.totalPages)
          //updatePagination('totalPages', response.body.totalPages)
          // updatePagination('pageNo', response.body.totalPages)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };


  return (
    <div className='p-5 h-screen bg-gray-100 w-full overflow-y-auto'>
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmAction}
        message={confirmationMessage}
      />
      <LoadingDialog message={'Processing'} isOpen={isLoading} />
      <ErrorDialog message={errorMessage} isOpen={showError} onClose={() => setShowError(false)} />
      <SuccessfulDialog message={successMessage} isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} />
      <Modal isVisible={isSessionExpired} onClose={() => setIsSessionExpired(false)}>
        <div className='flex flex-col p-4'>
          <p className={`text-xs text-red-500`}>Session expired, please login  again to refresh your session</p>
          <div className='grid grid-cols-1 md:grid-cols-2 mt-2 gap-1 md:gap-2'>
            <div className="flex flex-col py-2 ">
              <label className=" text-black text-xs">Phone number</label>
              <input
                value={loggedInUser.mobileNumber}
                id="phone"
                className={` border-red-500 text-xs rounded-lg bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none`}
                type={"phone"}
              ></input>

            </div>
            <div className="flex flex-col py-2">
              <label className="text-black text-xs">Password</label>
              <input
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                id="password"
                className="rounded-lg text-xs bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none"
                type={"password"}
              ></input>
            </div>
          </div>
          <CustomButton
            // bg-gradient-to-b from-[#FEE6F7] to-[#f8aee2]
            buttonStyle={
              "w-full my-5 py-4 bg-blue-400 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
            }
            buttonText={"Login"}
            onClick={() => {
              processLoginAndAccessToken()
            }}
          />

        </div>
      </Modal>
      <div className='flex flex-col'>
        <div id="menu-controller" className="flex w-full justify-end space-x-2 p-2 ">
          <div onClick={() => setOpenSearchOptions(!openSearchOptions)} className="flex items-center rounded-lg border p-2 cursor-pointer text-sm bg-white">
            <p className="text-sm text-gray-600">Search options</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openSearchOptions && "rotate-180"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
          <div onClick={() => setOpenActionButtons(!openActionButtons)} className="flex items-center rounded-lg border cursor-pointer p-2 text-sm bg-white">
            <p className="text-sm text-gray-600">Actions</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openActionButtons && "rotate-180"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
        </div>
        <div id="class-filters" className="flex flex-col md:flex-row  items-end space-x-0  md:space-x-4 justify-end  w-full md:w-auto">
          <div className={`flex flex-col md:flex-row items-end space-x-0 md:space-x-4 w-full md:w-auto transition-opacity duration-300 ${!openSearchOptions ? "fade-out" : "fade-in"}`}>
            {/* <div className='text-sm w-full md:w-auto'>
              <p>Select Results</p>
              <CustomDropdown options={messageTitles} style={'w-full md:w-52'} />
            </div> */}
            <div className='text-sm w-full md:w-auto'>
              <p>Select Class</p>
              <CustomDropdownWithCancelIcon
                options={schoolBlockClassesDropdownOptions}
                style={'w-full'}
                onOptionSelect={(value) => {
                  //set selectedClass
                  const blockClass = pickSelectedObject(schoolClasses, 'className', value);
                  console.log('selectedBlockClass', blockClass)
                  if (isNull(blockClass.classId)) {
                    console.log('selectedBlockClass is nun')
                    setSearchSmsParams(removeObjectFromArray(searchSmsParams, 'classIdentifier'))
                  } else {
                    console.log('selectedBlockClass is not nun')
                    updateOrAddSearchSmsParams('classIdentifier', { key: 'classIdentifier', value: blockClass.classId })
                    setSelectedBlockClass(blockClass)
                  }
                  setPaginationPageNo(1);

                }}
                isOptionSelected={!isNull(selectedBlockClass)}
              />
            </div>

            <div className='text-xs w-full md:w-auto'>
              <p>Recipient number</p>
              <div className='flex items-center w-full rounded-md border my-2 border-gray-700 bg-light-white px-4 py-3.5'>
                <input
                  type={"search"}
                  placeholder="Search by recipient no."
                  className={`bg-transparent text-sm w-full text-black focus:outline-none`}
                  onChange={(e) => {
                    setPaginationPageNo(1);
                    setSmsRecipient(e.target.value)
                  }}
                  value={smsRecipient}
                ></input>
              </div>
            </div>
            <div className='text-xs w-full md:w-auto'>
              <p>Student admn number</p>
              <div className='flex items-center rounded-md border my-2 border-gray-700 bg-light-white px-4 py-3.5'>
                <input
                  type={"search"}
                  placeholder="Search by admn no."
                  className={`bg-transparent text-sm w-full text-black focus:outline-none`}
                  onChange={(e) => {
                    setPaginationPageNo(1);
                    setSmsStudentIdentifier(e.target.value)
                  }}
                  value={smsStudentIdentifier}
                ></input>
              </div>
            </div>
            <div className='text-xs w-full md:w-auto'>
              <p>Message</p>
              <div className='flex items-center rounded-md border my-2 border-gray-700 bg-light-white px-4 py-3.5'>
                <input
                  type={"search"}
                  placeholder="Search by admn no."
                  className={`bg-transparent text-sm w-full text-black focus:outline-none`}
                  onChange={(e) => {
                    setPaginationPageNo(1);
                    setSearchSms(e.target.value)
                  }}
                  value={searchSms}
                ></input>
              </div>
            </div>

            <button
              onClick={() => {
                //getSearchSmsString()
                searchSmsApiCall()
              }}
              className="rounded-lg m-2 py-4 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full md:w-52 bg-green-600 text-white space-x-1">
              <IoSearchSharp />
              <p>View</p>
            </button>
          </div>
          <div className={`${!openActionButtons ? "fade-out" : "fade-in"} flex flex-col md:flex-row space-x-0  md:space-x-4 items-center justify-end`}>
            <div className={` rounded-lg m-2 py-3.5 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full md:w-52 bg-green-600 text-white space-x-1`}>
              <BsFillChatTextFill />
              <p>Send Exam Results</p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full overflow-auto rounded-lg shadow ">
        <table className="w-full mb-10">
          <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Message</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Recipient</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Student</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Status</th>
              {/* <th className="p-3 text-sm font-semibold tracking-wide text-left">Status Description</th> */}
              {/* <th className="p-3 text-sm font-semibold tracking-wide text-left">Actions</th> */}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {sms.map((message, index) => (
              <tr className="bg-white" key={index}>
                <td className="p-3 text-sm text-gray-700 whitespace-nowrap flex-wrap">
                  {message.sms}
                </td>
                <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                  {message.recipient}
                </td>
                <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                  {message.studentIdentifier}
                </td>
                <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                  {message.status === 'Sent' || message.status === 'Processed' ? "Processed" : message.status}
                </td>
                {/* <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                  {message.statusDescription}
                </td> */}
                {/* <td className="p-3 flex space-x-2">
                  <button className="border p-2 rounded-lg text-sm">Resend</button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div class={`bg-white p-4 border border-gray-300 ${paginationTotalPages < 2 && 'hidden'}`}>
                    <div class="flex justify-center items-center ">
                        <a onClick={() => {
                            handlePreviousPageV2()
                        }} href="#" class="bg-blue-500 text-white py-2 px-4 rounded-l-lg mr-2">Back</a>

                        <div class="flex text-sm">
                            <p>Page {paginationPageNo} of {paginationTotalPages}</p>
                        </div>
                        <a onClick={() => {
                            handleNextPageV2()
                            //  const pageCrudActions = ['AddStudent', 'EditStudent', 'DeleteStudent', 'BulkUploadStudents' , 'fetchStudents', 'searchStudents'];
                            // setPageAction(pageCrudActions[])
                        }} href="#" class="bg-blue-500 text-white py-2 px-4 rounded-r-lg ml-2">Next</a>
                    </div>
                </div>

    </div>
  )
}

export default Sms
import React, { useState } from 'react';
import { BsX } from "react-icons/bs";


function CustomDropdownWithCancelIcon({ options, style, onOptionSelect, isOptionSelected }) {
    console.log('isOptionSelected', isOptionSelected)
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("-- Select --"); //

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
        // Call the callback function passed from the parent component
        if (onOptionSelect) {
            onOptionSelect(option);
        }
    };

    const handleRemoveOptionSelected = () => {
        setSelectedOption("-- Select --")
        onOptionSelect(null)
    }

    return (
        <div className={`relative  inline-block text-left ${style}`}>
            <div>
                <button
                    type="button"
                    // onClick={toggleDropdown}
                    className={`inline-flex justify-between items-center w-full px-4 py-4 my-2 text-sm font-medium text-white bg-gray-700 rounded-md focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75`}
                    id="options-menu"
                    aria-haspopup="true"
                    aria-expanded="true"
                >
                    <span onClick={toggleDropdown}>{selectedOption}</span>
                    <svg
                        className={`-mr-1 ml-2 h-5 w-5 ${isOptionSelected && 'hidden'}`}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 12a2 2 0 100-4 2 2 0 000 4z"
                            clipRule="evenodd"
                        />
                    </svg>

                    <BsX onClick={()=>handleRemoveOptionSelected()} className= {`w-5 h-5 cursor-pointer text-white hover:rotate-180 transition duration-75 ${!isOptionSelected && 'hidden'}`} />

                </button>
            </div>

            {isOpen && (
                <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 max-h-36 overflow-auto">
                    <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        {options.map((option) => (
                            <div
                                key={option}
                                onClick={() => handleOptionClick(option)}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                                role="menuitem"
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default CustomDropdownWithCancelIcon;

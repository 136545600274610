import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import CustomDropdown from "../../components/CustomDropdown"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import { FaPlus } from "react-icons/fa6";
import Modal from '../../components/Modal';
import { extractStringsFromArray, extractStringsFromArrayWithActiveStatus, fetchBlockLevels, isNull, pickSelectedObject } from '../../services/Utilities';
import { getFromLocalStorage, saveToLocalStorage } from '../../services/localStorageService';
import { ADMIN__ROLE, BLOCK_CLASSES_LOCALSTORAGE_KEY, BLOCK_LEVELS_LOCALSTORAGE_KEY, CRUD_ACTIONS, DELETED_STATUS, FORBIDEN_MESSAGE, INACTIVE_STATUS, LOGGED_IN_USER_TOKEN_DETAILS, ONLY_PERFORMED_BY_ADMIN_MESSAGE, SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY, SCHOOL_ID_LOCALSTORAGE_KEY, SCHOOL_STREAMS_LOCALSTORAGE_KEY, cbcClasses } from '../../configs/constants';
import httpService from '../../services/HttpService';
import { INVALID_API_RESPONSE } from '../../configs/constants';
import ErrorDialog from '../../components/ErrorDialog';
import LoadingDialog from '../../components/LoadingDialog';
import SuccessfulDialog from '../../components/SuccessfulDialog';
import CustomDropdownWithCancelIcon from '../../components/CustomDropdownWithCancelIcon';




function Classes() {

  console.log('Classes rendered')

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isAddBlockClass, setIsAddBlockClass] = useState(false);
  const [isAddAcademicLevelStream, setIsAddAcademicLevelStream] = useState(false);
  const [selectedClassStream, setSelectedClassStream] = useState({});
  const [editClassStrem, setEditClassStream] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [selectedAcademicBlockOption, setSelectedAcademicBlockOption] = useState('');
  const [blockLevelsAllData, setBlockLevelsAllData] = useState([]);
  const [blockClassesFormOptions, setBlockClassesFormOptions] = useState([]);
  const [selectedBlockClassOption, setSelectedBlockClassOption] = useState('');
  const [selectedBlockClass, setSelectedBlockClass] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blockClassesArray, setBlockClassesArray] = useState([]);
  const [schoolStreamsArray, setSchoolStreamsArray] = useState([]);
  const [filteredClassStreams , setFilteredClassStreams] = useState([])

  const [schoolAcademicBlocksOptions, setSchoolAcademicBlocksOptions] = useState(extractStringsFromArrayWithActiveStatus(getFromLocalStorage(SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY), 'blockName'))   //['Pre primary', 'Lower Primary', 'Upper primary', 'Junior secondary', 'Senior seccondary'];
  const [selectedSchoolAcademicBlock, setSelectedSchoolAcademicBlock] = useState({});

  const pageActions = ['crudBlockClass', 'crudSchoolStream', 'requestRemoveStudentsInTheStream' , 'deleteStudentsInClass'];
  const searchActions = ['searchBlockClasses', 'searchClassStreams'];
  const [selectedSearchAction, setSelectedSearchAction] = useState('');
  const [selectedPageAction, setSelectedPageAction] = useState('');
  const [selectedCrudAction, setSelectedCrudAction] = useState('');
  const [streamName, setStreamName] = useState('');


  const [selectedAcademicBlockOptionFromAddStreamModal, setSelectedAcademicBlockOptionFromAddStreamModal] = useState('');
  const [selectedAcademicBlockOptionFromAddClassModal, setSelectedAcademicBlockOptionFromAddClassModal] = useState('');
  const [selectedAcademicBlockOptionToFilterClasses, setSelectedAcademicBlockOptionToFilterClasses] = useState('');
  const [selectedAcademicBlockOptionToFilterStreams, setSelectedAcademicBlockOptionToFilterStreams] = useState('');

  const [loggedInUser, setLoggedInUser] = useState(getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS));
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [removeStudentsInTheStream, setRemoveStudentsInTheStream] = useState(false)
  const [userPassword, setUserPassword] = useState("");
  



  const processLoginAndAccessToken = async () => {

    if (userPassword.length == 0) {
      setErrorMessage('Please enter your password')
      setShowError(true)
      return;
    }

    setIsLoading(true)


    try {
      const payLoad = {
        "username": loggedInUser.mobileNumber,
        "password": userPassword
      };

      const response = await httpService.request('POST', 'auth/schoolStaffLogin', payLoad);
      console.log('Server Response:', response);
      setIsLoading(false)
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          saveToLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS, response.body)
          setLoggedInUser(response.body)
          setIsSessionExpired(false)
          setSuccessMessage("Successfully logged in")
          setShowSuccessMessage(true)
        }

        else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      setErrorMessage(INVALID_API_RESPONSE);
      setShowError(true)
    }
  };



  const pickStreamToDelete = (selectedClassStream) => {
    selectedClassStream.status = DELETED_STATUS;
    setSelectedClassStream(selectedClassStream);
    setSelectedPageAction(pageActions[1])
    setSelectedCrudAction(CRUD_ACTIONS[2]);
    setConfirmationMessage('Confirm you want to delete ' + selectedClassStream.streamName + '?')
    setIsConfirmationOpen(true);
  }

  function toggleEditClassStream(selectedClassStream) {
    //setSelectedPageAction(pageActions[])
    setEditClassStream(true);
    setSelectedClassStream(selectedClassStream);
  }

  const handleStreamInputChange = (e) => {
    // Update the state with the new value
    setSelectedClassStream((prevStream) => ({
      ...prevStream,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {

    // fetchBlockClasses()
    // fetchAllSchoolStreams()
  }, []);

  useEffect(() => {
    setBlockLevelsAllData(cbcClasses[selectedAcademicBlockOption]);
    setSelectedSchoolAcademicBlock(pickSelectedObject(getFromLocalStorage(SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY), 'blockName', selectedAcademicBlockOption))
  }, [selectedAcademicBlockOption]);

  useEffect(() => {

    console.log(' << selectedSchoolAcademicBlock >>', selectedSchoolAcademicBlock)
    //console.log(' selectedSearchAction :', searchActions[1])
    // if (selectedSearchAction == searchActions[0]) { //search classes
    //   console.log('Now hit search block classese ', selectedSchoolAcademicBlock)
    //   if (selectedSchoolAcademicBlock && selectedSchoolAcademicBlock.academicBlockId !== undefined) {
    //     fetchBlockClassesUsingFilters(selectedSchoolAcademicBlock.academicBlockId)
    //   }
    // }
    // if (selectedSearchAction == searchActions[1]) { //search streams
    //   if (selectedSchoolAcademicBlock && selectedSchoolAcademicBlock.schoolAcademicBlockId !== undefined) {
    //     console.log('About to fetch streams:', selectedSchoolAcademicBlock.schoolAcademicBlockId);
    //     fetchAcademicBlockStreams(selectedSchoolAcademicBlock.schoolAcademicBlockId);
    //   }
    // }

    if (selectedSchoolAcademicBlock && selectedSchoolAcademicBlock.academicBlockId !== undefined) {
      fetchBlockClassesUsingFilters(selectedSchoolAcademicBlock.academicBlockId)
    }

    
  }, [selectedSchoolAcademicBlock]);





  useEffect(() => {
    console.log('blockLevelsAllData', blockLevelsAllData)
    setBlockClassesFormOptions(extractStringsFromArray(blockLevelsAllData, 'levelName'));
  }, [blockLevelsAllData]);

  useEffect(() => {
    console.log('selectedBlockClassOption:' + selectedBlockClassOption)
    setSelectedBlockClass(pickSelectedObject(blockLevelsAllData, 'levelName', selectedBlockClassOption))
  }, [selectedBlockClassOption]);

  useEffect(() => {
    console.log('selectedBlockClass', selectedBlockClass)
    // if(!isAddAcademicLevelStream){
    //   setFilteredClassStreams(schoolStreamsArray.filter((stream) => stream.classId === selectedSchoolAcademicBlock.classId))
    // }
    
  }, [selectedBlockClass]); //internally i.e from options

  useEffect(() => {
    console.log(selectedClassStream);
  }, [selectedClassStream]);

  useEffect(() => {
    console.log('blockClassesArray', blockClassesArray);
  }, [blockClassesArray]);



  useEffect(() => {
    if (!showError) {
      //clear everything here
    }
  }, [showError]);

  useEffect(() => {
    setSelectedSearchAction(searchActions[1])
    setSelectedSchoolAcademicBlock(
      pickSelectedObject(
        getFromLocalStorage(SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY),
        'blockName',
        selectedAcademicBlockOptionToFilterStreams
      )
    );
    console.log('isNull(selectedAcademicBlockOptionToFilterStreams):', isNull(selectedAcademicBlockOptionToFilterStreams))
  }, [selectedAcademicBlockOptionToFilterStreams]);

  useEffect(() => {
    setSelectedSearchAction(searchActions[0])
    setSelectedSchoolAcademicBlock(
      pickSelectedObject(
        getFromLocalStorage(SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY),
        'blockName',
        selectedAcademicBlockOptionToFilterClasses
      )
    );
  }, [selectedAcademicBlockOptionToFilterClasses]);




  useEffect(() => {
    console.log('selectedAcademicBlockOptionFromAddStreamModal', selectedAcademicBlockOptionFromAddStreamModal)
  }, [selectedAcademicBlockOptionFromAddStreamModal]);




  const fetchAllSchoolStreams = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/searchSchoolStream?schoolId=' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + '&status=Active', null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          saveToLocalStorage(SCHOOL_STREAMS_LOCALSTORAGE_KEY, response.body.content)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }






  const fetchAcademicBlockStreams = async (schoolAcademicBlockId) => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/getAcademicBlockStreams/' + schoolAcademicBlockId, null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSchoolStreamsArray(response.body);
          setFilteredClassStreams(response.body);
          //saveToLocalStorage(SCHOOL_STREAMS_LOCALSTORAGE_KEY , response.body)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }

  const fetchBlockClassesUsingFilters = async (academicBlockId) => {
    setIsLoading(true)
    setBlockClassesArray([])
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/searchBlockClass?schoolId=' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + '&academicBlockId=' + academicBlockId + '&status=Active', null, headers);
      console.log('Server Response:', response);
      setIsLoading(false)
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setBlockClassesArray(response.body.content);
          fetchAcademicBlockStreams(selectedSchoolAcademicBlock.schoolAcademicBlockId);
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }

  const fetchBlockClasses = async () => {
    setIsLoading(true)
    setBlockClassesArray([])
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/getSchoolBlockClasses/' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY), null, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setBlockClassesArray(response.body);
          saveToLocalStorage(BLOCK_CLASSES_LOCALSTORAGE_KEY, response.body)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }

  function addNewBlockClass() {
    if (!selectedSchoolAcademicBlock.hasOwnProperty('academicBlockId')
      || !selectedBlockClass.hasOwnProperty('levelName')) {
      setErrorMessage('Ensure you have selected all fields');
      setIsAddBlockClass(false);
      setShowError(true)
      return
    }

    const data = {
      "schoolId": getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY),
      "academicBlockId": selectedSchoolAcademicBlock.academicBlockId,
      "academicBlockLevelId": selectedBlockClass.academicBlockLevelId,
      "className": selectedBlockClass.levelName,
      "blockName": selectedSchoolAcademicBlock.blockName,
      "status": "Active"
    };
    crudBlockClass(data);
    setSelectedSchoolAcademicBlock({});
    setSelectedBlockClass({});
    setIsAddBlockClass(false);
  }

  function addNewSchoolStream() {
    const data = {
      "classId": selectedBlockClass.classId,
      "schoolAcademicBlockId": selectedSchoolAcademicBlock.schoolAcademicBlockId,
      "streamName": streamName,
      "schoolId": getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY),
      "status": "Active",
      "academicBlockId": selectedBlockClass.academicBlockId
    };
    console.log('add school stream payload', data)
    crudSchoolStream(data);
    setIsAddAcademicLevelStream(false)
  }

  useEffect(() => {
    if (removeStudentsInTheStream) {
      crudSchoolStream(selectedClassStream);
    }
  }, [removeStudentsInTheStream]);


  const deleteStudentsInClass = async (classId) => {
    setIsLoading(true);
    
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      //crud/schoolStreams/{removeStudentsInTheStream}
      const response = await httpService.request('GET', 'onboarding/delete/students/'+classId , null, headers);
      console.log('Server Response:', response);
      setSelectedPageAction("")
      setIsLoading(false)
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSuccessMessage(response.header.customerMessage)
          setShowSuccessMessage(true)
         
        } 
        else {
          setRemoveStudentsInTheStream(false)
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setRemoveStudentsInTheStream(false)
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setRemoveStudentsInTheStream(false)
      setSelectedPageAction("")
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }

  const crudSchoolStream = async (data) => {
    data.classId=selectedBlockClass.classId;
    setEditClassStream(false)
    setIsLoading(true);
    console.log('crudData', data)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      //crud/schoolStreams/{removeStudentsInTheStream}
      const response = await httpService.request('POST', 'onboarding/crud/schoolStreams/' + removeStudentsInTheStream, data, headers);
      console.log('Server Response:', response);
      setSelectedPageAction("")
      setIsLoading(false)
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setRemoveStudentsInTheStream(false)
          fetchAcademicBlockStreams(data.schoolAcademicBlockId)
        } else if (response.header.responseCode === 100) {

          setSelectedPageAction(pageActions[2])
          setConfirmationMessage(response.header.customerMessage);
          setIsConfirmationOpen(true);
        }
        else {
          setRemoveStudentsInTheStream(false)
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setRemoveStudentsInTheStream(false)
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setRemoveStudentsInTheStream(false)
      setSelectedPageAction("")
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };


  const crudBlockClass = async (data) => {
    setIsLoading(true);
    console.log('crudData', data)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'onboarding/crud/crudBlockClasses', data, headers);
      console.log('Server Response:', response);
      setIsLoading(false)
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          fetchBlockClasses()
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };


  const handleInputChange = (e) => {
    // Update the state with the new value
    setSelectedBlockClass((prevBlockClass) => ({
      ...prevBlockClass,
      [e.target.name]: e.target.value,
    }));
  };



  const handleOpenConfirmation = (confirmationMessage) => {
    setIsConfirmationOpen(true);
    setConfirmationMessage(confirmationMessage)
  };

  const handleOpenConfirmationForDeleteClass = (selectedBlockClass) => {
    setIsConfirmationOpen(true);
    setConfirmationMessage('Are you sure you want to delete ' + selectedBlockClass.className + '?');
    setSelectedBlockClass(selectedBlockClass);
    setSelectedPageAction(pageActions[0]);
    setSelectedCrudAction(CRUD_ACTIONS[2]);
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmAction = () => {
    // Your confirmation action logic here   const pageActions = ['crudBlockClass', 'crudSchoolStream' , 'requestRemoveStudentsInTheStream'];


    if (selectedPageAction === pageActions[2]) {
      setRemoveStudentsInTheStream(true)
    }

    if(selectedPageAction === pageActions[3]){
      deleteStudentsInClass(selectedBlockClass.classId)
    }


    if (selectedPageAction == pageActions[0]) { //crudBlockClass
      if (selectedCrudAction == CRUD_ACTIONS[0]) { //add

      }
      if (selectedCrudAction == CRUD_ACTIONS[1]) { //edit

      }
      if (selectedCrudAction == CRUD_ACTIONS[2]) { //delete
        selectedBlockClass.status = DELETED_STATUS;
        crudBlockClass(selectedBlockClass);
      }
      if (selectedCrudAction == CRUD_ACTIONS[3]) { //read

      }

    }
    if (selectedPageAction == pageActions[1]) {//crudSchoolStream
      if (selectedCrudAction == CRUD_ACTIONS[0]) { //add

      }
      if (selectedCrudAction == CRUD_ACTIONS[1]) { //edit

      }
      if (selectedCrudAction == CRUD_ACTIONS[2]) { //delete
        crudSchoolStream(selectedClassStream);
      }
      if (selectedCrudAction == CRUD_ACTIONS[3]) { //read

      }
    }

    console.log('Confirmed action');
    setIsConfirmationOpen(false);
  };

  // function getClassName(classId){
  //   console.log('classId received ', classId)
  //    //blockClassesArray.filter((classData) => classData.classId === schoolStream.classId).className
  //    blockClassesArray.map((classData ) => 
  //     {
  //       console.log('comparing  classData.classId ', classData.classId)
  //       if(classData.classId === classId){
  //         console.log('filted className', classData.className)
  //         return classData.className;
  //       }
  //     })
  // }

  function getClassName(classId) {
    //console.log('classId received ', classId);
    
    const classData = blockClassesArray.find((classData) => {
      //console.log('comparing classData.classId ', classData.classId);
      return classData.classId === classId;
    });
    
    if (classData) {
      console.log('filtered className', classData.className);
      return classData.className;
    } else {
      console.log('classId not found');
      return "----"; // or return some default value
    }
  }
  

  return (
    <div className="p-5 h-screen bg-gray-100 w-full overflow-y-auto">
      <Modal isVisible={isSessionExpired} onClose={() => setIsSessionExpired(false)}>
        <div className='flex flex-col p-4'>
          <p className={`text-xs text-red-500`}>Session expired, please login  again to refresh your session</p>
          <div className='grid grid-cols-1 md:grid-cols-2 mt-2 gap-1 md:gap-2'>
            <div className="flex flex-col py-2 ">
              <label className=" text-black text-xs">Phone number</label>
              <input
                value={loggedInUser.mobileNumber}
                id="phone"
                className={` border-red-500 text-xs rounded-lg bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none`}
                type={"phone"}
              ></input>

            </div>
            <div className="flex flex-col py-2">
              <label className="text-black text-xs">Password</label>
              <input
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                id="password"
                className="rounded-lg text-xs bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none"
                type={"password"}
              ></input>
            </div>
          </div>
          <CustomButton
            // bg-gradient-to-b from-[#FEE6F7] to-[#f8aee2]
            buttonStyle={
              "w-full my-5 py-4 bg-blue-400 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
            }
            buttonText={"Login"}
            onClick={() => {
              processLoginAndAccessToken()
            }}
          />

        </div>
      </Modal>
      <LoadingDialog message={'Processing'} isOpen={isLoading} />
      <ErrorDialog message={errorMessage} isOpen={showError} onClose={() => setShowError(false)} />
      <SuccessfulDialog message={successMessage} isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} />

      {/* Confirmation Dialog */}
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmAction}
        message={confirmationMessage}
      />

      {/*add stream to academic block levels*/}

      <Modal isVisible={isAddAcademicLevelStream} onClose={() => setIsAddAcademicLevelStream(false)}>
        <div className='space-y-2 p-4'>
        <div className='text-xs border-b p-2 mb-5'>
            <p>{selectedBlockClass.className}</p>
          </div>
          {/* <div>
            <p>Select Academic Level</p>
            <CustomDropdownWithCancelIcon
              options={schoolAcademicBlocksOptions}
              style={'w-full'}
              onOptionSelect={(selectedValue) => {
                setSelectedAcademicBlockOptionFromAddStreamModal(pickSelectedObject(getFromLocalStorage(SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY), 'blockName', selectedValue))
                // setSelectedAcademicBlockOptionFromAddStreamModal(pickSelectedObject(getFromLocalStorage(SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY), 'blockName', selectedAcademicBlockOptionFromAddStreamModal))

              }}
              isOptionSelected={!isNull(selectedAcademicBlockOptionFromAddStreamModal)}
            />
          </div> */}
          <div>
            <p className='text-sm'>Stream Name</p>
            <div className="relative mt-2 text-black">
              <input type="text" value={streamName} onChange={(e) => setStreamName(e.target.value)} name='streamName' id="streamName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
              <label htmlFor="streamName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Stream name</label>
            </div>
          </div>
          <div className=' mt-2'>
            <CustomButton
              buttonStyle={
                "w-full text-white text-xs my-5 py-4 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
              }
              buttonText={"Add"}
              onClick={() => addNewSchoolStream()}
            />
          </div>
        </div>
      </Modal>

      <Modal isVisible={editClassStrem} onClose={() => setEditClassStream(false)}>
        <div className='space-y-2 p-4'>
          <div className='text-xs border-b p-2 mb-5'>
            <p>{selectedBlockClass.className}</p>
          </div>
          {/* <div className={` mt-6 ${!isNull(selectedClassStream.academicBlockId) && 'hidden'}`}>
            <p>Select Academic Level</p>
            <CustomDropdownWithCancelIcon
              options={schoolAcademicBlocksOptions}
              style={'w-full'}
              onOptionSelect={(selectedValue) => {
                console.log("selectedValue  ppppp ", selectedValue)
                setSelectedAcademicBlockOptionFromAddStreamModal(pickSelectedObject(getFromLocalStorage(SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY), 'blockName', selectedValue))
              }}
              isOptionSelected={!isNull(selectedAcademicBlockOptionFromAddStreamModal)}
            />
          </div> */}
          <div className=' mt-6'>
            <p className='text-sm'>Stream Name</p>
            <div className="relative mt-2">
              <input type="text" value={selectedClassStream.streamName} onChange={handleStreamInputChange} name='streamName' id="streamName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
              <label htmlFor="streamName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Stream name</label>
            </div>
          </div>
          <div className=' mt-2'>
            <CustomButton
              buttonStyle={
                "w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
              }
              buttonText={"Save"}
              onClick={() => {
                if (isNull(selectedClassStream.academicBlockId) 
                && isNull(selectedAcademicBlockOptionFromAddStreamModal.academicBlockId)) {
                  setEditClassStream(false)
                  setErrorMessage("Please select academic level")
                  setShowError(true);
                } else {
                  if (isNull(selectedClassStream.academicBlockId)) {
                    selectedClassStream.academicBlockId = selectedAcademicBlockOptionFromAddStreamModal.academicBlockId;
                    crudSchoolStream(selectedClassStream);
                  } else {
                    crudSchoolStream(selectedClassStream);
                  }
                }
              }}
            />
          </div>
        </div>
      </Modal>

      {/* Add class Modal */}
      <Modal isVisible={isAddBlockClass} onClose={() => setIsAddBlockClass(false)}>
        <div className='space-y-2 p-4'>
          <div>
            <p>Select Academic Level</p>
            <CustomDropdown options={schoolAcademicBlocksOptions} style={'w-full'}
              onOptionSelect={(value) => {
                console.log('value', value)
                //setSelectedAcademicBlockOptionFromAddClassModal(value)
                setBlockLevelsAllData(cbcClasses[value]);
                setSelectedSchoolAcademicBlock(pickSelectedObject(getFromLocalStorage(SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY), 'blockName', value))

              }} />
          </div>

          <div>
            <p>Select Class level</p>
            <CustomDropdown options={blockClassesFormOptions} style={'w-full'} onOptionSelect={setSelectedBlockClassOption} />
          </div>
          <div className={`mt-2 `}>
            <CustomButton
              buttonStyle={
                ` w-full my-5 py-4 bg-teal-500 shadow-lg text-xs text-white shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg`
              }
              buttonText={"Addd"}
              onClick={() => addNewBlockClass()}
            />
          </div>
        </div>


      </Modal>

      <div className='flex flex-row justify-end'>
        <div id="class-filters" className="flex flex-col w-full md:flex-row md:justify-end ">
          {/* <div className='flex flex-col'>

  <p className='text-xs '>Select Level</p>
            <CustomDropdown options={schoolAcademicBlocksOptions} width={''}
              onOptionSelect={(selectedValue) => {
                console.log('selectedValue', selectedValue)
                setSelectedAcademicBlockOptionToFilterClasses(selectedValue)
              }} />
  </div> */}

          <div className='text-sm  w-full md:w-auto'>
            <p>Filter By Level</p>
            <CustomDropdownWithCancelIcon
              options={schoolAcademicBlocksOptions}
              style={'w-full'}
              onOptionSelect={(selectedValue) => {
                setSelectedAcademicBlockOptionToFilterClasses(selectedValue)
              }}
              isOptionSelected={!isNull(selectedAcademicBlockOptionToFilterClasses)}
            />
          </div>

          <div className='flex flex-col justify-end'>

            <div onClick={setIsAddBlockClass} className={` ${loggedInUser.primaryRole !== ADMIN__ROLE && 'hidden'} rounded-lg m-2 py-4 px-2 border flex flex-row items-center text-sm cursor-pointer bg-green-600 text-white space-x-1`}>
              <FaPlus />
              <p>Add Class</p>
            </div>
          </div>

          {/* <div className='flex flex-col justify-end'>
            <div onClick={() => {

              setIsAddAcademicLevelStream(true)
              setSelectedAcademicBlockOptionFromAddStreamModal({})
            }

            }
              className={`${loggedInUser.primaryRole !== ADMIN__ROLE && 'hidden'} rounded-lg m-2 py-4 px-2 border flex flex-row items-center text-sm cursor-pointer bg-green-600 text-white space-x-1`}>
              <FaPlus />
              <p>Add Stream</p>
            </div>
          </div> */}
        </div>


      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div id="display-classes" className="flex flex-col">
          {/* <div id="class-filters" className="flex flex-col md:flex-row  space-x-0 md:space-x-2 justify-end">
            <CustomDropdown options={schoolAcademicBlocksOptions} width={''}
              onOptionSelect={(selectedValue) => {
                console.log('selectedValue', selectedValue)
                setSelectedAcademicBlockOptionToFilterClasses(selectedValue)
              }} />
            <div onClick={setIsAddBlockClass} className={`${loggedInUser.primaryRole !== ADMIN__ROLE && 'hidden'} rounded-lg m-2 py-2 px-2 border flex flex-row items-center text-sm cursor-pointer bg-green-600 text-white space-x-1`}>
              <FaPlus />
              <p>Add Class</p>
            </div>
          </div> */}
          <div id="list-block-classes" className="w-full overflow-auto rounded-lg shadow ">
            <table className="w-full mb-10">
              <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
                <tr>
                  <th></th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">Class Name</th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">Level</th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {blockClassesArray.length == 0
                  ?
                  <tr className="">
                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <p>No Classes found</p>
                    </td>

                  </tr>
                  : blockClassesArray.map((blockClass, index) => (
                    <tr className="bg-white" key={index}>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    {blockClass.classId === selectedBlockClass.classId ? (
                      <div className="flex items-center space-x-2">
                        <input type="checkbox" id="myCheckbox" 
                          checked={true}
                          // onChange={e => toggleStudentSubjects(subjectStudent.studentSubjectId, e.target.checked)}
                          onChange={e => setSelectedBlockClass(blockClass)}
                          className="form-checkbox text-blue-500 h-5 w-5" defaultChecked />
                        <label htmlFor="myCheckbox" className="text-gray-700"></label>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-2">
                        <input type="checkbox" id="myCheckbox"
                          checked={false}
                          // onChange={e => toggleStudentSubjects(subjectStudent.studentSubjectId, e.target.checked)}
                          onChange={e => setSelectedBlockClass(blockClass)}
                          className="form-checkbox text-blue-500 h-5 w-5" />
                        <label htmlFor="myCheckbox" className="text-gray-700"></label>
                      </div>
                    )}
                  </td>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                        {blockClass.className}
                      </td>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                        <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{blockClass.blockName}</span>
                      </td>

                      <td className="p-3 flex space-x-2">
                        {/* onClick={toggleShowStudentDetailsModal} */}
                        <button onClick={() => {
                          if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                            setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                            setShowError(true)
                          } else {
                            handleOpenConfirmationForDeleteClass(blockClass)
                          }
                        }
                        } className="border p-2 rounded-lg text-sm">Delete Class</button>
                        <button onClick={() => {
                          setSelectedPageAction(pageActions[3])
                          setConfirmationMessage("Connfirm you want delete all students in "+blockClass.className+"? This is irreversible")
                          setIsConfirmationOpen(true)
                          setSelectedBlockClass(blockClass)
                        }
                        } className="border p-2 rounded-lg text-sm">Delete Students</button>
                        <button onClick={() => {
                          setFilteredClassStreams(schoolStreamsArray.filter((stream) => stream.classId === blockClass.classId))
                          setSelectedBlockClass(blockClass)
                        }
                        } className="border p-2 rounded-lg text-sm">View Streams</button>
                        <button onClick={() => {
                           setIsAddAcademicLevelStream(true)
                           setSelectedAcademicBlockOptionFromAddStreamModal({})
                          setSelectedPageAction(pageActions[1])
                          setSelectedBlockClass(blockClass)
                        }
                        } className="border p-2 rounded-lg text-sm">Add Stream</button>
                       
                      </td>
                     
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div id="display-levels-streams" className="flex flex-col">
          {/* <div id="class-filters" className="flex flex-col md:flex-row  space-x-0 md:space-x-2 justify-end">
            <CustomDropdownWithCancelIcon
              options={schoolAcademicBlocksOptions}
              width={''}
              onOptionSelect={setSelectedAcademicBlockOptionToFilterStreams}
              isOptionSelected={!isNull(selectedAcademicBlockOptionToFilterStreams)}
            />
            <div onClick={setIsAddAcademicLevelStream}
              className={`${loggedInUser.primaryRole !== ADMIN__ROLE && 'hidden'} rounded-lg m-2 py-2 px-2 border flex flex-row items-center text-sm cursor-pointer bg-green-600 text-white space-x-1`}>
              <FaPlus />
              <p>Add Stream</p>
            </div>
          </div> */}
          <div id="list-block-classes" className="w-full overflow-auto rounded-lg shadow ">
            <table className="w-full mb-10">
              <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
                <tr>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">Stream</th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">Class</th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {filteredClassStreams.length == 0

                  ? <tr className="" >
                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <p>No Class streams to display</p>
                    </td>

                  </tr>

                  :
                  filteredClassStreams.map((schoolStream, index) => (
                    <tr className="bg-white" key={index}>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                        <a href="#" className="font-bold text-blue-500 hover:underline">{schoolStream.streamName}</a>
                      </td>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                      {/* blockClassesArray.filter((classData) => classData.classId === schoolStream.classId) */}
                      {/* blockClassesArray.filter((classData) => classData.classId === schoolStream.classId).className */}
                      <p>{ getClassName(schoolStream.classId) }</p>
                      </td>
                      <td className="p-3 flex space-x-2">
                        {/* onClick={toggleShowStudentDetailsModal} */}
                        <button onClick={() => {
                          if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                            setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                            setShowError(true)
                          } else {
                            if(isNull(selectedBlockClass.classId)){
                              setErrorMessage("Please select class first")
                            setShowError(true)
                            }else{
                              toggleEditClassStream(schoolStream)
                            } 
                          }

                        }
                        } className="border p-2 rounded-lg text-sm">Edit</button>
                        <button onClick={() => {
                          if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                            setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                            setShowError(true)
                          } else {
                            pickStreamToDelete(schoolStream)
                          }
                        }

                        } className="border p-2 rounded-lg text-sm">Delete</button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>






    </div>

  )
}

export default Classes
// Authentication service class
class AuthService {
    static isLoggedIn = true;
  
    static login() {
      // Simulate a successful login
      this.isLoggedIn = true;
    }
  
    static logout() {
      // Simulate a logout
      this.isLoggedIn = false;
    }
  
    static isAuthenticated() {
      return this.isLoggedIn;
    }
  }
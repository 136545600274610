import React, { useState } from 'react';

const ImageWithLoading = ({ imageUrl, loadingIconUrl }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setLoading(false);
    // Handle error, e.g., display a placeholder image or show an error message
    console.error('Error loading image from URL:', imageUrl);
  };

  return (
    <div className="relative border">
      {loading && (
        <img
          src={loadingIconUrl}
          alt="Loading"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      )}
      <img
        src={imageUrl}
        alt="Image"
        onLoad={handleImageLoad}
        onError={handleImageError}
        className={`block ${loading ? 'hidden' : ''}  w-14 h-14 object-cover`}
      />
    </div>
  );
};

export default ImageWithLoading;

import axios from 'axios';
import { GATEWAY_BASE_URL } from '../configs/constants';
const apiUrl = GATEWAY_BASE_URL;
// const apiUrl = 'https://digischool.app/gateway';
// const apiUrl = 'http://localhost:8080'; // Replace with your gateway API base URL
//const apiUrl = 'http://192.168.100.104:8080/v1/api';


const httpServicev2 = {
  request: async (method, endpoint, data = null , headers) => {
    console.log('Enpoint Url: ', `${apiUrl}/${endpoint}`)
    try {
      const response = await axios({
        method,
        url: `${apiUrl}/${endpoint}`,
        headers: headers,
        data,
      });

      return response.data;
    } catch (error) {
      // Handle errors here
      console.error('HTTP Request Error:', error);
      throw error;
    }
  },
};

export default httpServicev2;

// export const FILES_DOWNLOAD_LINK = "http://127.0.0.1/";
// export const GATEWAY_BASE_URL = "http://localhost:8080";

export const FILES_DOWNLOAD_LINK = "https://digischool.app/student-reports-files/";
export const GATEWAY_BASE_URL = "https://digischool.app/gateway";

export const LOGGED_IN_USER_DATA_OBJECT_KEY = 'USER';
export const SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY = "academicBlocks";
export const BLOCK_CLASSES_LOCALSTORAGE_KEY = "blockClasses";
export const BLOCK_LEVELS_LOCALSTORAGE_KEY = "blockLevels";
export const SCHOOL_STREAMS_LOCALSTORAGE_KEY = "schoolStreams";
export const USER_ID_LOCALSTORAGE_KEY = "userId";
export const SCHOOL_ID_LOCALSTORAGE_KEY = "schoolId";
export const SCHOOL_NAME_LOCALSTORAGE_KEY = "schoolName";
export const SCHOOL_TERM_LOCALSTORAGE_KEY = "schoolTerm";
export const SCHOOL_YEAR_LOCALSTORAGE_KEY = "schoolYear";
export const USER_ROLE_LOCALSTORAGE_KEY = "role";
export const SCHOOLDATA_LOCALSTORAGE_KEY = "school";
export const LOGGED_IN_USER_TOKEN_DETAILS = "userTokenDetails";
export const SCHOOL_TOTAL_STUDENTS_COUNT = "studentsCount";

export const INACTIVE_STATUS = "Inactive";
export const ACTIVE_STATUS = "Active";
export const DELETED_STATUS = "Deleted";
export const PENDING_STATUS = "Pending";
export const PROCESSED_STATUS = "Processed";
export const FAILED_STATUS = "Failed";

export const SCHOOL_LOGO_IMAGE_CATEGORY = "SchoolLogo";
export const ADMIN_TEACHER_ROLE = "ADMIN";
export const ADMIN__ROLE = "Admin";
export const ONLY_PERFORMED_BY_ADMIN_MESSAGE = "Sorry , this can only be performed by the admin.";
export const PASSWORD_LIMIT_CHARACTERS = 4;
export const ACADEMIC_BLOCK_ID_844 = 6;




export const EDIT_ACTION = 'EDIT';
export const SCHOOL_LEVELS_ARRAY = [
    {academicBlockId:1,blockName:'Pre Primary',selected:true , status:'Active'},
    {academicBlockId:2,blockName:'Lower Primary',selected:true ,  status:'Active'},
    {academicBlockId:3,blockName:'Upper Primary',selected:true, status:'Active'},
    {academicBlockId:4,blockName:'Junior Secondary',selected:true, status:'Active'},
    {academicBlockId:5,blockName:'Senior Secondary',selected:true, status:'Active'},
    {academicBlockId:6,blockName:'8-4-4',selected:true, status:'Active'}
];
export const EXEMPTED_MSISDSN_FOR_VALIDATIONS = ['0202301134'];
export const USER_ROLES = ['Admin',  'Teacher', 'Bursar', 'Parent', 'Student']; //'ClassTeacher',
export const SUBJECT_GROUP_COMPUTATION_OPTIONS = ['PickAll','PickTheBestOne','PickTheBestTwo','PickTheBestThree'];
export const INVALID_API_RESPONSE = "Sorry, we experienced technical error. Try again later";
export const FORBIDEN_MESSAGE = "Sorry , you're not allowed to access this resource. Try to login again";
export const UNAUTHORIZED_MESSAGE = "Sorry , your session is invalid or expired. Enter your password to refresh your session";
export const KENYA_PHONE_NUMBER_REGEX = /^(\+254|0)(7\d{8}|1\d{8})$/;
export const cbcClasses = {
    "Pre Primary": [
      {
        "academicBlockLevelId": 1,
        "academicBlockId": 1,
        "levelName": "Play Group"
      },
      {
        "academicBlockLevelId": 2,
        "academicBlockId": 1,
        "levelName": "PP1"
      },
      {
        "academicBlockLevelId": 3,
        "academicBlockId": 1,
        "levelName": "PP2"
      }
    ],
    "Lower Primary": [
      {
        "academicBlockLevelId": 4,
        "academicBlockId": 2,
        "levelName": "Grade 1"
      },
      {
        "academicBlockLevelId": 5,
        "academicBlockId": 2,
        "levelName": "Grade 2"
      },
      {
        "academicBlockLevelId": 6,
        "academicBlockId": 2,
        "levelName": "Grade 3"
      }
    ],
    "Upper Primary": [
      {
        "academicBlockLevelId": 7,
        "academicBlockId": 3,
        "levelName": "Grade 4"
      },
      {
        "academicBlockLevelId": 8,
        "academicBlockId": 3,
        "levelName": "Grade 5"
      },
      {
        "academicBlockLevelId": 9,
        "academicBlockId": 3,
        "levelName": "Grade 6"
      }
    ],
    "Junior Secondary": [
      {
        "academicBlockLevelId": 10,
        "academicBlockId": 4,
        "levelName": "Grade 7"
      },
      {
        "academicBlockLevelId": 11,
        "academicBlockId": 4,
        "levelName": "Grade 8"
      },
      {
        "academicBlockLevelId": 12,
        "academicBlockId": 4,
        "levelName": "Grade 9"
      }
    ],
    "Senior Secondary": [
      {
        "academicBlockLevelId": 13,
        "academicBlockId": 5,
        "levelName": "Grade 10"
      },
      {
        "academicBlockLevelId": 14,
        "academicBlockId": 5,
        "levelName": "Grade 11"
      },
      {
        "academicBlockLevelId": 15,
        "academicBlockId": 5,
        "levelName": "Grade 12"
      }
    ],
    "8-4-4": [
      {
        "academicBlockLevelId": 16,
        "academicBlockId": 6,
        "levelName": "Form 1"
      },
      {
        "academicBlockLevelId": 17,
        "academicBlockId": 6,
        "levelName": "Form 2"
      },
      {
        "academicBlockLevelId": 18,
        "academicBlockId": 6,
        "levelName": "Form 3"
      },
      {
        "academicBlockLevelId": 19,
        "academicBlockId": 6,
        "levelName": "Form 4"
      }
    ]
  };
export const CRUD_ACTIONS = ['Add','Edit','Delete','Read']; //0 Add| 1 Edit | 2 Delete | 3 Read|


  
  

import React from 'react'

function CustomButton({buttonStyle, buttonText, onClick}) {
  return (
    <button className={buttonStyle} onClick={onClick}>
    {buttonText}
  </button>
  )
}

export default CustomButton
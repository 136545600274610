import React from 'react'

function ErrorDialog({ message, isOpen, onClose }) {
    return (
        // <div className={`fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-800 bg-opacity-50 ${isOpen ? '' : ''}`}>
        <div
            className={`fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-300 ${isOpen
                    ? 'opacity-100 backdrop-filter backdrop-blur-md'
                    : 'opacity-0 pointer-events-none'
                }`}
        >
            <div className="bg-white p-8 m-8 rounded-lg shadow-md flex flex-col items-center">
                <p className="text-lg text-red-600 font-semibold">{message}</p>
                <button
                    className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:shadow-outline-red"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    )
}

export default ErrorDialog
import React, { useEffect, useState } from 'react'
import CustomButton from "../../components/CustomButton";
import CustomDropdown from "../../components/CustomDropdown"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import { FaPlus } from "react-icons/fa6";
import Modal from '../../components/Modal';
import { getFromLocalStorage, saveToLocalStorage } from '../../services/localStorageService';
import { ACADEMIC_BLOCK_ID_844, ACTIVE_STATUS, ADMIN__ROLE, BLOCK_CLASSES_LOCALSTORAGE_KEY, DELETED_STATUS, FORBIDEN_MESSAGE, INACTIVE_STATUS, INVALID_API_RESPONSE, LOGGED_IN_USER_TOKEN_DETAILS, ONLY_PERFORMED_BY_ADMIN_MESSAGE, SCHOOLDATA_LOCALSTORAGE_KEY, SCHOOL_ID_LOCALSTORAGE_KEY, SCHOOL_STREAMS_LOCALSTORAGE_KEY, SUBJECT_GROUP_COMPUTATION_OPTIONS } from '../../configs/constants';
import { extractStringsFromArray, extractStringsFromArrayWithActiveStatus, isEmptyObject, isNull, pickSelectedObject } from '../../services/Utilities';
import LoadingDialog from '../../components/LoadingDialog';
import httpService from '../../services/HttpService';
import ErrorDialog from '../../components/ErrorDialog';
import { BsFillPencilFill, BsX } from "react-icons/bs";
import SuccessfulDialog from '../../components/SuccessfulDialog';
import CustomDropdownWithCancelIcon from '../../components/CustomDropdownWithCancelIcon';


function Subjects() {
  const [selectedClass, setSelectedClass] = useState({});
  const [selectedSubject, setSelectedSubject] = useState({});
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isAddNewSubject, setIsAddNewSubject] = useState(false);
  const [isEditNewSubject, setIsEditNewSubject] = useState(false);
  const [displaySubjectDetailsDialog, setDisplaySubjectDetailsDialog] = useState(false)
  const [displaySubjectGroupDetails, setDisplaySubjectGroupDetails] = useState(false)
  const [selectedSubjectGroup, setSelectedSubjectGroup] = useState({});
  const [isEditingSubjectGroup, setIsEditingSubjectGroup] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState({});
  const [teachersDropDownOptions, setTeachersDropDownOptions] = useState([])

  const [subjectAliasName, setSubjectAliasName] = useState('');
  const [subjectName, setSubjectName] = useState('');
  const [subjectGroupName, setSubjectGroupName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const pageCrudActions = ['addSubject', 'editSubject', 'deleteSubject', 'addSubjectGroup', 'editSubjectGroup', 'deleteSubjectGroup', 'fetchSubjects', 'assignSubjectTutor', 'changeSubjectGroupComputationOption', 'removeAssignedTutor','changeSubjectGorpuForSelectedSubject'];
  const [selectedPageCrudAction, setSelectedPageCrudAction] = useState('');
  const [schoolBlockClasses, setSchoolBlockClasses] = useState([]); //getFromLocalStorage(BLOCK_CLASSES_LOCALSTORAGE_KEY)
  const [schoolBlockClassesDropdownOptions, setSchoolBlockClassesDropdownOptions] = useState([]);
  const [schoolBlockClassesDropdownOption, setSchoolBlockClassesDropdownOption] = useState('');
  const [selectedSchoolBlockClass, setSelectedSchoolBlockClass] = useState({})
  const [allClassStreams, setAllClassStreams] = useState([]);
  const [classStreams, setClassStreams] = useState([])
  const [classStreamsOptions, setClassStreamsOptions] = useState([])
  const [selectedClassStream, setSelectedClassStream] = useState([])
  const [schoolSubjectGroups, setSchoolSubjectGroups] = useState([]);
  const [schoolSubjectGroupsDropdownOptions, setSchoolSubjectGroupsDropdownOptions] = useState([])
  const [selectedSchoolSubjectGroupsDropdownOption, setSelectedSchoolSubjectGroupsDropdownOption] = useState('');
  const [selectedSchoolSubjectGroup, setSelectedSchoolSubjectGroup] = useState({});
  const [classSubjects, setclassSubjects] = useState([]);
  const [selectedClassSubject, setSelectedClassSubject] = useState({});
  const [searchSubjectParams, setSearchSubjectParams] = useState([{ key: 'schoolId', value: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) },
  { key: 'size', value: '30' }, { key: 'page', value: '0' }, { key: 'status', value: 'Active' }, { key: 'classId', value: '0' }])


  const [loggedInUser, setLoggedInUser] = useState(getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS));
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [userPassword, setUserPassword] = useState("");
  const [tutors, setTutors] = useState([])
  const [assignTutorPayload, setAssignTutorPayload] = useState({})
  const [classSubjectsResults, setClassSubjectsResults] = useState([])
  const [learningAreaText, setLearningAreaText] = useState('L Area');



  useEffect(() => {
    console.log('assignTutorPayload >>>>> ', assignTutorPayload)
  }, [assignTutorPayload]);

  function clearAssignTutorPayload() {
    setAssignTutorPayload(prevData => {
      return {
        ...prevData,
        classSubjectId: isNull(selectedClassSubject.classSubjectId) ? 0 : selectedClassSubject.classSubjectId,
        subjectId: 0,
        subjectName: isNull(selectedClassSubject.subjectName) ? '' : selectedClassSubject.subjectName,
        subjectAlias: isNull(selectedClassSubject.subjectAliasName) ? '' : selectedClassSubject.subjectAliasName,
        classId: isNull(selectedSchoolBlockClass.classId) ? 0 : selectedSchoolBlockClass.classId, //captured
        schoolId: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY), //captured
        streamId: isNull(selectedClassStream.streamId) ? 0 : selectedClassStream.streamId, //captured
        teacherId: isNull(selectedTeacher.teacherId) ? 0 : selectedTeacher.teacherId, //captured
        firstName: isNull(selectedTeacher.firstName) ? '' : selectedTeacher.firstName,
        lastName: isNull(selectedTeacher.lastName) ? '' : selectedTeacher.lastName,
        status: ACTIVE_STATUS
      };
    });
  }


  function manipulateAssignTutorPayload(myKey, keyValue) {
    setAssignTutorPayload(prevData => ({
      ...prevData,
      [myKey]: keyValue,
      schoolId: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY),
      classId: selectedSchoolBlockClass.classId,
      status:  myKey === 'status' ? keyValue  :ACTIVE_STATUS
    }));
  }


  const processLoginAndAccessToken = async () => {

    if (userPassword.length == 0) {
      setErrorMessage('Please enter your password')
      setShowError(true)
      return;
    }

    setIsLoading(true)


    try {
      const payLoad = {
        "username": loggedInUser.mobileNumber,
        "password": userPassword
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken //this token isnt really needed
      };
      const response = await httpService.request('POST', 'auth/schoolStaffLogin', payLoad, headers);
      //console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          saveToLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS, response.body)
          setLoggedInUser(response.body)
          setIsSessionExpired(false)

          setSuccessMessage("Successfully logged in")
          setShowSuccessMessage(true)

          //action after refreshing token
          fetchBlockClasses()



        }

        else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      console.error('Exception:' + error)
      // setErrorMessage(INVALID_API_RESPONSE);
      // setShowError(true)

      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        //console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }


    }
  };



  const getSearchString = (item) => {
    const searchString = searchSubjectParams.map((param) => `${param.key}=${param.value}`).join('&');
    return `${item}?${searchString}`;
  };

  useEffect(() => {
    fetchBlockClasses()
  }, []);

  useEffect(() => {
    //console.log("classStreams", classStreams)
    
    if (classStreams.length < 1) {
      manipulateAssignTutorPayload('streamId', 0)
    }
    setClassStreamsOptions(extractStringsFromArray(classStreams.filter(data => data.academicBlockId === selectedSchoolBlockClass.academicBlockId && data.classId === selectedSchoolBlockClass.classId), 'streamName'))
  }, [classStreams]);

  useEffect(() => {
    console.log("classStreamsOptions", classStreamsOptions)
  }, [classStreamsOptions]);

  useEffect(() => {
    console.log("allClassStreams", allClassStreams)
    setClassStreams(allClassStreams.filter(data => data.schoolAcademicBlockId !== 0 && data.classId === selectedSchoolBlockClass.classId))
  }, [allClassStreams]);

  useEffect(() => {
    console.log('selectedSchoolBlockClass', selectedSchoolBlockClass)
    if(selectedSchoolBlockClass.academicBlockId === ACADEMIC_BLOCK_ID_844){
      setLearningAreaText('Subject')
      
    }else{
      setLearningAreaText('L. Area')
    }
    // if (allClassStreams.length < 1) {
    //   fetchSchoolStreams()
    // }
    // //filter streams
    // // setClassStreams(allClassStreams.filter(data => data.academicBlockId === selectedSchoolBlockClass.academicBlockId))
    // setClassStreams(allClassStreams.filter(data => data.classId === selectedSchoolBlockClass.classId))
    fetchSchoolStreams()

  }, [selectedSchoolBlockClass]);

  useEffect(() => {
    setSchoolSubjectGroupsDropdownOptions(extractStringsFromArrayWithActiveStatus(schoolSubjectGroups, 'subjectGroupName'))
  }, [schoolSubjectGroups]);

  useEffect(() => {
    setSchoolBlockClassesDropdownOptions(extractStringsFromArray(schoolBlockClasses, "className"));
  }, [schoolBlockClasses]);

  useEffect(() => {
    setTeachersDropDownOptions(extractStringsFromArray(teachers, "filterName"));
  }, [teachers]);



  useEffect(() => {
    //console.log(searchSubjectParams)
  }, [searchSubjectParams]);

  useEffect(() => {
    if(selectedPageCrudAction === pageCrudActions[9]){
      manipulateAssignTutorPayload('status', 'Deleted')
    }
  }, [isConfirmationOpen]);

  useEffect(() => {
   console.log('selectedSchoolSubjectGroup',selectedSchoolSubjectGroup)
  }, [selectedSchoolSubjectGroup]);

  useEffect(() => {

    if(selectedPageCrudAction === pageCrudActions[10])
      {
        setSchoolSubjectGroupsDropdownOptions(extractStringsFromArrayWithActiveStatus(schoolSubjectGroups, 'subjectGroupName'))
      }
   
  }, [selectedPageCrudAction]);

  

  



  useEffect(() => {
    if (selectedPageCrudAction == pageCrudActions[6]) {
      searchSubjects()
    }

    //console.log('selectedSchoolBlockClass', selectedSchoolBlockClass)
    if (!isNull(selectedSchoolBlockClass.classId)) {
      manipulateAssignTutorPayload('classId', selectedSchoolBlockClass.classId)
      manipulateAssignTutorPayload('className', selectedSchoolBlockClass.className)
      fetchClassSubjectsTeachers()
    }

  }, [selectedSchoolBlockClass]);

  useEffect(() => {
    if (!isNull(selectedTeacher.teacherId)) {
      manipulateAssignTutorPayload('teacherId', selectedTeacher.teacherId)
      manipulateAssignTutorPayload('firstName', selectedTeacher.firstName)
      manipulateAssignTutorPayload('lastName', selectedTeacher.lastName)
    }
  }, [selectedTeacher]);

  useEffect(() => {
    if (!isNull(selectedClassStream.streamId)) {
      manipulateAssignTutorPayload('streamId', selectedClassStream.streamId)
      manipulateAssignTutorPayload('streamName', selectedClassStream.streamName)
    }
  }, [selectedClassStream]);

  useEffect(() => {
    console.log('selectedClassSubject :: ',selectedClassSubject)
    if (!isNull(selectedClassSubject.classSubjectId)) {
      manipulateAssignTutorPayload('classSubjectId', selectedClassSubject.classSubjectId)
      manipulateAssignTutorPayload('subjectName', selectedClassSubject.subjectName)
      manipulateAssignTutorPayload('subjectAlias', selectedClassSubject.subjectAlias)
    }
  }, [selectedClassSubject]);

  useEffect(() => {
    console.log('tutors >>> ', tutors)
    //setclassSubjects(classSubjectsResults);
  }, [tutors]);





  const fetchSchoolStreams = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/searchSchoolStream?schoolId=' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + '&status=Active&size=150', null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          //setAllClassStreams(response.body.content.filter(data => data.classId === selectedSchoolBlockClass.classId))
          setAllClassStreams(response.body.content)
          //setSchoolStreamsArray(response.body);
          //saveToLocalStorage(SCHOOL_STREAMS_LOCALSTORAGE_KEY , response.body)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        //console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }

  const updateOrAddSearchStudentParams = (keyToUpdate, newObject) => {
    const index = searchSubjectParams.findIndex(obj => {
      return obj.key === keyToUpdate;
    });

    if (index !== -1) {
      // If exists, update the existing object
      setSearchSubjectParams(prevParams => [
        ...prevParams.slice(0, index),
        { ...prevParams[index], ...newObject },
        ...prevParams.slice(index + 1),
      ]);
    } else {
      // If doesn't exist, add the new object
      setSearchSubjectParams(prevParams => [...prevParams, newObject]);
    }
  };

  const crudAssignTutorApiCall = async () => {
    setSelectedPageCrudAction("")
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'onboarding/crud/crudAssignTutor', assignTutorPayload, headers);
      //console.log('Server Response:', response);
      clearAssignTutorPayload()
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setTutors(response.body)
          setSuccessMessage(response.header.customerMessage)
          setShowSuccessMessage(true)
          searchSubjects()
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      clearAssignTutorPayload()
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        //console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }


  const crudSubjectGroup = async (data) => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'onboarding/crud/crudClassSubjectGroups', data, headers);
      //console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          //searchSubjectGroups()
          searchSubjects()

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        //console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }

  const crudSubject = async (data) => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'onboarding/crud/classSubjects', data, headers);
      //console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          searchSubjects()
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        //console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }



  const fetchClassSubjectsTeachers = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/get/fetchClassSubjectTeachers/' + selectedSchoolBlockClass.classId, null, headers);
      //console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          //console.log('fetchClassSubjectsTeachers response ', response.body)
          setTutors(response.body);
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        //console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }


  const searchSubjectGroups = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', getSearchString('onboarding/searchSubjectGroups'), null, headers);
      //console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSchoolSubjectGroups(response.body.content);

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        //console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }


  const searchSubjects = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', getSearchString('onboarding/searchSubjects'), null, headers);
      //console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
           setclassSubjects(response.body.content);
          //setClassSubjectsResults(response.body.content);
          searchSubjectGroups()
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        //console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }



  const fetchBlockClasses = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/getSchoolBlockClasses/' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY), null, headers);
      //console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSchoolBlockClasses(response.body);

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        //console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }



  const handleOnClickAddNewSubjectGroup = () => {
    setSelectedPageCrudAction(pageCrudActions[3]);
    //console.log('handleOnClickAddNewSubjectGroup >>>> selectedSchoolBlockClass ', selectedSchoolBlockClass)
    if (selectedSchoolBlockClass === undefined || isEmptyObject(selectedSchoolBlockClass)) {
      setErrorMessage("Select class to proceed!")
      setShowError(true);
    } else {
      setDisplaySubjectGroupDetails(true)
      setIsEditingSubjectGroup(false);
      setSubjectGroupName('');
    }
  }


  function processPageAction() {
    //console.log('selectedPageCrudAction:', selectedPageCrudAction)
    if (selectedPageCrudAction == pageCrudActions[3]) { //add subject group
      const newSubjectGroup = {
        "academicBlockId": selectedSchoolBlockClass.academicBlockId,
        "classId": selectedSchoolBlockClass.classId,
        "schoolId": getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY),
        "subjectGroupName": subjectGroupName,
        "status": ACTIVE_STATUS
      };
      crudSubjectGroup(newSubjectGroup);
      setDisplaySubjectGroupDetails(false)
      setIsEditingSubjectGroup(false);
      setSubjectGroupName('');

    }
    if (selectedPageCrudAction == pageCrudActions[0]) { //add subject
      const addNewSubjectPayloaad = {
        //"classSubjectId": 0,
        "classSubjectGroupId": selectedSchoolSubjectGroup.classSubjectGroupId, //setSelectedSchoolSubjectGroup
        "subjectId": 0,
        "subjectName": subjectName,
        "subjectAlias": subjectAliasName,
        "subjectGroupName": selectedSchoolSubjectGroup.subjectGroupName,
        "academicBlockId": selectedSchoolBlockClass.academicBlockId,
        "classId": selectedSchoolBlockClass.classId,
        "schoolId": getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY),
        "teacherId": 0,
        "className": selectedSchoolBlockClass.className,
        "status": ACTIVE_STATUS
      };
      crudSubject(addNewSubjectPayloaad);
      setDisplaySubjectDetailsDialog(false)
      setIsAddNewSubject(false)
      setIsEditNewSubject(false);
      setSubjectName('');
      setSubjectAliasName('');
    }

    if (selectedPageCrudAction == pageCrudActions[1]) { //edit subject
      const addNewSubjectPayloaad = {
        "classSubjectId": selectedClassSubject.classSubjectId,
        "classSubjectGroupId": selectedSchoolSubjectGroup.classSubjectGroupId,
        "subjectId": 0,
        "subjectName": subjectName,
        "subjectAlias": subjectAliasName,
        "subjectGroupName": selectedSchoolSubjectGroup.subjectGroupName,
        "academicBlockId": selectedSchoolBlockClass.academicBlockId,
        "classId": selectedSchoolBlockClass.classId,
        "schoolId": getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY),
        "teacherId": 0,
        "className": selectedSchoolBlockClass.className,
        "status": ACTIVE_STATUS
      };
      crudSubject(addNewSubjectPayloaad);
      setDisplaySubjectDetailsDialog(false)
      setIsAddNewSubject(false)
      setIsEditNewSubject(false);
      setSubjectName('');
      setSubjectAliasName('');
    }

  

    //process delete group and subject
    if (selectedPageCrudAction == pageCrudActions[5]) { //delete subject group
      selectedSchoolSubjectGroup.status = DELETED_STATUS;
      crudSubjectGroup(selectedSchoolSubjectGroup);
    }
    if (selectedPageCrudAction == pageCrudActions[2]) { //delete subject
      selectedClassSubject.status = DELETED_STATUS;
      crudSubject(selectedClassSubject);
    }

    if (selectedPageCrudAction == pageCrudActions[7]) { //assign tutor
      selectedClassSubject.teacherName = selectedTeacher.filterName;
      selectedClassSubject.teacherId = selectedTeacher.teacherId;
      setSelectedPageCrudAction('')
      crudSubject(selectedClassSubject);

    }
    if (selectedPageCrudAction == pageCrudActions[8]) { //
      setSelectedPageCrudAction('')
      crudSubjectGroup(selectedSchoolSubjectGroup);
    }

  }

  const handleOnClickAddNewSubject = () => {
    setSelectedPageCrudAction(pageCrudActions[0]);
    if (selectedSchoolBlockClass === undefined || isEmptyObject(selectedSchoolBlockClass)) {
      setErrorMessage("Select class to proceed!")
      setShowError(true);
    } else {
      setDisplaySubjectDetailsDialog(true)
      setIsAddNewSubject(true)
      setIsEditNewSubject(false);
      setSubjectName('');
      setSubjectAliasName('');
    }
  }

  // const pageCrudActions = ['addSubject', 'editSubject', 'deleteSubject', 'addSubjectGroup', 'editSubjectGroup', 'deleteSubjectGroup', 'fetchSubjects'];


  const pickSubjectGroupForEditing = (selectedSubjectGroup) => {
    setSelectedPageCrudAction(pageCrudActions[4]);
    setSelectedSchoolSubjectGroup(selectedSubjectGroup)
    setDisplaySubjectGroupDetails(true)
    setIsEditingSubjectGroup(true);
    setSubjectGroupName(selectedSubjectGroup.subjectGroupName);
  }

  const handleConfirmDeleteSubjectGroup = (selectedSubjectGroup) => {
    setSelectedPageCrudAction(pageCrudActions[5])
    setSelectedSchoolSubjectGroup(selectedSubjectGroup)
    setIsConfirmationOpen(true);
    setConfirmationMessage('Confirm you want to delete ' + selectedSubjectGroup.subjectGroupName + '? All subjects under this group will also be deleted');
  }


  const handleConfirmationDeleteSubject = (selectedSubject) => {
    setSelectedPageCrudAction(pageCrudActions[2])
    setSelectedClassSubject(selectedSubject);
    setIsConfirmationOpen(true);
    setConfirmationMessage('Are you sure you want to delete ' + selectedSubject.subjectName + '?');
  };



  const pickSubjectSelectedForEditing = (selectedSubject) => {
    setSelectedPageCrudAction(pageCrudActions[1])
    setSelectedClassSubject(selectedSubject);
    setSubjectName(selectedSubject.subjectName);
    setSubjectAliasName(selectedSubject.subjectAlias);
    setIsAddNewSubject(false);
    setIsAddNewSubject(true);
    setDisplaySubjectDetailsDialog(true);
  };

                            // setClassStreamsOptions(extractStringsFromArray(classStreams.filter((stream)=> stream.classId === classSubject.classId), 'streamName'))


  const pickSubjectToAssignTutor = (selectedSubject) => {
    setSelectedTeacher({})
    setSelectedPageCrudAction(pageCrudActions[7])
    setSelectedClassSubject(selectedSubject);
    if (teachers.length < 1) {
      processSearchTeachers("onboarding/searchTeacher?size=10&page=0&schoolId=" + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + "&status=Active");
    }

  };


  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmAction = () => {

    if(selectedPageCrudAction === pageCrudActions[9]){
      crudAssignTutorApiCall()
    }

    if(selectedPageCrudAction === pageCrudActions[10]){
      selectedClassSubject.classSubjectGroupId = selectedSchoolSubjectGroup.classSubjectGroupId;
      selectedClassSubject.subjectGroupName = selectedSchoolSubjectGroup.subjectGroupName;
      console.log('selected subject details ', selectedClassSubject);
      crudSubject(selectedClassSubject);    
    }

    // Your confirmation action logic here
    //console.log('Confirmed action: ', selectedPageCrudAction);
    processPageAction();
    setIsConfirmationOpen(false);
  };

  const processSearchTeachers = async (url = null) => {

    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', url, null, headers);
      //console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          const initialReceivedData = response.body;
          const alteredData = [];
          initialReceivedData.map((data) => {
            data.filterName = data.firstName + " " + data.lastName;
            alteredData.push(data)
          })
          setTeachers(alteredData);
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        //console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };





  return (
    <div className="p-5 h-screen bg-gray-100 w-full overflow-y-auto">
      <Modal isVisible={isSessionExpired} onClose={() => setIsSessionExpired(false)}>
        <div className='flex flex-col p-4'>
          <p className={`text-xs text-red-500`}>Session expired, please login  again to refresh your session</p>
          <div className='grid grid-cols-1 md:grid-cols-2 mt-2 gap-1 md:gap-2'>
            <div className="flex flex-col py-2 ">
              <label className=" text-black text-xs">Phone number</label>
              <input
                value={loggedInUser.mobileNumber}
                id="phone"
                className={` border-red-500 text-xs rounded-lg bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none`}
                type={"phone"}
              ></input>

            </div>
            <div className="flex flex-col py-2">
              <label className="text-black text-xs">Password</label>
              <input
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                id="password"
                className="rounded-lg text-xs bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none"
                type={"password"}
              ></input>
            </div>
          </div>
          <CustomButton
            // bg-gradient-to-b from-[#FEE6F7] to-[#f8aee2]
            buttonStyle={
              "w-full my-5 py-4 bg-blue-400 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
            }
            buttonText={"Login"}
            onClick={() => {
              processLoginAndAccessToken()
            }}
          />

        </div>
      </Modal>
      <LoadingDialog message={'Processing'} isOpen={isLoading} />
      <ErrorDialog message={errorMessage} isOpen={showError} onClose={() => setShowError(false)} />
      <SuccessfulDialog message={successMessage} isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} />
      {/* Confirmation Dialog */}
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmAction}
        message={confirmationMessage}
      />
      <Modal isVisible={selectedPageCrudAction === pageCrudActions[8]} onClose={() => setSelectedPageCrudAction('')}>
        <div className='flex flex-col'>
          <p className='text-xs border-b-2 p-2'>Set {learningAreaText} Category Computation Setting</p>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-2 mt-2'>
            <div className=''>
              {/* <p>Select Computation Option</p> */}
              <CustomDropdown
                options={SUBJECT_GROUP_COMPUTATION_OPTIONS}
                onOptionSelect={(selectedValue) => {
                  //console.log('selectedSchoolSubjectGroup to edit , ', selectedSchoolSubjectGroup);
                  selectedSchoolSubjectGroup.subjectGroupComputationSetting = selectedValue;
                  //console.log('selectedSchoolSubjectGroup after change', selectedSchoolSubjectGroup)
                  setSelectedSchoolSubjectGroup(selectedSchoolSubjectGroup)
                }} style={'w-full'} />
            </div>
            <div className=' mt-2'>
              <CustomButton
                buttonStyle={
                  "w-full py-4 text-xs text-white bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
                }
                buttonText={"Set"}
                onClick={() => processPageAction()}
              />
            </div>

          </div>
        </div>
      </Modal>

      <Modal isVisible={selectedPageCrudAction === pageCrudActions[7]} onClose={() => setSelectedPageCrudAction('')}>
        <div className='flex flex-col'>

          <div className={`text-sm w-full md:w-auto ${classStreams.length < 1 && 'hidden'}`}>
            <p>Select Stream</p>
            <CustomDropdownWithCancelIcon options={classStreamsOptions}
              onOptionSelect={(valueSelected) => {
                const selected = pickSelectedObject(classStreams, 'streamName', valueSelected);
                //console.log('selected class stream: ', selected)
                setSelectedClassStream(selected);
              }}
              style={'w-full'}
              isOptionSelected={!isNull(selectedClassStream)}
            />
          </div>

          <p>Select Teacher to assign</p>
          <CustomDropdownWithCancelIcon
            options={teachersDropDownOptions}
            onOptionSelect={(selectedValue) => {
              //console.log(selectedValue);
              setSelectedTeacher(pickSelectedObject(teachers, 'filterName', selectedValue))
            }}
            style={'w-full'}
            isOptionSelected={!isNull(selectedTeacher)}
          />
          <div className=' mt-1'>
            <CustomButton
              buttonStyle={
                "w-full  py-4 text-xs text-white bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
              }
              buttonText={"Assign"}
              onClick={() => {
                crudAssignTutorApiCall()
              }}
            />
          </div>
        </div>
      </Modal>

      {/* crud subject group */}

      <Modal isVisible={displaySubjectGroupDetails} onClose={() => setDisplaySubjectGroupDetails(false)}>
        {/* <div className='space-y-2 p-4'>
          <p className='text-sm'>Edit Subject Group</p>
        </div> */}
        <div>
          <p className='text-sm'>Category Name</p>
          <div className="relative mt-2">
            <input type="text" name='subjectGroupName' onChange={e => setSubjectGroupName(e.target.value)} value={subjectGroupName} id="subjectGroupName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
            <label htmlFor="subjectGroupName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Category Name</label>
          </div>
        </div>

        <div className=' mt-2'>
          <CustomButton
            buttonStyle={
              "w-full text-white text-xs my-5 py-4 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
            }
            buttonText={"Save"}
            onClick={() => processPageAction()}
          />
        </div>
      </Modal>

      <Modal isVisible={displaySubjectDetailsDialog} onClose={() => setDisplaySubjectDetailsDialog(false)}>
        <div className='space-y-2 p-4'>
          <p className='text-xs border-b pb-2'>{selectedPageCrudAction == pageCrudActions[0] ? `Add Class ${learningAreaText}` : `Edit Class ${learningAreaText}`}</p>
          {/* <div>
            <p>Select Learning Area Category</p>
            <CustomDropdown
              options={schoolSubjectGroupsDropdownOptions}
              onOptionSelect={(selectedValue) => {
                //console.log(selectedValue);
                const pickedSubjectGroup = pickSelectedObject(schoolSubjectGroups, 'subjectGroupName', selectedValue)
                //console.log('pickedSubjectGroup', pickedSubjectGroup)
                setSelectedSchoolSubjectGroup(pickedSubjectGroup)
              }} style={'w-full'} />
          </div> */}
          <div className='mt-2'>
            <p className='text-xs'>{learningAreaText} Name</p>
            <div className="relative mt-2">
              <input type="text" value={subjectName} onChange={e => setSubjectName(e.target.value)} name='subjectName' id="subjectName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
              <label htmlFor="subjectName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{learningAreaText}</label>
            </div>
          </div>
          <div className='mt-4'>
            <p className='text-xs'>{learningAreaText} Alias Name</p>
            <div className="relative mt-2">
              <input type="text" value={subjectAliasName} onChange={e => setSubjectAliasName(e.target.value)} name='subjectAlias' id="subjectAlias" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
              <label htmlFor="subjectAlias" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{learningAreaText} alias</label>
            </div>
          </div>
          <div className=' mt-2'>
            <CustomButton
              buttonStyle={
                "w-full my-5 py-4 text-xs text-white bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
              }
              buttonText={`${selectedPageCrudAction == pageCrudActions[0] ? 'Save' : 'Edit'}`}
              onClick={() => processPageAction()}
            />
          </div>
        </div>
      </Modal>


      <div className=' w-auto flex flex-col space-x-1 md:flex-row  md:space-x-1 justify-end'>
        <CustomDropdown options={schoolBlockClassesDropdownOptions}
          onOptionSelect={(valueSelected) => {
            setSelectedPageCrudAction(pageCrudActions[6]);
            //console.log('valueSelected', valueSelected)
            //console.log('schoolBlockClasses', schoolBlockClasses)
            const selected = pickSelectedObject(schoolBlockClasses, 'className', valueSelected);
            updateOrAddSearchStudentParams('classId', { key: 'classId', value: selected.classId });
            console.log('allStreams ', classStreams);
            console.log('filtredStreams',classStreams.filter((stream)=> stream.classId === selected.classId))
            setClassStreamsOptions(extractStringsFromArray(classStreams.filter((stream)=> stream.classId === selected.classId), 'streamName'))
            setSelectedSchoolBlockClass(selected);

          }}
          width={''} />
        {/* <CustomDropdown options={schoolBlockClassesDropdownOptions} width={''} /> */}
        {/* <div onClick={() => {
          if (loggedInUser.primaryRole !== ADMIN__ROLE) {
            setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
            setShowError(true)
          } else {
            handleOnClickAddNewSubject()
          }
        }
        } className="rounded-lg m-2 py-3 px-2 border flex flex-row items-center text-sm cursor-pointer bg-green-600 text-white md:space-x-1">
          <FaPlus />
          <p>Add Learning Area</p>
        </div> */}
        <div onClick={() => {
          if (loggedInUser.primaryRole !== ADMIN__ROLE) {
            setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
            setShowError(true)
          } else {
            handleOnClickAddNewSubjectGroup()
          }
        }
        } className="rounded-lg m-2 py-3 px-2 border flex flex-row items-center text-sm cursor-pointer bg-green-600 text-white md:space-x-1">
          <FaPlus />
          <p>Add {learningAreaText} Category</p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div id="display-subjects" className="flex flex-col">
          {/* <div id="class-filters" className="flex flex-col md:flex-row  space-x-0 md:space-x-2 justify-end">
            <CustomDropdown options={schoolBlockClassesDropdownOptions} width={''} />
            <div onClick={() => handleOnClickAddNewSubject()} className="rounded-lg m-2 py-2 px-2 border flex flex-row items-center text-sm cursor-pointer bg-green-600 text-white space-x-1">
              <FaPlus />
              <p>Add Subject</p>
            </div>
          </div> */}
          <div id="list-block-classes" className="w-full overflow-auto rounded-lg shadow ">
            <table className="w-full mb-10">
              <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
                <tr>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">{learningAreaText}</th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">Alias</th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">Group</th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">Tutors</th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {classSubjects.length == 0 ?

                  (
                    <tr className='w-full'>
                      <td> <span className='p-4 text-xs'>No {learningAreaText} found</span> </td>
                    </tr>
                  )

                  : classSubjects.map((classSubject, index) => (
                    <tr className="bg-white" key={index}>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                        {classSubject.subjectName}
                      </td>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                        {classSubject.subjectAlias}
                      </td>
                      <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                        <p className='flex flex-row items-center space-x-1'>
                        <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{classSubject.subjectGroupName}</span>
                        <BsFillPencilFill className=' cursor-pointer h-3 w-3' 
                              onClick={()=>{
                                setSelectedClassSubject(classSubject)
                                setSelectedPageCrudAction(pageCrudActions[10]);
                                setConfirmationMessage("Confirm you want to add "+classSubject.subjectName+" to "+selectedSchoolSubjectGroup.subjectGroupName+"?")
                                setIsConfirmationOpen(true);

                              }} />
                        </p>
                        
                      </td>
                      <td className="p-3 text-xs text-gray-700 whitespace-nowrap ">

                        <ul className=' space-y-1'>
                          {

                            tutors.filter(data => data.classSubjectId === classSubject.classSubjectId).map((teacherSubject, index) =>

                              <li key={index} className='text-xs flex flex-row'>{teacherSubject.streamId === 0 ? teacherSubject.firstName + '' : teacherSubject.firstName + '-' + teacherSubject.streamName + ' '} 
                              <BsX className=' cursor-pointer h-4 w-4' 
                              onClick={()=>{
                                //  const pageCrudActions = ['addSubject', 'editSubject', 'deleteSubject', 'addSubjectGroup', 'editSubjectGroup', 'deleteSubjectGroup', 'fetchSubjects', 'assignSubjectTutor', 'changeSubjectGroupComputationOption', 'removeAssignedTutor'];
                                setAssignTutorPayload(teacherSubject)
                                manipulateAssignTutorPayload('status', DELETED_STATUS)
                                setSelectedPageCrudAction(pageCrudActions[9])
                                setConfirmationMessage('Confirm you want to remove '+teacherSubject.firstName+' from '+classSubject.subjectName+'?')
                                setIsConfirmationOpen(true)
                              }} /></li>

                            )}
                        </ul>





                      </td>
                      <td className="p-3 flex space-x-2">
                        {/* onClick={toggleShowStudentDetailsModal} */}
                        <button onClick={() => {
                          if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                            setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                            setShowError(true)
                          } else {
                            handleConfirmationDeleteSubject(classSubject)
                          }
                        }
                        } className="border p-2 rounded-lg text-sm">Delete</button>
                        <button onClick={() => {
                          if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                            setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                            setShowError(true)
                          } else {
                            pickSubjectSelectedForEditing(classSubject)
                          }
                        }
                        } className="border p-2 rounded-lg text-sm">Edit</button>
                        <button onClick={() => {

                          // setClassStreamsOptions(extractStringsFromArray(classStreams.filter((stream)=> stream.classId === classSubject.classId), 'streamName'))
                          setSelectedClassStream({})

                          if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                            setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                            setShowError(true)
                          } else {
                            pickSubjectToAssignTutor(classSubject)
                          }
                        }
                        } className="border p-2 rounded-lg text-sm">Assign Tutor</button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

        </div>
        <div id="display-subject-groups" className="flex flex-col">
          {/* <div id="class-filters" className="flex flex-col md:flex-row  space-x-0 md:space-x-2 justify-end">
            <CustomDropdown options={classes} style={''} />
            <div onClick={() => handleOnClickAddNewSubjectGroup()} className="rounded-lg m-2 py-2 px-2 border flex flex-row items-center text-sm cursor-pointer bg-green-600 text-white space-x-1">
              <FaPlus />
              <p>Add Subject Group</p>
            </div>
          </div> */}
          <div id="list-block-classes" className="w-full overflow-auto rounded-lg shadow ">
            <table className="w-full mb-10">
              <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
                <tr>
                  <th>{learningAreaText} </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">{learningAreaText} Group</th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">
                    Computation Settings
                  </th>
                  <th className="p-3 text-sm font-semibold tracking-wide text-left">Actions</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100"></tbody>
              {schoolSubjectGroups.length == 0 ?
                (<tr className=' '>
                  <td><span className='p-1 text-xs'>No Subject groups found</span></td>
                </tr>)
                : schoolSubjectGroups.map((group, index) => (
                  <tr className="bg-white" key={index}>
                     <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    {selectedSchoolSubjectGroup.classSubjectGroupId === group.classSubjectGroupId ? (
                      <div className="flex items-center space-x-2">
                        <input type="checkbox" id="myCheckbox" 
                          checked={true}
                          // onChange={e => toggleStudentSubjects(subjectStudent.studentSubjectId, e.target.checked)}
                          onChange={e => setSelectedSchoolSubjectGroup(group)}
                          className="form-checkbox text-blue-500 h-5 w-5" defaultChecked />
                        <label htmlFor="myCheckbox" className="text-gray-700"></label>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-2">
                        <input type="checkbox" id="myCheckbox"
                          checked={false}
                          // onChange={e => toggleStudentSubjects(subjectStudent.studentSubjectId, e.target.checked)}
                          onChange={e => setSelectedSchoolSubjectGroup(group)}
                          className="form-checkbox text-blue-500 h-5 w-5" />
                        <label htmlFor="myCheckbox" className="text-gray-700"></label>
                      </div>
                    )}
                  </td>
                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                      {group.subjectGroupName}
                    </td>

                    <td >
                      <div className='flex flex-row space-x-1 text-xs ml-3'>
                        <p>{isNull(group.subjectGroupComputationSetting) ? '  ----  ' : group.subjectGroupComputationSetting}</p>
                        <p onClick={() => {
                          if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                            setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                            setShowError(true)
                          } else {
                            setSelectedPageCrudAction(pageCrudActions[8])
                            setSelectedSchoolSubjectGroup(group)
                          }
                        }} className=' cursor-pointer'><BsFillPencilFill /></p>
                      </div>

                    </td>
                    <td className="p-3 flex space-x-2">
                      {/* onClick={toggleShowStudentDetailsModal} */}
                      <button onClick={() => {
                        if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                          setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                          setShowError(true)
                        } else {
                          handleConfirmDeleteSubjectGroup(group)
                        }
                      }

                      } className="border p-2 rounded-lg text-sm">Delete</button>
                      <button onClick={() => {
                        if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                          setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                          setShowError(true)
                        } else {
                          pickSubjectGroupForEditing(group)
                        }
                      }
                      } className="border p-2 rounded-lg text-sm">Edit</button>
                      <button onClick={() => {
                        if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                          setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                          setShowError(true)
                        } else {

                          setSelectedSchoolSubjectGroup(group)
                          
                          //pickSubjectGroupForEditing(group)
                          handleOnClickAddNewSubject()
                        }
                      }
                      } className="border p-2 rounded-lg text-sm">Add {learningAreaText}</button>

                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Subjects
import React, { useEffect, useState } from 'react'
import CustomButton from "../../components/CustomButton";
import CustomDropdown from "../../components/CustomDropdown"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import { FaPlus } from "react-icons/fa6";
import Modal from '../../components/Modal';
import { CiSearch } from "react-icons/ci";
import { IoSearchSharp } from "react-icons/io5";
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import { ACADEMIC_BLOCK_ID_844, ACTIVE_STATUS, DELETED_STATUS, FORBIDEN_MESSAGE, INVALID_API_RESPONSE, LOGGED_IN_USER_TOKEN_DETAILS, SCHOOL_ID_LOCALSTORAGE_KEY } from '../../configs/constants';
import httpService from '../../services/HttpService';
import { getFromLocalStorage, saveToLocalStorage } from '../../services/localStorageService';
import { addKeysAndValuesToArray, convertCSVToJson, extractStringsFromArray, extractStringsFromArrayWithActiveStatus, pickSelectedObject, removeObjectsWithNullKey } from '../../services/Utilities';
import SuccessfulDialog from '../../components/SuccessfulDialog';



function Grading() {

  const [importJsonData, setImportJsonData] = useState([]);
  const [finalCsvData, setFinalCsvData] = useState([])
  const [displayCsvData, setDisplayCsvData] = useState(false)
  const [openSearchOptions, setOpenSearchOptions] = useState(false);
  const [openActionButtons, setOpenActionButtons] = useState(false);
  //const classLevels = ['Pre Primary', 'Lower Primary', 'Upper primary', 'Junior Secondary', 'Senior Secondary'];
  //const blockClasses = ['Play Group', 'PP1', 'PP2'];
  // const classSubjects = ['English', 'Kiswahili', 'Mathematics'];
  const classSubjectGradeScaleArray = [
    {
      gradeId: 1,
      classSubjectId: 101,
      subjectId: 201,
      classId: 301,
      grade: "A",
      classTeacherRemarks: "Excellent",
      gradePoints: 4.0,
      lowerLimitMarks: 90.0,
      upperLimitMarks: 100.0,
      status: "ACTIVE",
    },
    {
      gradeId: 2,
      classSubjectId: 102,
      subjectId: 202,
      classId: 302,
      grade: "B",
      classTeacherRemarks: "Good",
      gradePoints: 3.0,
      lowerLimitMarks: 80.0,
      upperLimitMarks: 89.9,
      status: "INACTIVE",
    },
  ];


  const [editGradeScale, setEditGradeScale] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [schoolBlockClasses, setSchoolBlockClasses] = useState([]); //getFromLocalStorage(BLOCK_CLASSES_LOCALSTORAGE_KEY)
  const [schoolBlockClassesDropdownOptions, setSchoolBlockClassesDropdownOptions] = useState([]);
  const [schoolBlockClassesDropdownOption, setSchoolBlockClassesDropdownOption] = useState('');
  const [selectedSchoolBlockClass, setSelectedSchoolBlockClass] = useState({})
  const [classSubjects, setClassSubjects] = useState([]); //getFromLocalStorage(BLOCK_CLASSES_LOCALSTORAGE_KEY)
  const [classSubjectsDropdownOptions, setClassSubjectsDropdownOptions] = useState([]);
  const [selectedClassSubjectsDropdownOption, setSelectedClassSubjectsDropdownOption] = useState('');
  const [selectedClassSubject, setSelectedClassSubject] = useState({})
  const [selectedSubjectGradeScale, setSelectedSubjectGradeScale] = useState([])
  const [selectedSubjectGrade, setSelectedSubjectGrade] = useState({})

  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS));
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [userPassword, setUserPassword] = useState("");
  const [learningAreaText, setLearningAreaText] = useState('Learning Area');

  const processLoginAndAccessToken = async () => {

    if (userPassword.length == 0) {
      setErrorMessage('Please enter your password')
      setShowError(true)
      return;
    }


    try {
      const payLoad = {
        "username": loggedInUser.mobileNumber,
        "password": userPassword
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken //this token isnt really needed
      };
      const response = await httpService.request('POST', 'auth/schoolStaffLogin', payLoad, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          saveToLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS, response.body)
          setLoggedInUser(response.body)
          setIsSessionExpired(false)

          setSuccessMessage("Successfully logged in")
          setShowSuccessMessage(true)

          //action after refreshing token
          fetchBlockClasses()

        }

        else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      console.error('Exception:' + error)
      // setErrorMessage(INVALID_API_RESPONSE);
      // setShowError(true)

      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }


    }
  };



  const handleCSVInputChange = (event) => {

    validateGradesCsvUpload();

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;
      const jsonData = convertCSVToJson(csvData);
      //remove object that has grade as null
      const filteredArray = removeObjectsWithNullKey(jsonData, 'grade');
      console.log('filtered array: ', filteredArray);
      setImportJsonData(filteredArray);
      // Reset the value of the file input to clear it
      event.target.value = "";
    };

    reader.readAsText(file);
  };


  useEffect(() => {
    console.log(importJsonData)

    const keysToAdd = [
      { key: "classSubjectId", value: selectedClassSubject.classSubjectId },
      { key: "subjectId", value: selectedClassSubject.subjectId },
      { key: "subjectName", value: selectedClassSubject.subjectName },
      { key: "classId", value: selectedSchoolBlockClass.classId },
      { key: "gradeStatus", value: "Active" }
    ];

    const newArray = addKeysAndValuesToArray(importJsonData, keysToAdd);
    console.log('new array', newArray);
    // if(newArray.length>0){

    // }
    setFinalCsvData(newArray)

  }, [importJsonData]);

  useEffect(() => {
    if (finalCsvData.length > 0) {
      setDisplayCsvData(true)
    }

  }, [finalCsvData]);




  const [searchSubjectGradeScaleParams, setSearchSubjectGradeScaleParams] = useState([{ key: 'schoolId', value: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) },
  { key: 'size', value: '40' }, { key: 'page', value: '0' }, { key: 'gradeStatus', value: 'Active' }, { key: 'classId', value: '0' },
  { key: 'classSubjectId', value: 0 }, { key: 'status', value: ACTIVE_STATUS }])

  const updateOrAddSearchStudentParams = (keyToUpdate, newObject) => {
    const index = searchSubjectGradeScaleParams.findIndex(obj => {
      return obj.key === keyToUpdate;
    });

    if (index !== -1) {
      // If exists, update the existing object
      setSearchSubjectGradeScaleParams(prevParams => [
        ...prevParams.slice(0, index),
        { ...prevParams[index], ...newObject },
        ...prevParams.slice(index + 1),
      ]);
    } else {
      // If doesn't exist, add the new object
      setSearchSubjectGradeScaleParams(prevParams => [...prevParams, newObject]);
    }
  };

  const getSearchString = (item, searchParams) => {
    const searchString = searchParams.map((param) => `${param.key}=${param.value}`).join('&');
    return `${item}?${searchString}`; //i.e item="search?"
  };


  function validateGradesCsvUpload() {
    if (selectedSchoolBlockClass.classId == null) {
      console.log('no class selected')
      setErrorMessage("Please select class first");
      setShowError(true)
      return;
    }

    //make sure selectedSchoolBlockClass & selectedClassSubject are selected
    if (selectedClassSubject.classSubjectId == null) {
      console.log('no class selected')
      setErrorMessage("Please select "+{learningAreaText});
      setShowError(true)
      return;
    }
  }


  useEffect(() => {
    fetchBlockClasses()
  }, []);



  useEffect(() => {
    setSchoolBlockClassesDropdownOptions(extractStringsFromArray(schoolBlockClasses, "className"))
  }, [schoolBlockClasses]);



  useEffect(() => {
    if(selectedSchoolBlockClass.academicBlockId === ACADEMIC_BLOCK_ID_844){
      setLearningAreaText('Subject')
      
    }else{
      setLearningAreaText('Learning Area')
    }
    //search class subjects
    search('searchSubjects')
  }, [selectedSchoolBlockClass]);

  useEffect(() => {
    //set subjects drop down options
    setClassSubjectsDropdownOptions(extractStringsFromArray(classSubjects, "subjectName"))
  }, [classSubjects]);

  useEffect(() => {
    //search grade scale
    search('searchSubjectGradeScale')

  }, [selectedClassSubject]);


  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmAction = () => {
    // Your confirmation action logic here
    console.log('Confirmed action: ', selectedPageAction);
    processPageAction();
    setIsConfirmationOpen(false);
  };

  const pageActions = ['deleteGrade', 'editGrade'];
  const [selectedPageAction, setSelectedPageAction] = useState('');

  function processPageAction() {
    if (selectedPageAction == pageActions[0]) { //deleteGrade
      selectedSubjectGrade.gradeStatus = DELETED_STATUS;
      selectedSubjectGrade.status = DELETED_STATUS;
      crudSubjectGrade(selectedSubjectGrade)
    }
  }

  const uploadBulkGradeScale = async (data) => {
    setDisplayCsvData(false);
    setIsLoading(true);
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'onboarding/upload/SubjectGradeScale/'+selectedClassSubject.classSubjectId, data, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          //search('searchSubjectGradeScale');
          setSelectedSubjectGradeScale(response.body)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }

    }
  }

  const crudSubjectGrade = async (data) => {
    //localhost:8080/v1/api/crud/SubjectGradeScale
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'onboarding/crud/SubjectGradeScale', data, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          search('searchSubjectGradeScale');
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }

    }
  }







  const search = async (searchItem) => {
    if (searchItem == 'searchSubjectGradeScale') {
      const classSubjectIdHasZeroValues = searchSubjectGradeScaleParams.some(item => (item.key === 'classSubjectId') && parseInt(item.value, 10) === 0);
      if (classSubjectIdHasZeroValues) {
        console.log(' classSubjectId is zero value ')
        return;
      }
      setSelectedSubjectGradeScale([])
    }

    if (searchItem == 'searchSubjects') {
      const classIdhasZeroValues = searchSubjectGradeScaleParams.some(item => (item.key === 'classId') && parseInt(item.value, 10) === 0);
      if (classIdhasZeroValues) {
        console.log('classId is zero ')
        return;
      }
    }
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', getSearchString('onboarding/' + searchItem, searchSubjectGradeScaleParams), null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          if (searchItem == 'searchSubjectGradeScale') { setSelectedSubjectGradeScale(response.body.content) }
          else { //searchSubjects
            setClassSubjects(response.body.content)
          }
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }

    }
  }




  const fetchBlockClasses = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/getSchoolBlockClasses/' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY), null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSchoolBlockClasses(response.body);

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }

    }
  }

  const handleGradeScaleInputChange = (e) => {
    // Update the state with the new value
    setSelectedSubjectGrade((prevGrade) => ({
      ...prevGrade,
      [e.target.name]: e.target.value,
    }));
  };



  return (
    <div className='p-5 h-screen bg-gray-100 w-full overflow-y-auto'>
      <Modal isVisible={isSessionExpired} onClose={() => setIsSessionExpired(false)}>
        <div className='flex flex-col p-4'>
          <p className={`text-xs text-red-500`}>Session expired, please login  again to refresh your session</p>
          <div className='grid grid-cols-1 md:grid-cols-2 mt-2 gap-1 md:gap-2'>
            <div className="flex flex-col py-2 ">
              <label className=" text-black text-xs">Phone number</label>
              <input
                value={loggedInUser.mobileNumber}
                id="phone"
                className={` border-red-500 text-xs rounded-lg bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none`}
                type={"phone"}
              ></input>

            </div>
            <div className="flex flex-col py-2">
              <label className="text-black text-xs">Password</label>
              <input
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                id="password"
                className="rounded-lg text-xs bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none"
                type={"password"}
              ></input>
            </div>
          </div>
          <CustomButton
            // bg-gradient-to-b from-[#FEE6F7] to-[#f8aee2]
            buttonStyle={
              "w-full my-5 py-4 bg-blue-400 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
            }
            buttonText={"Login"}
            onClick={() => {
              processLoginAndAccessToken()
            }}
          />

        </div>
      </Modal>
      <LoadingDialog message={'Processing'} isOpen={isLoading} />
      <ErrorDialog message={errorMessage} isOpen={showError} onClose={() => setShowError(false)} />
      <SuccessfulDialog message={successMessage} isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} />
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmAction}
        message={confirmationMessage}
      />
      <Modal isVisible={editGradeScale} onClose={() => setEditGradeScale(false)}>
        <div className='flex flex-col overflow-auto'>
          <p className='text-sm'>Edit {selectedSchoolBlockClass.className} {selectedClassSubject.subjectName} Grade Scale</p>
          <div className='grid grid-cols-2 gap-2 mt-3'>
            <div>
              <div className="relative mt-2">
                <input type="text" name='lowerLimitMarks' onChange={handleGradeScaleInputChange} value={selectedSubjectGrade.lowerLimitMarks} id="lowerLimitMarks" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="lowerLimitMarks" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Lower Limit</label>
              </div>
            </div>
            <div>

              <div className="relative mt-2">
                <input type="text" name='upperLimitMarks' onChange={handleGradeScaleInputChange} value={selectedSubjectGrade.upperLimitMarks} id="upperLimitMarks" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="upperLimitMarks" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Upper Limit</label>
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-2 mt-2'>
            <div>
              <div className="relative mt-2">
                <input type="text" name='grade' onChange={handleGradeScaleInputChange} value={selectedSubjectGrade.grade} id="grade" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="grade" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Grade</label>
              </div>
            </div>
            <div>
              <div className="relative mt-2">
                <input type="text" name='gradeAlias' onChange={handleGradeScaleInputChange} value={selectedSubjectGrade.gradeAlias} id="gradeAlias" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="gradeAlias" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Grade Alias</label>
              </div>
            </div>

          </div>
          <div className='grid grid-cols-2 gap-2 mt-2'>
            <div>
              <div className="relative mt-2">
                <input type="text" name='points' onChange={handleGradeScaleInputChange} value={selectedSubjectGrade.points} id="points" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="points" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Points</label>
              </div>
            </div>
            <div>
              <div className="relative mt-2">
                <input type="text" name='remarks' onChange={handleGradeScaleInputChange} value={selectedSubjectGrade.remarks} id="remarks" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="remarks" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Remarks</label>
              </div>
            </div>

          </div>
          <CustomButton
            buttonStyle={
              "w-full mt-4 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
            }
            buttonText={"Save"}
            onClick={() => {
              crudSubjectGrade(selectedSubjectGrade)
              setEditGradeScale(false)
            }
            }
          />
        </div>
      </Modal>
      <Modal isVisible={displayCsvData} onClose={() => setDisplayCsvData(false)}>
        <div className='flex flex-col overflow-auto max-h-96'>
          <p className='text-xs'>Save {selectedSchoolBlockClass.className} {selectedClassSubject.subjectName} Grade Scale</p>
          <table className="w-full mb-2 mt-2">
            <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
              <tr>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">Lower Limit</th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">Upper Limit</th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">Grade</th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">{selectedSchoolBlockClass.academicBlockId === ACADEMIC_BLOCK_ID_844 ? 'Points' : 'Rubric'}</th>
                {/* <th className="p-3 text-sm font-semibold tracking-wide text-left">Remarks</th> */}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              {finalCsvData.length == 0 ?
                (<tr>
                  <td>No data imported</td>
                </tr>)

                : finalCsvData.map((subjectGrade, index) => (
                  <tr className="bg-white" key={index} >
                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <a href="#" className="font-bold text-blue-500 hover:underline">{subjectGrade.lowerLimitMarks}</a>
                    </td>
                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                      {subjectGrade.upperLimitMarks}
                    </td>
                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{subjectGrade.gradeAlias}</span>
                    </td>
                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{subjectGrade.points}</td>
                    {/* <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{subjectGrade.classTeacherRemarks}</td> */}
                  </tr>
                ))}

            </tbody>
          </table>
          <CustomButton
            buttonStyle={
              "w-full py-4 text-xs text-white bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
            }
            buttonText={"Save"}
            onClick={() => { uploadBulkGradeScale(finalCsvData) }}
          />
        </div>
      </Modal>
      <div className='flex flex-col'>
        <div id="menu-controller" className="flex w-full justify-end space-x-2 p-2 ">
          <div onClick={() => setOpenSearchOptions(!openSearchOptions)} className="flex items-center rounded-lg border p-2 cursor-pointer text-sm bg-white">
            <p className="text-sm text-gray-600">Search options</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openSearchOptions && "rotate-180"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
          <div onClick={() => setOpenActionButtons(!openActionButtons)} className="flex items-center rounded-lg border cursor-pointer p-2 text-sm bg-white">
            <p className="text-sm text-gray-600">Actions</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openActionButtons && "rotate-180"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
        </div>
        <div id="class-filters" className="flex flex-col md:flex-row items-end space-x-0  md:space-x-4 justify-end">
          <div className={`flex flex-col md:flex-row items-end space-x-0 md:space-x-4 w-full md:w-auto transition-opacity duration-300 ${!openSearchOptions && "hidden"}`}>
            {/* <div className=' text-sm w-full md:w-auto'>
              <p>Select Level</p>

              <CustomDropdown options={classLevels} style={'w-full md:w-52'} />
            </div> */}

            <div className='text-sm w-full md:w-auto'>
              <p>Select Class</p>
              <CustomDropdown options={schoolBlockClassesDropdownOptions}
                onOptionSelect={(valueSelected) => {
                  //setSelectedPageCrudAction(pageCrudActions[6]);
                  console.log('valueSelected', valueSelected)
                  console.log('schoolBlockClasses', schoolBlockClasses)
                  const selected = pickSelectedObject(schoolBlockClasses, 'className', valueSelected);
                  updateOrAddSearchStudentParams('classId', { key: 'classId', value: selected.classId });
                  setSelectedSchoolBlockClass(selected);

                }} style={'w-full md:w-52'} />
            </div>
            <div className='text-sm w-full md:w-auto'>
              <p>Select {learningAreaText}</p>
              <CustomDropdown options={classSubjectsDropdownOptions}
                onOptionSelect={(valueSelected) => {
                  const selected = pickSelectedObject(classSubjects, 'subjectName', valueSelected);
                  updateOrAddSearchStudentParams('classSubjectId', { key: 'classSubjectId', value: selected.classSubjectId });
                  setSelectedClassSubject(selected)
                }} style={'w-full md:w-52'} />
            </div>
            <div onClick={() => { search('searchSubjectGradeScale') }} className="rounded-lg m-2 py-4 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full md:w-52 bg-green-600 text-white space-x-1">
              <IoSearchSharp />
              <p>View</p>
            </div>
          </div>
          <div className={`${!openActionButtons && "hidden"} w-full flex flex-col md:flex-row space-x-0  md:space-x-4 justify-end`}>
            <input className={` my-2 w-full border p-2 md:w-auto rounded-lg text-sm focus:outline-none border-gray-500 shadow-sm`} placeholder="By Lastname" type="file" accept=".csv" onChange={handleCSVInputChange} />
            {/* <div onClick={() => {}} className={` rounded-lg m-2 py-2 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full md:w-52 bg-green-600 text-white space-x-1`}>
              <FaPlus />
              <p>Import for csv</p>
            </div> */}
          </div>

        </div>

      </div>

      {/* hidden md:block */}
      <div className="w-full overflow-auto rounded-lg shadow ">
        <table className="w-full mb-10">
          <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Lower Limit</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Upper Limit</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Grade</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Alias</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Rubric</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Remarks</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {selectedSubjectGradeScale.length == 0 ?
              (<tr className=""  >
                <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                  <a href="#" className="font-bold text-blue-500 hover:underline">No Subject Grade scale to show</a>
                </td></tr>
              )
              : selectedSubjectGradeScale.map((subjectGrade, index) => (
                <tr className="bg-white" key={index} >
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    <a href="#" className="font-bold text-blue-500 hover:underline">{subjectGrade.lowerLimitMarks}</a>
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    {subjectGrade.upperLimitMarks}
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{subjectGrade.grade}</span>
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{subjectGrade.gradeAlias}</span>
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{subjectGrade.points}</td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{subjectGrade.remarks}</td>
                  <td className="p-3 flex space-x-2">
                    <button onClick={() => {
                      console.log('subjectGrade', subjectGrade)
                      setSelectedPageAction(pageActions[1])
                      setSelectedSubjectGrade(subjectGrade);
                      setEditGradeScale(true);
                    }} className="border p-2 rounded-lg text-sm">Edit</button>
                    <button
                      onClick={() => {
                        setSelectedPageAction(pageActions[0])
                        setSelectedSubjectGrade(subjectGrade);
                        setConfirmationMessage('Confirm you want to delete Rubric ' + subjectGrade.grade + '?')
                        setIsConfirmationOpen(true);
                      }}
                      className="border p-2 rounded-lg text-sm">Delete</button>
                  </td>
                </tr>
              ))}

          </tbody>
        </table>
      </div>



    </div>
  )
}

export default Grading
import React from 'react'

function SuccessfulDialog( {message, isOpen, onClose} ) {
  return (
    <div
            className={`fixed inset-0 flex items-center justify-center z-50 transition-opacity duration-300 ${isOpen
                    ? 'opacity-100 backdrop-filter backdrop-blur-md'
                    : 'opacity-0 pointer-events-none'
                }`}
        >
            <div className="bg-white p-8 rounded-lg shadow-md flex flex-col items-center">
                <p className="text-lg text-green-600 font-semibold">{message}</p>
                <button
                    className="mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:shadow-outline-red"
                    onClick={onClose}
                >
                    Okay
                </button>
            </div>
        </div>
  )
}

export default SuccessfulDialog
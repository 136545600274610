import React, { useEffect, useState } from 'react'
import CustomDropdown from "../../components/CustomDropdown"
import CustomButton from "../../components/CustomButton";
import { getFromLocalStorage, saveToLocalStorage } from '../../services/localStorageService';
import { ACTIVE_STATUS, DELETED_STATUS, FORBIDEN_MESSAGE, INVALID_API_RESPONSE, LOGGED_IN_USER_TOKEN_DETAILS, SCHOOLDATA_LOCALSTORAGE_KEY, SCHOOL_ID_LOCALSTORAGE_KEY, SCHOOL_STREAMS_LOCALSTORAGE_KEY } from '../../configs/constants';
import Modal from '../../components/Modal';
import httpService from '../../services/HttpService';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import SuccessfulDialog from '../../components/SuccessfulDialog';
import { convertCSVToJson, extractStringsFromArray, isNull, pickSelectedObject, removeObjectsWithNullKey } from '../../services/Utilities';
import CustomDropdownWithCancelIcon from '../../components/CustomDropdownWithCancelIcon';
import { BsFillChatTextFill, BsFillPencilFill } from 'react-icons/bs';
// import { BsArrowRepeat, BsCheck, BsPlus, BsFillChatTextFill } from "react-icons/bs";


function Guardians() {
    const genderOptions = ['Male', 'Female'];
    const [isAddGuardian, setIsAddGuardian] = useState(false);
    const [importGuardians, setImportGuardians] = useState(false);
    const [schoolData, setSchoolData] = useState(getFromLocalStorage(SCHOOLDATA_LOCALSTORAGE_KEY))
    const [loggedInUser, setLoggedInUser] = useState(getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS));
    const [isSessionExpired, setIsSessionExpired] = useState(false)
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [userPassword, setUserPassword] = useState("");
    const [selectedBlockClassOption, setSelectedBlockClassOption] = useState('');
    const [selectedBlockClass, setSelectedBlockClass] = useState({})
    const [academicBlockClasses, setAcademicBlockClasses] = useState([])
    const [academicBlockClassesOptions, setAcademicBlockClassesOptions] = useState(['class1', 'class2']);
    const [guardian, setGuardian] = useState({})
    const [selectedGender, setSelectedGender] = useState('')
    const [importJsonData, setImportJsonData] = useState([]);
    const [finalCsvData, setFinalCsvData] = useState([])
    const [payloadValidated, setPayloadValidated] = useState(false)
    const pageActions = ['addGuardian', 'editGuardian', 'deleteGuardian', 'deleteAssociation', 'sendSms' , 'editGuardianMobileNo'];
    const [pageAction, setPageAction] = useState('');
    const [searchGuardianMappingParams, setSearchGuardianMappingParams] = useState([{ key: 'status', value: 'Active' }]);
    const [schoolClasses, setSchoolClasses] = useState([])

    const [paginationTotalPages, setPaginationTotalPages] = useState(0)
    const [paginationPageNo, setPaginationPageNo] = useState(1)
    const [paginationPageSize, setPaginationPageSize] = useState(30);
    const [schoolBlockClassesDropdownOptions, setSchoolBlockClassesDropdownOptions] = useState([]);

    //search guardian mapping params start
    const [schoolAdmissionNumber, setSchoolAdmissionNumber] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("")
    const [guardianMappings, setGuardianMappings] = useState([])
    const [guardianMapping, setGuardianMapping] = useState({})
    const [selectText, setSelectText] = useState()
    const [selectedGuardians, setSelectedGuardians] = useState([])
    const [messageToBroadcast, setMessageToBroadcast] = useState("")
    const [messageLength, setMessageLength] = useState(0)
    const [smsPages, setSmsPages] = useState(1);

    useEffect(() => {
        console.log("selectedGuardians size:", selectedGuardians.length)
        console.log("selectedGuardians : ", selectedGuardians)
        if (selectedGuardians.length === 0) {
            setPageAction("")
        }
    }, [selectedGuardians]);

    useEffect(() => {
        setMessageLength(messageToBroadcast.length)
        if (messageToBroadcast.length <= 160) {
            setSmsPages(1)
        }
        if (messageToBroadcast.length > 160 && messageToBroadcast.length <= 320) {
            setSmsPages(2)
        }
        if (messageToBroadcast.length > 320 && messageToBroadcast.length <= 480) {
            setSmsPages(3)
        }
        if (messageToBroadcast.length > 480 && messageToBroadcast.length <= 640) {
            setSmsPages(4)
        }
        updateMessageInSmsToPublishToQueue(messageToBroadcast)
    }, [messageToBroadcast]);


    function updateMessageInSmsToPublishToQueue(sms) {
        setSelectedGuardians(prevArray => {
            // Map over the previous array and update the 'sms' property in each object
            return prevArray.map(data => {
                // Create a new object with updated 'sms' property
                return { ...data, sms: sms };
            });
        });
    }


    function updateSelectedGuardians(smsMessage) {
        console.log("Called updateSelectedGuardians");

        // Filter guardianMappings to include only checked guardians
        const filteredGuardians = guardianMappings.filter(guardianMapping => guardianMapping.isChecked === "checked");

        //setSelectedGuardians([])

        // Map filteredGuardians to create smsPayload objects and add them to selectedGuardians array
        filteredGuardians.forEach(guardian => {
            // Create smsPayload object
            const smsPayload = {
                schoolId: schoolData.schoolId, // I assume you'll assign the correct schoolId here
                studentIdentifier: guardian.schoolAdmissionNumber,
                classIdentifier: guardian.classId,
                internalIdIdentifier: guardian.classId, // I assume you meant classIdentifier here
                sms: smsMessage,
                broadcast: true,
                recipient: guardian.phoneNumber,
                senderId: schoolData.senderId,
            };

            // Add guardian to selectedGuardians array
            setSelectedGuardians(prevArray => [...prevArray, smsPayload]);
        });
    }



    // function updateSelectedGuardians() {
    //     console.log("Called updateSelectedGuardians")
    //     const filteredGuardians = guardianMappings.filter(guardianMapping => guardianMapping.isChecked === "checked");
    //     filteredGuardians.map(guardian => {
    //         const smsPayload = {
    //             "schoolId" : 0;
    //        studentIdentifier="";
    //        classIdentifier = guardian.classId;
    //         internalIdIdentifier = guardian.classId;; //have classIdentifier
    //         sms = messageToBroadcast;
    //         broadcast = true;
    //         recipient = guardian.phoneNumber;
    //         senderId = schoolData.senderId;
    //         };
    //         setSelectedGuardians(prevArray => [...prevArray, guardian]);
    //     })

    // }


    function toggleGuardianMapping(mappingId, selected) {
        setGuardianMappings(guardianMappings => {
            return guardianMappings.map(guardianMapping => {
                if (guardianMapping.mappingId === mappingId) {
                    return { ...guardianMapping, isChecked: selected ? "checked" : "notChecked" };
                }

                return guardianMapping;
            });
        });
        //updateSelectedGuardians()
    }

    function toggleAllGuardianMappings(isSelected) {
        const dataToLoop = guardianMappings;
        setGuardianMappings([])
        console.log('Im selected --> ', isSelected)
        if (isSelected) {
            setSelectText('Remove All')
        } else {
            setSelectText('Select All')
        }

        const updatedData = dataToLoop.map(item => ({ ...item, isChecked: isSelected ? 'checked' : 'notChecked' }));
        setGuardianMappings(updatedData);
        //updateSelectedGuardians()
    }

    useEffect(() => {
        setSelectedGuardians([])
        //console.log('changed guardianMappings ', guardianMappings)
        updateSelectedGuardians("")
    }, [guardianMappings]);

    useEffect(() => {
        if (isNull(schoolAdmissionNumber)) {
            console.log('schoolAdmissionNumber is nun')
            setSearchGuardianMappingParams(removeObjectFromArray(searchGuardianMappingParams, 'schoolAdmissionNumber'))
        } else {
            console.log('schoolAdmissionNumber is not nun')
            updateOrAddSearchStudentGuardianMappingParams('schoolAdmissionNumber', { key: 'schoolAdmissionNumber', value: schoolAdmissionNumber })
        }
    }, [schoolAdmissionNumber]);

    useEffect(() => {
        if (isNull(phoneNumber)) {
            console.log('phoneNumber is nun')
            setSearchGuardianMappingParams(removeObjectFromArray(searchGuardianMappingParams, 'phoneNumber'))
        } else {
            console.log('phoneNumber is not nun')
            updateOrAddSearchStudentGuardianMappingParams('phoneNumber', { key: 'phoneNumber', value: phoneNumber })
        }
    }, [phoneNumber]);



    function removeObjectFromArray(array, keyToRemove) {
        // Find the index of the object with the provided key
        const indexToRemove = array.findIndex(obj => obj.key === keyToRemove);
        // If the object with the key is found, remove it from the array
        if (indexToRemove !== -1) {
            const newArray = [...array.slice(0, indexToRemove), ...array.slice(indexToRemove + 1)];
            return newArray;
        }
        // If the key is not found, return the original array
        return array;
    }


    const updateOrAddSearchStudentGuardianMappingParams = (keyToUpdate, newObject) => {
        const index = searchGuardianMappingParams.findIndex(obj => {
            return obj.key === keyToUpdate;
        });

        if (index !== -1) {
            // If exists, update the existing object
            setSearchGuardianMappingParams(prevParams => [
                ...prevParams.slice(0, index),
                { ...prevParams[index], ...newObject },
                ...prevParams.slice(index + 1),
            ]);
        } else {
            // If doesn't exist, add the new object
            setSearchGuardianMappingParams(prevParams => [...prevParams, newObject]);
        }
    };



    useEffect(() => {
        console.log('changed importJsonData ', importJsonData)
    }, [importJsonData]);

    useEffect(() => {
        console.log('changed searchGuardianMappingParams ', searchGuardianMappingParams)
    }, [searchGuardianMappingParams]);



    //searchStudents()
    useEffect(() => {
        const urlString = getSearchGuardianStudentMappingString() + "&page=" + paginationPageNo + "&schoolId=" + schoolData.schoolId + "&size=" + paginationPageSize;
        searchStudents(urlString)
    }, [schoolClasses]);



    useEffect(() => {
        //console.log('selectedBlockClass ',selectedBlockClass)
    }, [selectedBlockClass]);

    // selectedBlockClass


    useEffect(() => {
        fetchSchoolClasses()
    }, []);


    useEffect(() => {
        console.log("paginationPageNo [[[[]]] ", paginationPageNo)
        if (paginationPageNo !== 0) {
            const urlString = getSearchGuardianStudentMappingString() + "&page=" + paginationPageNo + "&schoolId=" + schoolData.schoolId + "&size=" + paginationPageSize;

            searchStudents(urlString)
        }

    }, [paginationPageNo]);








    const handleCSVInputChange = (event) => {

        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const csvData = e.target.result;
            const jsonData = convertCSVToJson(csvData);

            //remove object that has grade as null
            const filteredArray = removeObjectsWithNullKey(jsonData, 'phoneNumber');
            console.log('filtered array: ', filteredArray);

            setImportJsonData(filteredArray);
            // Reset the value of the file input to clear it
            event.target.value = "";
        };

        reader.readAsText(file);
    };



    function clearGuardian() {
        setGuardian(prevState => ({
            ...prevState,
            firstName: '',
            lastName: '',
            otherNames: '',
            idNumber: '',
            phoneNumber: '',
            password: '',
            status: '',
            gender: '',
            studentAdmissionNumber: '',
            guardianId: 0
        }));
    }



    function validateCrudGuardian() {
        //setPayloadValidated(false)
        if (isNull(guardian.firstName)) {
            setIsAddGuardian(false);
            setErrorMessage("First name cant be empty");
            setShowError(true)
            return;
        }
        if (isNull(guardian.lastName)) {
            setIsAddGuardian(false);
            setErrorMessage("Last name cant be empty");
            setShowError(true)
            return;
        }
        if (isNull(guardian.phoneNumber)) {
            setIsAddGuardian(false);
            setErrorMessage("Mobile number cant be empty");
            setShowError(true)
            return;
        }
        if (isNull(guardian.gender)) {
            setIsAddGuardian(false);
            setErrorMessage("Gender cant be empty");
            setShowError(true)
            return;
        }
        if (guardian.gender !== 'Male' && guardian.gender !== 'Female') {
            setIsAddGuardian(false);
            setErrorMessage("Gender must either be Male or Female");
            setShowError(true)
            return;
        }

        guardian.schoolId = schoolData.schoolId;

        console.log('pageAction ', pageAction)

        //['addGuardian', 'editGuardian','deleteGuardian'];
        if (pageAction === pageActions[0]) {
            guardian.status = ACTIVE_STATUS;
            // setGuardian(prevState => ({
            //     ...prevState,
            //     status: ACTIVE_STATUS
            // }));
        }
        if (pageAction === pageActions[2]) {
            // setGuardian(prevState => ({
            //     ...prevState,
            //     status: DELETED_STATUS
            // }));
        }

        console.log('guardian  >>> ', guardian)
        //setPayloadValidated(true)

        crudGuardian(guardian);
    }

    const removeAssociation = async () => {

        setIsLoading(true)

        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + loggedInUser.accessToken
            };
            const response = await httpService.request('POST', 'onboarding/remove/association', guardianMapping, headers);
            setIsLoading(false)
            console.log('Server Response:', response);
            if (response && response.header) {
                setIsLoading(false)
                if (response.header.responseCode === 200) {
                    clearGuardian()
                    setSuccessMessage(response.header.customerMessage)
                    setShowSuccessMessage(true)
                    searchStudents()
                    //   fetchStudents()
                } else {
                    setErrorMessage(response.header.customerMessage)
                    setShowError(true)
                }
            } else {
                setIsLoading(false)
                setErrorMessage(INVALID_API_RESPONSE);
                setShowError(true)
            }


        } catch (error) {
            setIsLoading(false)
            // Handle errors here
            console.error('Exception:' + error)
            if (error.response) {
                const statusCode = error.response.status;
                console.log('sttausCode inside exception:', statusCode)
                if (statusCode === 403) {
                    setShowError(true)
                    setErrorMessage(FORBIDEN_MESSAGE);
                } else if (statusCode === 401) {
                    if (!isSessionExpired) {
                        setIsSessionExpired(true)
                    }
                } else {
                    setShowError(true)
                    setErrorMessage(INVALID_API_RESPONSE);
                }
            } else {
                setShowError(true)
                setErrorMessage(INVALID_API_RESPONSE);
            }
        }
    };

    const sendMessages = async () => {

        setSelectedGuardians([])
        updateSelectedGuardians(messageToBroadcast);
        setIsLoading(true)
        setPageAction("")
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + loggedInUser.accessToken
            };
            const response = await httpService.request('POST', 'print-outs/publishSmsToQueue', selectedGuardians, headers);
            setIsLoading(false)
            console.log('Server Response:', response);
            if (response && response.header) {
                setIsLoading(false)
                if (response.header.responseCode === 200) {

                    setSuccessMessage(response.header.customerMessage)
                    setShowSuccessMessage(true)

                } else {
                    setErrorMessage(response.header.customerMessage)
                    setShowError(true)
                }
            } else {
                setIsLoading(false)
                setErrorMessage(INVALID_API_RESPONSE);
                setShowError(true)
            }


        } catch (error) {
            setIsLoading(false)
            // Handle errors here
            console.error('Exception:' + error)
            if (error.response) {
                const statusCode = error.response.status;
                console.log('sttausCode inside exception:', statusCode)
                if (statusCode === 403) {
                    setShowError(true)
                    setErrorMessage(FORBIDEN_MESSAGE);
                } else if (statusCode === 401) {
                    if (!isSessionExpired) {
                        setIsSessionExpired(true)
                    }
                } else {
                    setShowError(true)
                    setErrorMessage(INVALID_API_RESPONSE);
                }
            } else {
                setShowError(true)
                setErrorMessage(INVALID_API_RESPONSE);
            }
        }

    }

    const editGuardian = async (guardian) => {
        setPageAction("")
        setIsLoading(true)

        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + loggedInUser.accessToken
            };
            const response = await httpService.request('POST', 'onboarding/edit/guardian', guardian, headers);
            setIsLoading(false)
            console.log('Server Response:', response);
            if (response && response.header) {
                setIsLoading(false)
                if (response.header.responseCode === 200) {
                    clearGuardian()
                    setSuccessMessage(response.header.customerMessage)
                    setShowSuccessMessage(true)
                    const urlString = getSearchGuardianStudentMappingString() + "&page=" + paginationPageNo + "&schoolId=" + schoolData.schoolId + "&size=" + paginationPageSize;
                    searchStudents(urlString)
                    //   fetchStudents()
                } else {
                    setErrorMessage(response.header.customerMessage)
                    setShowError(true)
                }
            } else {
                setIsLoading(false)
                setErrorMessage(INVALID_API_RESPONSE);
                setShowError(true)
            }


        } catch (error) {
            setIsLoading(false)
            // Handle errors here
            console.error('Exception:' + error)
            if (error.response) {
                const statusCode = error.response.status;
                console.log('sttausCode inside exception:', statusCode)
                if (statusCode === 403) {
                    setShowError(true)
                    setErrorMessage(FORBIDEN_MESSAGE);
                } else if (statusCode === 401) {
                    if (!isSessionExpired) {
                        setIsSessionExpired(true)
                    }
                } else {
                    setShowError(true)
                    setErrorMessage(INVALID_API_RESPONSE);
                }
            } else {
                setShowError(true)
                setErrorMessage(INVALID_API_RESPONSE);
            }
        }
    };

    const crudGuardian = async (guardian) => {

        setIsAddGuardian(false);
        setIsLoading(true)

        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + loggedInUser.accessToken
            };
            const response = await httpService.request('POST', 'onboarding/crud/guardian', guardian, headers);
            setIsLoading(false)
            console.log('Server Response:', response);
            if (response && response.header) {
                setIsLoading(false)
                if (response.header.responseCode === 200) {
                    clearGuardian()
                    setSuccessMessage(response.header.customerMessage)
                    setShowSuccessMessage(true)
                    const urlString = getSearchGuardianStudentMappingString() + "&page=" + paginationPageNo + "&schoolId=" + schoolData.schoolId + "&size=" + paginationPageSize;
                    searchStudents(urlString)
                    //   fetchStudents()
                } else {
                    setErrorMessage(response.header.customerMessage)
                    setShowError(true)
                }
            } else {
                setIsLoading(false)
                setErrorMessage(INVALID_API_RESPONSE);
                setShowError(true)
            }


        } catch (error) {
            setIsLoading(false)
            // Handle errors here
            console.error('Exception:' + error)
            if (error.response) {
                const statusCode = error.response.status;
                console.log('sttausCode inside exception:', statusCode)
                if (statusCode === 403) {
                    setShowError(true)
                    setErrorMessage(FORBIDEN_MESSAGE);
                } else if (statusCode === 401) {
                    if (!isSessionExpired) {
                        setIsSessionExpired(true)
                    }
                } else {
                    setShowError(true)
                    setErrorMessage(INVALID_API_RESPONSE);
                }
            } else {
                setShowError(true)
                setErrorMessage(INVALID_API_RESPONSE);
            }
        }
    };

    const importDataFromCsvGuardians = async () => {

        setIsLoading(true)

        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + loggedInUser.accessToken
            };
            const response = await httpService.request('POST', 'onboarding/map/guardians/' + schoolData.schoolId, importJsonData, headers);
            setIsLoading(false)
            console.log('Server Response:', response);
            if (response && response.header) {
                setIsLoading(false)
                if (response.header.responseCode === 200) {
                    setSuccessMessage(response.header.customerMessage)
                    setShowSuccessMessage(true)
                    const urlString = getSearchGuardianStudentMappingString() + "&page=" + paginationPageNo + "&schoolId=" + schoolData.schoolId + "&size=" + paginationPageSize;
                    searchStudents(urlString);
                } else {
                    setErrorMessage(response.header.customerMessage)
                    setShowError(true)
                }
            } else {
                setIsLoading(false)
                setErrorMessage(INVALID_API_RESPONSE);
                setShowError(true)
            }


        } catch (error) {
            setIsLoading(false)
            // Handle errors here
            console.error('Exception:' + error)
            if (error.response) {
                const statusCode = error.response.status;
                console.log('sttausCode inside exception:', statusCode)
                if (statusCode === 403) {
                    setShowError(true)
                    setErrorMessage(FORBIDEN_MESSAGE);
                } else if (statusCode === 401) {
                    if (!isSessionExpired) {
                        setIsSessionExpired(true)
                    }
                } else {
                    setShowError(true)
                    setErrorMessage(INVALID_API_RESPONSE);
                }
            } else {
                setShowError(true)
                setErrorMessage(INVALID_API_RESPONSE);
            }
        }
    };





    const handleGuardianInputChange = (e) => {
        // Update the state with the new value
        setGuardian((prevGrade) => ({
            ...prevGrade,
            [e.target.name]: e.target.value,
        }));
    };

    const handleCloseConfirmation = () => {
        setIsConfirmationOpen(false);
    };

    const handleConfirmAction = () => {
        console.log('Confirmed action');
        setIsConfirmationOpen(false);
        //['addGuardian', 'editGuardian', 'deleteGuardian', 'deleteAssociation'];
        if (pageAction === pageActions[2]) { //delete guardian
            guardian.status = DELETED_STATUS;
            guardian.schoolId = schoolData.schoolId;
            crudGuardian(guardian);
        }
        if (pageAction === pageActions[3]) { //delete association
            guardianMapping.status = DELETED_STATUS;
            console.log('guardianMapping to delete', guardianMapping)
            removeAssociation()
        }

    };

    const fetchSchoolClasses = async () => {
        setIsLoading(true)
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + loggedInUser.accessToken
            };
            const response = await httpService.request('GET', 'onboarding/searchBlockClass?schoolId=' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + '&status=Active', null, headers);
            setIsLoading(false)
            console.log('Server Response:', response);
            if (response && response.header) {
                if (response.header.responseCode === 200) {
                    setSchoolClasses(response.body.content);
                } else {
                    setErrorMessage(response.header.customerMessage)
                    setShowError(true)
                }
            } else {
                setErrorMessage(INVALID_API_RESPONSE);
                setShowError(true)
            }


        } catch (error) {
            setIsLoading(false)
            // Handle errors here
            console.error('Exception:' + error)
            if (error.response) {
                const statusCode = error.response.status;
                console.log('sttausCode inside exception:', statusCode)
                if (statusCode === 403) {
                    setShowError(true)
                    setErrorMessage(FORBIDEN_MESSAGE);
                } else if (statusCode === 401) {
                    if (!isSessionExpired) {
                        setIsSessionExpired(true)
                    }
                } else {
                    setShowError(true)
                    setErrorMessage(INVALID_API_RESPONSE);
                }
            } else {
                setShowError(true)
                setErrorMessage(INVALID_API_RESPONSE);
            }
        }
    };

    useEffect(() => {
        setSchoolBlockClassesDropdownOptions(extractStringsFromArray(schoolClasses, "className"))
    }, [schoolClasses]);


    useEffect(() => {
        console.log(guardian);
    }, [guardian]);

    const processLoginAndAccessToken = async () => {

        if (userPassword.length == 0) {
            setErrorMessage('Please enter your password')
            setShowError(true)
            return;
        }

        setIsLoading(true)

        try {
            const payLoad = {
                "username": loggedInUser.mobileNumber,
                "password": userPassword
            };

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + loggedInUser.accessToken //this token isnt really needed
            };
            const response = await httpService.request('POST', 'auth/schoolStaffLogin', payLoad, headers);
            setIsLoading(false)
            console.log('Server Response:', response);
            if (response && response.header) {
                if (response.header.responseCode === 200) {

                    saveToLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS, response.body)
                    setLoggedInUser(response.body)
                    setIsSessionExpired(false)
                    setSuccessMessage("Successfully logged in")
                    setShowSuccessMessage(true)
                    fetchSchoolClasses();


                }

                else {
                    setErrorMessage(response.header.customerMessage)
                    setShowError(true)
                }
            } else {
                setErrorMessage(INVALID_API_RESPONSE);
                setShowError(true)
            }


        } catch (error) {
            setIsLoading(false)
            // Handle errors here
            console.error('Exception:' + error)
            // setErrorMessage(INVALID_API_RESPONSE);
            // setShowError(true)

            console.error('Exception:' + error)
            if (error.response) {
                const statusCode = error.response.status;
                console.log('sttausCode inside exception:', statusCode)
                if (statusCode === 403) {
                    setShowError(true)
                    setErrorMessage(FORBIDEN_MESSAGE);
                } else if (statusCode === 401) {
                    if (!isSessionExpired) {
                        setIsSessionExpired(true)
                    }
                } else {
                    setShowError(true)
                    setErrorMessage(INVALID_API_RESPONSE);
                }
            } else {
                setShowError(true)
                setErrorMessage(INVALID_API_RESPONSE);
            }


        }
    };

    const handleNextPageV2 = () => {
        if (paginationPageNo < paginationTotalPages) {
            setPaginationPageNo(prevPageNo => prevPageNo + 1);
        }
    }


    const handlePreviousPageV2 = () => {
        if (paginationPageNo > 1) {
            setPaginationPageNo(prevPageNo => prevPageNo - 1);
        }
    }

    const getSearchGuardianStudentMappingString = () => {
        const searchString = searchGuardianMappingParams.map((param) => `${param.key}=${param.value}`).join('&');
        return `onboarding/searchGuardianMapping?${searchString}`;
    };

    // function toggleGuardianMapping(mappingId, selected) {
    //     setSubjectStudents(guardianMappings => {}
    // }



    const searchStudents = async (searchString) => {
        //const searchString = getSearchGuardianStudentMappingString() + "&page=" + paginationPageNo + "&schoolId=" + schoolData.schoolId + "&size=" + paginationPageSize;

        //  const pageCrudActions = ['AddStudent', 'EditStudent', 'DeleteStudent', 'BulkUploadStudents' , 'fetchStudents', 'searchStudents'];
        // setPageAction(pageCrudActions[])
        setIsLoading(true)
        //setPageAction(pageCrudActions[5])
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + loggedInUser.accessToken
            };
            const response = await httpService.request('GET', searchString, null, headers);
            setIsLoading(false)
            console.log('Server Response:', response);
            if (response && response.header) {
                if (response.header.responseCode === 200) {
                    setGuardianMappings(response.body.content);
                    setPaginationTotalPages(response.body.totalPages)
                    //updatePagination('totalPages', response.body.totalPages)
                    // updatePagination('pageNo', response.body.totalPages)
                } else {
                    setErrorMessage(response.header.customerMessage)
                    setShowError(true)
                }
            } else {
                setErrorMessage(INVALID_API_RESPONSE);
                setShowError(true)
            }


        } catch (error) {
            // Handle errors here
            setIsLoading(false)
            console.error('Exception:' + error)
            if (error.response) {
                const statusCode = error.response.status;
                console.log('sttausCode inside exception:', statusCode)
                if (statusCode === 403) {
                    setShowError(true)
                    setErrorMessage(FORBIDEN_MESSAGE);
                } else if (statusCode === 401) {
                    if (!isSessionExpired) {
                        setIsSessionExpired(true)
                    }
                } else {
                    setShowError(true)
                    setErrorMessage(INVALID_API_RESPONSE);
                }
            } else {
                setShowError(true)
                setErrorMessage(INVALID_API_RESPONSE);
            }
        }
    };








    return (
        <>
            <div className="p-5 h-screen bg-gray-100 w-full overflow-y-auto  ">
                <Modal isVisible={pageAction === pageActions[4]} onClose={() => setPageAction("")}>
                    <div className='flex flex-col overflow-auto'>
                        <div>
                            <p>Send Message</p>
                            <p className='text-xs mt-7'>Sending to {selectedGuardians.length} contacts selected</p>
                            <div className="relative mt-2">
                                <textarea
                                    name='messageToBroadcast'
                                    onChange={(e) => setMessageToBroadcast(e.target.value)}
                                    value={messageToBroadcast}
                                    id="messageToBroadcast"
                                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=" "
                                />
                                <label
                                    htmlFor="messageToBroadcast"
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                >
                                    Message
                                </label>
                            </div>

                            <div className='flex flex-row justify-end text-xs'>
                                <p>{messageLength} words : {smsPages} Sms</p>
                            </div>



                            <CustomButton
                                buttonStyle={
                                    " mt-5 text-xs w-full text-white px-4 py-4 cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
                                }
                                buttonText={"Send"}
                                onClick={() => {
                                    if (isNull(messageToBroadcast)) {

                                        setErrorMessage("Set message to send")
                                        setShowError(true)

                                    } else {
                                        sendMessages(messageToBroadcast)
                                    }
                                    //crudGuardian();
                                    //validateCrudGuardian()
                                }}
                            />

                        </div>
                    </div>
                </Modal>
                <Modal isVisible={importGuardians} onClose={() => setImportGuardians(false)}>

                    <div className='flex flex-col p-6'>
                        <p className=' border-b-2'>Upload guardians csv</p>
                        <div className={`p-10 text-xs ${importJsonData.length < 1 && 'hidden'}`}>
                            <p>Successfully imported, click <b>Save</b> to complete </p>
                        </div>
                        <div className=' mt-8 w-full'>
                            <input className={`w-full  border p-2  rounded-lg text-sm focus:outline-none border-gray-500 shadow-sm ${importJsonData.length > 0 && 'hidden'}`} type="file" accept=".csv" onChange={handleCSVInputChange} />
                        </div>
                        <CustomButton
                            buttonStyle={
                                ` ${importJsonData.length < 1 && 'hidden'} text-white my-4  text-sm px-4 py-2 cursor-pointer bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg`
                            }
                            buttonText={"Save"}
                            onClick={() => {
                                setImportGuardians(false)
                                importDataFromCsvGuardians()
                            }}

                        />
                    </div>

                </Modal>

                <Modal isVisible={pageAction === pageActions[5]} onClose={() => setPageAction("")}>
                    <div className='flex flex-col'>
                        <p>Edit Phone Number</p>
                        <div className="relative mt-2">
                                    <input type="text" name='phoneNumber' onChange={handleGuardianInputChange} value={guardian.phoneNumber} id="phoneNumber" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                    <label htmlFor="phoneNumber" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">PhoneNumber</label>
                                </div>
                                <CustomButton
                                buttonStyle={
                                    " mt-5 text-xs w-full text-white px-4 py-4 cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
                                }
                                buttonText={"Send"}
                                onClick={() => {
                                    editGuardian(guardian)
                                }}
                            />
                    </div>

                </Modal>

                <Modal isVisible={pageAction === pageActions[1]} onClose={() => setPageAction("")}>
                    <div className='flex flex-col overflow-auto'>
                        <p>{isNull(guardian.guardianId) ? 'Add a Guardian' : 'Edit'}</p>
                        <div className='grid grid-cols-2 gap-2 mt-3'>
                            <div>
                                <div className="relative mt-2">
                                    <input type="text" name='firstName' onChange={handleGuardianInputChange} value={guardian.firstName} id="firstName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                    <label htmlFor="firstName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Firstname</label>
                                </div>
                            </div>
                            <div>

                                <div className="relative mt-2">
                                    <input type="text" name='lastName' onChange={handleGuardianInputChange} value={guardian.lastName} id="lastName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                    <label htmlFor="lastName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Lastname</label>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-2 mt-3'>
                            <div>
                                <div className="relative mt-2">
                                    <input type="text" name='otherNames' onChange={handleGuardianInputChange} value={guardian.otherNames} id="otherNames" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                    <label htmlFor="otherNames" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Othernames</label>
                                </div>
                            </div>
                            <div>
                                <div className="relative mt-2">
                                    <input type="text" name='phoneNumber' onChange={handleGuardianInputChange} value={guardian.phoneNumber} id="phoneNumber" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                    <label htmlFor="phoneNumber" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">PhoneNumber</label>
                                </div>
                            </div>
                        </div>



                        <div className='text-xs'>
                            <p>Select Gender</p>
                            <CustomDropdownWithCancelIcon options={genderOptions}
                                onOptionSelect={(value) => {
                                    setSelectedGender(value)
                                    setGuardian(prevState => ({
                                        ...prevState,
                                        gender: value
                                    }));
                                    // //set selected Academic Block
                                    // const academicBlockSelected = pickSelectedObject(schoolAcademicBlocks, 'blockName', value)
                                    // setSelectedSchoolAcademicBlocksOption(value);
                                    // updateOrAddSearchStudentParams('academicBlockId', { key: 'academicBlockId', value: academicBlockSelected.academicBlockId })
                                    // //setSelectedSchoolAcademicBlocksOption();  
                                    // setSelectedPageDiv(pageDivIds[0]); //for search
                                }}
                                style={'w-full'}
                                isOptionSelected={true} //!isNull(selectedSchoolAcademicBlocksOption)
                            />
                        </div>



                        <CustomButton
                            buttonStyle={
                                "my-2 text-xs text-white px-4 py-4 cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
                            }
                            buttonText={"Save"}
                            onClick={() => {
                                //crudGuardian();
                                validateCrudGuardian()
                            }}
                        />

                    </div>
                </Modal>



                <Modal isVisible={isAddGuardian} onClose={() => setIsAddGuardian(false)}>
                    <div className='flex flex-col overflow-auto'>
                        <p>{isNull(guardian.guardianId) ? 'Add a Guardian' : 'Edit'}</p>
                        <div className='grid grid-cols-2 gap-2 mt-3'>
                            <div>
                                <div className="relative mt-2">
                                    <input type="text" name='firstName' onChange={handleGuardianInputChange} value={guardian.firstName} id="firstName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                    <label htmlFor="firstName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Firstname</label>
                                </div>
                            </div>
                            <div>

                                <div className="relative mt-2">
                                    <input type="text" name='lastName' onChange={handleGuardianInputChange} value={guardian.lastName} id="lastName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                    <label htmlFor="lastName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Lastname</label>
                                </div>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 gap-2 mt-3'>
                            <div>
                                <div className="relative mt-2">
                                    <input type="text" name='otherNames' onChange={handleGuardianInputChange} value={guardian.otherNames} id="otherNames" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                    <label htmlFor="otherNames" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Othernames</label>
                                </div>
                            </div>
                            <div>

                                <div className="relative mt-2">
                                    <input type="text" name='studentAdmissionNumber' onChange={handleGuardianInputChange} value={guardian.studentAdmissionNumber} id="studentAdmissionNumber" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                    <label htmlFor="studentAdmissionNumber" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Student Admission No</label>
                                </div>
                            </div>
                        </div>



                        <div className='text-xs'>
                            <p>Select Gender</p>
                            <CustomDropdownWithCancelIcon options={genderOptions}
                                onOptionSelect={(value) => {
                                    setSelectedGender(value)
                                    setGuardian(prevState => ({
                                        ...prevState,
                                        gender: value
                                    }));
                                    // //set selected Academic Block
                                    // const academicBlockSelected = pickSelectedObject(schoolAcademicBlocks, 'blockName', value)
                                    // setSelectedSchoolAcademicBlocksOption(value);
                                    // updateOrAddSearchStudentParams('academicBlockId', { key: 'academicBlockId', value: academicBlockSelected.academicBlockId })
                                    // //setSelectedSchoolAcademicBlocksOption();  
                                    // setSelectedPageDiv(pageDivIds[0]); //for search
                                }}
                                style={'w-full'}
                                isOptionSelected={true} //!isNull(selectedSchoolAcademicBlocksOption)
                            />
                        </div>

                        <div>
                            <div className="relative mt-2">
                                <input type="text" name='phoneNumber' onChange={handleGuardianInputChange} value={guardian.phoneNumber} id="phoneNumber" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                                <label htmlFor="phoneNumber" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">PhoneNumber</label>
                            </div>
                        </div>

                        <CustomButton
                            buttonStyle={
                                "my-2 text-xs text-white px-4 py-4 cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
                            }
                            buttonText={"Save"}
                            onClick={() => {
                                //crudGuardian();
                                validateCrudGuardian()
                            }}
                        />

                    </div>
                </Modal>
                <Modal isVisible={isSessionExpired} onClose={() => setIsSessionExpired(false)}>
                    <div className='flex flex-col p-4'>
                        <p className={`text-xs text-red-500`}>Session expired, please login  again to refresh your session</p>
                        <div className='grid grid-cols-1 md:grid-cols-2 mt-2 gap-1 md:gap-2'>
                            <div className="flex flex-col py-2 ">
                                <label className=" text-black text-xs">Phone number</label>
                                <input
                                    value={loggedInUser.mobileNumber}
                                    id="phone"
                                    className={` border-red-500 text-xs rounded-lg bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none`}
                                    type={"phone"}
                                ></input>

                            </div>
                            <div className="flex flex-col py-2">
                                <label className="text-black text-xs">Password</label>
                                <input
                                    value={userPassword}
                                    onChange={(e) => setUserPassword(e.target.value)}
                                    id="password"
                                    className="rounded-lg text-xs bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none"
                                    type={"password"}
                                ></input>
                            </div>
                        </div>
                        <CustomButton
                            // bg-gradient-to-b from-[#FEE6F7] to-[#f8aee2]
                            buttonStyle={
                                "w-full my-5 py-4 bg-blue-400 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
                            }
                            buttonText={"Login"}
                            onClick={() => {
                                processLoginAndAccessToken()
                            }}
                        />

                    </div>
                </Modal>
                <ConfirmationDialog
                    isOpen={isConfirmationOpen}
                    onClose={handleCloseConfirmation}
                    onConfirm={handleConfirmAction}
                    message={confirmationMessage}
                />
                <LoadingDialog message={'Processing'} isOpen={isLoading} />
                <ErrorDialog message={errorMessage} isOpen={showError} onClose={() => setShowError(false)} />
                <SuccessfulDialog message={successMessage} isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} />

                <div className={`flex duration-300 justify-end space-x-2  m-2 flex-col md:flex-row`}>
                    <div className='text-xs'>
                        <p>Select Class</p>
                        {/* <CustomDropdownWithCancelIcon
                            options={academicBlockClassesOptions}
                            onOptionSelect={(selectedValue)=>{
                                setSelectedBlockClass({'key':selectedValue})
                                console.log(selectedValue)
                            }}
                            style={'w-full'} 
                            isOptionSelected={isNull(selectedBlockClass)}
                        /> */}
                        <CustomDropdownWithCancelIcon
                            options={schoolBlockClassesDropdownOptions}
                            style={'w-full'}
                            onOptionSelect={(value) => {
                                //set selectedClass
                                const blockClass = pickSelectedObject(schoolClasses, 'className', value);
                                console.log('selectedBlockClass', blockClass)
                                if (isNull(blockClass.classId)) {
                                    setSelectedBlockClass({})
                                    console.log('selectedBlockClass is nun')
                                    setSearchGuardianMappingParams(removeObjectFromArray(searchGuardianMappingParams, 'classId'))
                                } else {
                                    console.log('selectedBlockClass is not nun')
                                    updateOrAddSearchStudentGuardianMappingParams('classId', { key: 'classId', value: blockClass.classId })
                                    setSelectedBlockClass(blockClass)
                                }
                                setPaginationPageNo(1);

                            }}
                            isOptionSelected={!isNull(selectedBlockClass)}
                        />
                    </div>

                    <div className='text-xs'>
                        <p>Guardian Mobile Number</p>
                        <div className='flex items-center rounded-md border my-2 border-gray-700 bg-light-white px-4 py-3.5'>
                            <input
                                type={"search"}
                                placeholder="Search by mobile no."
                                className={`bg-transparent text-sm w-full text-black focus:outline-none`}
                                onChange={(e) => {
                                    setPaginationPageNo(1);
                                    setPhoneNumber(e.target.value)
                                }}
                                value={phoneNumber}
                            ></input>
                        </div>
                    </div>

                    <div className='text-xs'>
                        <p>Student admission number</p>
                        <div className='flex items-center rounded-md border my-2 border-gray-700 bg-light-white px-4 py-3.5'>
                            <input
                                type={"search"}
                                placeholder="Search by admn no."
                                className={`bg-transparent text-sm w-full text-black focus:outline-none`}
                                onChange={(e) => {
                                    setPaginationPageNo(1);
                                    setSchoolAdmissionNumber(e.target.value)
                                }}
                                value={schoolAdmissionNumber}
                            ></input>
                        </div>
                    </div>

                    <div className='text-xs flex flex-col justify-between'>
                        <p></p>
                        <CustomButton
                            buttonStyle={
                                "my-2 text-xs text-white px-4 k py-4 cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
                            }
                            buttonText={"Search"}
                            onClick={() => {
                                const urlString = getSearchGuardianStudentMappingString() + "&page=" + paginationPageNo + "&schoolId=" + schoolData.schoolId + "&size=" + paginationPageSize;
                                searchStudents(urlString)
                                // setDisplayImportStudentsModal(true)
                                // setFinalCsvData([])
                            }}
                        />
                    </div>

                    <div className='text-xs flex flex-col justify-between'>
                        <p></p>
                        <CustomButton
                            buttonStyle={
                                "my-2 text-xs text-white px-4 k py-4 cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
                            }
                            buttonText={"Import from csv"}
                            onClick={() => {
                                setImportJsonData([])
                                setImportGuardians(true)
                                // setDisplayImportStudentsModal(true)
                                // setFinalCsvData([])
                            }}
                        />
                    </div>
                    <div className='text-xs flex flex-col justify-between'>
                        <p></p>
                        <CustomButton
                            buttonStyle={
                                "my-2 text-xs text-white px-4 k py-4 cursor-pointer bg-blue-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
                            }
                            buttonText={"Add Guardian"}
                            onClick={() => {
                                //setGuardian({}) 
                                clearGuardian()
                                setPageAction(pageActions[0])
                                setIsAddGuardian(true)
                                // setFinalCsvData([])
                            }}
                        />
                    </div>
                    <div className={`text-xs flex flex-col justify-between`}>
                        <p></p>
                        <div
                            onClick={() => {


                                if (selectedGuardians.length === 0) {
                                    setShowError(true)
                                    setErrorMessage("Select the guardians you want to send message")
                                } else {
                                    setPageAction(pageActions[4])
                                    //updateSelectedGuardians()
                                }

                                // console.log("selectedBlockClass >>>> ", selectedBlockClass.classId)
                                // console.log('isNull(selectedBlockClass)', isNull(selectedBlockClass.classId))
                                // if (isNull(selectedBlockClass.classId)) {
                                // setShowError(true)
                                // setErrorMessage("Select class first")
                                // } else {
                                //     setPageAction(pageActions[4])
                                // }
                            }}
                            className={` rounded-lg m-2 py-3.5 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full md:w-52 bg-green-600 text-white space-x-1`}>
                            <BsFillChatTextFill />
                            <p>Send Sms</p>
                        </div>
                    </div>

                </div>


                <div class="w-full overflow-auto rounded-lg shadow hidden md:block">
                    <table class="w-full mb-10">
                        <thead class="bg-gray-50 border-b-2 border-gray-200 w-full">
                            <tr>
                                {/* <th class="p-3 text-sm font-semibold tracking-wide text-left">No.</th> */}
                                <th className="p-3 text-sm font-semibold tracking-wide text-left">
                                    <input type='checkbox' onChange={(e) => toggleAllGuardianMappings(e.target.checked)} />
                                    <span className='ml-2'>{selectText}</span>
                                </th>
                                <th class="p-3 text-sm font-semibold tracking-wide text-left">Name</th>
                                <th class="p-3 text-sm font-semibold tracking-wide text-left">Gender</th>
                                <th class="p-3 text-sm font-semibold tracking-wide text-left">Student</th>
                                <th class="p-3 text-sm font-semibold tracking-wide text-left">Admn No.</th>
                                <th class="p-3 text-sm font-semibold tracking-wide text-left">Mobile No.</th>
                                <th class="p-3 text-sm font-semibold tracking-wide text-left">Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                            {

                                // ({ ...item, isChecked: isSelected ? 'checked' : 'notChecked' }));

                                guardianMappings.map((guardianMapping, index) => (
                                    <tr class="bg-white">
                                        <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                                            {guardianMapping.isChecked === 'checked' ? (
                                                <div className="flex items-center space-x-2">
                                                    <input type="checkbox" id="myCheckbox" value={guardianMapping.isChecked}
                                                        checked={guardianMapping.isChecked == 'checked' ? true : false}
                                                        onChange={e => toggleGuardianMapping(guardianMapping.mappingId, e.target.checked)}
                                                        className="form-checkbox text-blue-500 h-5 w-5" defaultChecked />
                                                    <label htmlFor="myCheckbox" className="text-gray-700"></label>
                                                </div>
                                            ) : (
                                                <div className="flex items-center space-x-2">
                                                    <input type="checkbox" id="myCheckbox" value={guardianMapping.isChecked}
                                                        checked={guardianMapping.isChecked == 'checked' ? true : false}
                                                        onChange={e => toggleGuardianMapping(guardianMapping.mappingId, e.target.checked)}
                                                        className="form-checkbox text-blue-500 h-5 w-5" />
                                                    <label htmlFor="myCheckbox" className="text-gray-700"></label>
                                                </div>
                                            )}
                                        </td>
                                        <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                                            <span
                                                class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-yellow-200 rounded-lg bg-opacity-50">{guardianMapping.guardian.firstName} {guardianMapping.guardian.lastName}</span>
                                        </td>
                                        <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                                            {guardianMapping.guardian.gender}
                                        </td>
                                        <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                                            {guardianMapping.student.firstName} {guardianMapping.student.lastName}
                                        </td>
                                        <td class="p-3 text-sm text-gray-700 whitespace-nowrap">
                                            {guardianMapping.student.schoolAdmissionNumber}
                                        </td>
                                        <td class="p-3 text-sm text-gray-700 whitespace-nowrap">

                                        <div className='flex flex-row space-x-1 text-xs ml-3'>
                                            <p> {guardianMapping.guardian.phoneNumber}</p>
                                            <p onClick={() => {
                                                setPageAction(pageActions[5])
                                                setGuardianMapping(guardianMapping)
                                                setGuardian(guardianMapping.guardian)
                                            }} className=' cursor-pointer'><BsFillPencilFill /></p>
                                        </div>
                                        </td>
                                        
                                        <td class="p-3 flex space-x-2">
                                            {/* <button onClick={() => {

                                                // const pageActions = ['addGuardian', 'editGuardian', 'deleteGuardian'];
                                                setGuardianMapping(guardianMapping)
                                                setPageAction(pageActions[1])
                                                setGuardian(guardianMapping.guardian)

                                            }} class="border p-2 rounded-lg text-sm">Edit</button> */}
                                            <button onClick={() => {

                                                //const pageActions = ['addGuardian', 'editGuardian', 'deleteGuardian', 'deleteAssociation'];
                                                setPageAction(pageActions[3])
                                                setGuardianMapping(guardianMapping)
                                                setGuardian(guardianMapping.guardian)
                                                setConfirmationMessage("Confirm you want to remove  " + guardianMapping.guardian.firstName + " as " + guardianMapping.student.firstName + " guardian?")
                                                setIsConfirmationOpen(true)

                                            }}
                                                class="border p-2 rounded-lg text-sm">Delete Association</button>

                                            <button onClick={() => {

                                                // const pageActions = ['addGuardian', 'editGuardian', 'deleteGuardian'];
                                                setPageAction(pageActions[2])
                                                setGuardianMapping(guardianMapping)
                                                setGuardian(guardianMapping.guardian)
                                                setConfirmationMessage("Confirm you want to delete " + guardianMapping.guardian.firstName + "?")
                                                setIsConfirmationOpen(true)

                                            }}
                                                class="border p-2 rounded-lg text-sm">Delete Guardian</button>
                                        </td>
                                    </tr>
                                ))}





                        </tbody>
                    </table>
                </div>

                <div class="grid grid-cols-1 sm:grid-cols-2 gap-4  md:hidden overflow-y-auto">

                    {
                        guardianMappings.map((guardianMapping, index) => (

                            <div class="bg-white space-y-3 p-4 rounded-lg shadow">
                                <div class="flex items-center text-gray-500 space-x-2 text-sm">
                                    <p class="text-blue-500 font-bold hover:underline">{guardianMapping.guardian.firstName} {guardianMapping.guardian.lastName}</p>
                                    <p class="flex flex-row space-x-1 p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">
                                        {guardianMapping.guardian.phoneNumber}
                                        <p onClick={() => {
                                                setPageAction(pageActions[5])
                                                setGuardianMapping(guardianMapping)
                                                setGuardian(guardianMapping.guardian)
                                            }} className=' cursor-pointer'><BsFillPencilFill /></p>
                                        </p>

                                </div>
                                <div class="flex items-center text-gray-500 space-x-2 text-sm">
                                    <p class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-purple-200 rounded-lg bg-opacity-50">{guardianMapping.student.firstName} {guardianMapping.student.lastName}</p>
                                    <p class="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-blue-200 rounded-lg bg-opacity-50">{guardianMapping.student.schoolAdmissionNumber}</p>

                                </div>
                                <div class="flex justify-end items-center space-x-2">
                                    {/* <button onClick={() => {
                                        
                                        setGuardianMapping(guardianMapping)
                                        setPageAction(pageActions[1])
                                        setGuardian(guardianMapping.guardian)
                                    }
                                    } class="border p-2 rounded-lg text-sm">Edit</button> */}
                                    <button onClick={() => {
                                        setPageAction(pageActions[3])
                                        setGuardianMapping(guardianMapping)
                                        setGuardian(guardianMapping.guardian)
                                        setConfirmationMessage("Confirm you want to remove  " + guardianMapping.guardian.firstName + " as " + guardianMapping.student.firstName + " guardian?")
                                        setIsConfirmationOpen(true)
                                    }} class="border p-2 rounded-lg text-sm">Delete Association</button>

                                    <button onClick={() => {
                                        setPageAction(pageActions[2])
                                        setGuardianMapping(guardianMapping)
                                        setGuardian(guardianMapping.guardian)
                                        setConfirmationMessage("Confirm you want to delete " + guardianMapping.guardian.firstName + "?")
                                        setIsConfirmationOpen(true)
                                    }} class="border p-2 rounded-lg text-sm">Delete Guardian</button>
                                </div>
                            </div>

                        ))}



                </div>

                <div class={`bg-white p-4 border border-gray-300 flex flex-col items-center ${paginationTotalPages < 2 && 'hidden'}`}>
                    <div class="flex justify-center items-center ">
                        <a onClick={() => {
                            handlePreviousPageV2()
                        }} href="#" class="bg-blue-500 text-white py-2 px-4 rounded-l-lg mr-2">Back</a>

                        <div class="flex text-sm">
                            <p>Page {paginationPageNo} of {paginationTotalPages}</p>
                        </div>
                        <a onClick={() => {
                            handleNextPageV2()
                            //  const pageCrudActions = ['AddStudent', 'EditStudent', 'DeleteStudent', 'BulkUploadStudents' , 'fetchStudents', 'searchStudents'];
                            // setPageAction(pageCrudActions[])
                        }} href="#" class="bg-blue-500 text-white py-2 px-4 rounded-r-lg ml-2">Next</a>
                    </div>

                    {/* <button 
                        onClick={()=>{
                            console.log("see me")
                            //setPaginationPageNo(0);
                            setPaginationPageNo(1)
                            setSearchGuardianMappingParams([{ key: 'classId', value: selectedBlockClass.classId }])
                            setPaginationPageSize(1000)
                            console.log("See")
                            //setPaginationPageNo(1)
                        }}
                        >
                            View all Guardians
                        </button> */}

                    <a onClick={() => {
                        if (isNull(selectedBlockClass.classId)) {
                            setShowError(true)
                            setErrorMessage("Select class first")
                        } else {

                            setSearchGuardianMappingParams([{ key: 'classId', value: selectedBlockClass.classId }])
                            const urlString = getSearchGuardianStudentMappingString() + "&page=1&schoolId=" + schoolData.schoolId + "&size=1000";
                            searchStudents(urlString)


                            //console.log("Imma here ...")
                        }
                    }} href="#" class=" text-black  py-2 px-4  ml-2 text-xs underline">View All</a>
                </div>


            </div>
        </>
    )
}

export default Guardians
import React, { useState } from 'react';

const FloatingLabelInput = ({ label, placeholder }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div className="mb-4 relative">
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
        className="w-full py-2 px-3 border rounded border-gray-300 focus:outline-none focus:border-blue-500"
      />
      <label
        className={`absolute left-3 transition-all ${
          inputValue ? '-top-2 text-sm text-gray-500' : 'top-1 text-gray-600'
        }`}
      >
        {label}
      </label>
    </div>
  );
};

export default FloatingLabelInput;

import React from "react";
import { useState } from "react";
import { BsArrowLeftShort, BsSearch } from "react-icons/bs";
import { RiDashboardFill } from "react-icons/ri";
import { SiPowerpages, SiVlcmediaplayer } from "react-icons/si";
import { MdOutlineAnalytics } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService"
import  { useEffect } from 'react';
import { BsBan,BsBuildings,BsBook,BsPen,BsReception3, BsSpeedometer,BsPeople,BsMortarboard, BsSuperscript,BsCalculator,BsFillPersonLinesFill,BsFillChatTextFill } from "react-icons/bs";
import { ADMIN__ROLE, LOGGED_IN_USER_TOKEN_DETAILS } from "../configs/constants";
import { getFromLocalStorage } from "../services/localStorageService";
import { isNull } from "../services/Utilities";


// import Classes from "../pages/Classes";
// import { NavLink } from "react-router-dom";

export const Navigation = ( {isLoggedIn , logOut} ) => {
  
  console.log('Navigation rendered')
  console.log('isLoggedIn', isLoggedIn)
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState(getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS));
  const [menus, setMenus] = useState([])
  const adminMenus = [
    {title: "Dashboard", isActive: false, link: "/", icon: <RiDashboardFill />},
    {title: "Classes", isActive: false, link: "/classes", icon: <BsBuildings />},
    {title: "Teachers", isActive: false, link: "/teachers", icon: <BsPen />},
    {title: "Students", isActive: false, link: "/students", icon: <BsMortarboard />},
    {title: "Guardians", isActive: false, link: "/guardians", icon: <BsPeople />},
    {title: "Subjects", isActive: false, link: "/subjects", icon: <BsBook />},
    {title: "Subject Grading", isActive: false, link: "/subject-grading", icon: <BsReception3 />},
    {title: "Subject Students", isActive: false, link: "/subjectmappings", icon: <BsFillPersonLinesFill />},
    {title: "Overall Grading", isActive: false, link: "/overall-grading", icon: <BsSpeedometer />},
    {title: "Exams", isActive: false, link: "/exams", icon: <BsMortarboard />},
    {title: "Scores", isActive: false, link: "/scores", icon: <BsSuperscript />},
    {title: "Exam Reports", isActive: false, link: "/reports", icon: <BsCalculator />},
    {title: "Sms", isActive: false, link: "/bulksms", icon: <BsFillChatTextFill />},
    {title: "LogOut", isActive: false, link: "/logOut", icon: <BsBan />},
  ];

  const teacherMenus = [
    {title: "Dashboard", isActive: false, link: "/", icon: <RiDashboardFill />},
    {title: "Classes", isActive: false, link: "/classes", icon: <BsBuildings />},
    {title: "Students", isActive: false, link: "/students", icon: <BsMortarboard />},
    {title: "Subjects", isActive: false, link: "/subjects", icon: <BsBook />},
    {title: "Subject Students", isActive: false, link: "/subjectmappings", icon: <BsFillPersonLinesFill />},
    {title: "Exams", isActive: false, link: "/exams", icon: <BsMortarboard />},
    {title: "Scores", isActive: false, link: "/scores", icon: <BsSuperscript />},
    {title: "Exam Reports", isActive: false, link: "/reports", icon: <BsCalculator />},
    // {title: "Sms", isActive: false, link: "/bulksms", icon: <BsFillChatTextFill />},
    {title: "LogOut", isActive: false, link: "/logOut", icon: <BsBan />},
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

 


  const userDetails = {};
  if(isLoggedIn){
    const userDetails = getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS);
    console.log('logged in userDetails:', userDetails)
    console.log('logged in userDetails role:', userDetails.primaryRole)
  }



  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };


  }, []); // Empty dependency array ensures the effect runs only once



 
  useEffect(() => {
    // Your code here to run when editSchoolTermDetails changes
    console.log('isMobile changed:', isMobile);
    if(isMobile){
      setOpen(false)
    }
   
  }, [isMobile]);

  useEffect(() => {
    if (isLoggedIn) {
      const userDetails = getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS);
      if (userDetails.primaryRole === ADMIN__ROLE) {
        setMenus(adminMenus);
      } else {
        setMenus(teacherMenus);
      }
    }
  }, [isLoggedIn]);

  // // State to manage the menus
  // const [menus, setMenus] = useState([
  //   {title: "Dashboard", isActive: false, link: "/", icon: <RiDashboardFill />},
  //   {title: "Classes", isActive: false, link: "/classes", icon: <BsBuildings />},
  //   {title: "Teachers", isActive: false, link: "/teachers", icon: <BsPen />},
  //   {title: "Students", isActive: false, link: "/students", icon: <BsMortarboard />},
  //   {title: "Guardians", isActive: false, link: "/guardians", icon: <BsPeople />},
  //   {title: "Subjects", isActive: false, link: "/subjects", icon: <BsBook />},
  //   {title: "Subject Grading", isActive: false, link: "/subject-grading", icon: <BsReception3 />},
  //   {title: "Subject Students", isActive: false, link: "/subjectmappings", icon: <BsFillPersonLinesFill />},
  //   {title: "Overall Grading", isActive: false, link: "/overall-grading", icon: <BsSpeedometer />},
  //   {title: "Exams", isActive: false, link: "/exams", icon: <BsMortarboard />},
  //   {title: "Scores", isActive: false, link: "/scores", icon: <BsSuperscript />},
  //   {title: "Exam Reports", isActive: false, link: "/reports", icon: <BsCalculator />},
  //   {title: "Sms", isActive: false, link: "/bulksms", icon: <BsFillChatTextFill />},
  //   {title: "LogOut", isActive: false, link: "/logOut", icon: <BsBan />},
  // ]);



  const handleClick = (link, index) => {
    console.log(link)
    if(link == '/logOut'){
      logOut();
      return
    }
    // Update the active state of the clicked menu item
    const updatedMenus = menus.map((menu, i) => ({
      ...menu,
      isActive: i === index,
    }));

    // Set the updatedMenus array to the new state
    setMenus(updatedMenus);

    navigate(link);
  };

  return (
   
    <div
      className={`bg-blue-800  fixed h-screen  ${!isLoggedIn && 'hidden'}  p-5 pt-8 ${open ? "w-72" : "w-20"
        } duration-300 relative`}
    >
      <BsArrowLeftShort
        className={`bg-white text-dark-purple text-3xl  duration-700 rounded-full absolute -right-3 top-9 border border-dark-purple cursor-pointer ${!open && "rotate-180"
          }`}
        onClick={() => setOpen(!open)}
      />

      {/* logo start*/}
      <div className="items-center inline-flex">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={`w-6 h-6 bg-amber-300 text-4xl rounded cursor-pointer block float-left mr-2 duration-500 ${open && "rotate-[360deg]"
            }`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
          />
        </svg>
        <h1
          className={`text-white origin-left font-medium text-2xl duration-300 ${!open && "scale-0"
            }`}
        >
          DigiSchool
        </h1>
      </div>
      {/* logo end*/}

      {/* search field start*/}
      <div
        className={`flex items-center rounded-md bg-light-white mt-6 px-4 py-2 ${!open ? "px-2.5" : "px-4"
          }`}
      >
        <BsSearch
          className={`text-white text-lg block float-left cursor-pointer ${open && "mr-2"
            } `}
        />
        <input
          type={"search"}
          placeholder="Search"
          className={`bg-transparent text-base w-full text-white focus:outline-none ${!open && "hidden"
            }`}
        ></input>
      </div>
      {/* search field end */}

      {/* Other menu items */}

     

<ul className="pt-2 fixed overflow-y-auto h-[calc(100vh-120px)]">
        { 
        
        menus.map((menu, index) => (
          <li
            key={index}
            className={`text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2 ${menu.isActive ? 'bg-light-white text-white' : ''
              }`}
            onClick={() => handleClick(menu.link, index)}
          >
            <span className="text-2xl block float-left">{menu.icon}</span>
            <span className={`text-sm origin-left font-medium flex-1 duration-300 ${!open && "scale-0 w-0"}`}>
              {menu.title}
            </span>
          </li>
        ))
      }
      </ul>



    </div>
  );
};

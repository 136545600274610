import React, { useEffect, useState } from 'react'
import CustomButton from "../../components/CustomButton";
import CustomDropdown from "../../components/CustomDropdown"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import { FaPlus } from "react-icons/fa6";
import Modal from '../../components/Modal';
import { CiSearch } from "react-icons/ci";
import { IoSearchSharp } from "react-icons/io5";
import { BsArrowRepeat, BsCheck, BsPlus } from "react-icons/bs";
import httpService from '../../services/HttpService';
import { getFromLocalStorage, saveToLocalStorage } from '../../services/localStorageService';
import { ACTIVE_STATUS, ADMIN__ROLE, DELETED_STATUS, FAILED_STATUS, FILES_DOWNLOAD_LINK, FORBIDEN_MESSAGE, INACTIVE_STATUS, INVALID_API_RESPONSE, LOGGED_IN_USER_TOKEN_DETAILS, ONLY_PERFORMED_BY_ADMIN_MESSAGE, PENDING_STATUS, PROCESSED_STATUS, SCHOOLDATA_LOCALSTORAGE_KEY, SCHOOL_ID_LOCALSTORAGE_KEY } from '../../configs/constants';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import SuccessfulDialog from '../../components/SuccessfulDialog';
import { extractStringsFromArray, isNull, pickSelectedObject } from '../../services/Utilities';
import { useActionData } from 'react-router-dom';
import { IoHourglassOutline } from "react-icons/io5";
import { BsXCircleFill } from "react-icons/bs";
import { BsFillCheckCircleFill } from "react-icons/bs";





function Reports() {
  //generateNewReport

  const [openSearchOptions, setOpenSearchOptions] = useState(false);
  const [openActionButtons, setOpenActionButtons] = useState(false);
  const [generateNewReport, setGenerateNewReport] = useState(false)
  const [capturingNewReportDetails, setCapturingNewReportDetails] = useState(false)
  const [addExamsToCompute, setAddExamsToCompute] = useState(false);


  // const [examsSelected , setExamsSelected] = useState([])

  const classLevels = ['Pre Primary', 'Lower Primary', 'Upper primary', 'Junior Secondary', 'Senior Secondary','8-4-4'];
  const blockClasses = ['Play Group', 'PP1', 'PP2'];
  const examsIncluded = ['MidTerm, Term 1 2022', 'End Term, Term 1 2022'];
  const blockClassExams = ['Opener', 'Midterm', 'Ednterm'];
  const [searchResultsParams, setSearchResultsParams] = useState([{ key: 'schoolId', value: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) },
  { key: 'size', value: "2000" }, { key: 'page', value: '0' }, { key: 'classId', value: 0 },
  { key: 'term', value: 0 }, { key: 'year', value: 0 }
]);
  const [resultsDetailsComputedArray, setResultsDetailsComputedArray] = useState([])
  const getSearchString = (item, searchParams) => {
    const searchString = searchParams.map((param) => `${param.key}=${param.value}`).join('&');
    return `${item}?${searchString}`; //i.e item="search?"
  };

  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPageAction, setSelectedPageAction] = useState('')
  const [resultsTitle, setResultsTitle] = useState('')
  const schoolAcademicTerms = ['1', '2', '3'];
  const [selectedTerm, setSelectedTerm] = useState('')
  const academicYears = ['2024', '2025', '2026', '2027'];
  const [selectedYear, setSelectedYear] = useState('')
  const computationOptions = ['To 100%', 'Exam Total Score'];
  const [selectedComputationOption, setSelectedComputationOption] = useState('')
  const [computeToHundred, setComputeToHundred] = useState(false);
  const [totalSubjects, settotalSubjects] = useState(0);
  const [exams, setExams] = useState([])
  const [examsDropDownOptions, setExamsDropDownOptions] = useState([])
  const [selectedExam, setSelectedExam] = useState({})
  const [schoolBlockClasses, setSchoolBlockClasses] = useState([]); //getFromLocalStorage(BLOCK_CLASSES_LOCALSTORAGE_KEY)
  const [schoolBlockClassesDropdownOptions, setSchoolBlockClassesDropdownOptions] = useState([]);
  const [selectedSchoolBlockClass, setSelectedSchoolBlockClass] = useState({})
  const [examsIncludedInResults, setExamsIncludedInResults] = useState([])
  const [examToPickTerm, setExamToPickTerm] = useState(0)
  const [examToPickYear, setExamToPickYear] = useState(0)
  const [viewAllAnalysisOptions, setViewAllAnalysisOptions] = useState(false);
  const [selectedReport, setSelectedReport] = useState({})
  const [recipientEmail, setRecipientEmail] = useState('')
  const [schoolData, setSchoolData] = useState(getFromLocalStorage(SCHOOLDATA_LOCALSTORAGE_KEY))
  const [generatedReports, setGeneratedReports] = useState([])

  const [loggedInUser, setLoggedInUser] = useState(getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS));
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [userPassword, setUserPassword] = useState("");
  const pageActions = ['sendResultsViaSms' , 'deleteReport'];
  const [pageAction, setPageAction] = useState("")

  const processLoginAndAccessToken = async () => {

    if (userPassword.length == 0) {
      setErrorMessage('Please enter your password')
      setShowError(true)
      return;
    }


    try {
      const payLoad = {
        "username": loggedInUser.mobileNumber,
        "password": userPassword
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken //this token isnt really needed
      };
      const response = await httpService.request('POST', 'auth/schoolStaffLogin', payLoad, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          saveToLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS, response.body)
          setLoggedInUser(response.body)
          setIsSessionExpired(false)

          setSuccessMessage("Successfully logged in")
          setShowSuccessMessage(true)

          //action after refreshing token
          fetchBlockClasses()
          searchResults()

        }

        else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      console.error('Exception:' + error)
      // setErrorMessage(INVALID_API_RESPONSE);
      // setShowError(true)

      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }


    }
  };




  const payl =
  {
    "resultsId": 0,
    "resultsTitle": resultsTitle,
    "classId": selectedSchoolBlockClass.classId,
    "academicBlockId" : selectedSchoolBlockClass.academicBlockId,
    "schoolId": getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY),
    "term": selectedTerm,
    "year": selectedYear,
    "addedBy": 0,
    "totalRankingSubjects": totalSubjects,
    "computeToHundred": computeToHundred,
    "subjectResultsComputed": false,
    "subjectRankingComputed": false,
    "overallRankingComputed": false,
    "status": ACTIVE_STATUS,
    "rankingOption": "Points",
    "statusDescription": "Pending computation",
    "examsIncludedInResults": examsIncludedInResults
  };

  useEffect(() => {
    fetchBlockClasses()
    searchResults()
    //console.log('schoolDetails {{{]}}}} ', schoolDetails)
    setRecipientEmail(schoolData.email)
  }, []);
  useEffect(() => {
    setSchoolBlockClassesDropdownOptions(extractStringsFromArray(schoolBlockClasses, "className"))
  }, [schoolBlockClasses]);
  useEffect(() => {
    console.log('selectedSchoolBlockClass', selectedSchoolBlockClass)

    if (selectedSchoolBlockClass.academicBlockId !== undefined) {
      fetchBlockExams()
    }
  }, [selectedSchoolBlockClass]);


  useEffect(() => {
    console.log(examsIncludedInResults)
  }, [examsIncludedInResults]);

  useEffect(() => {
    console.log('exams', exams)
    setExamsDropDownOptions( extractStringsFromArray(exams, 'examName'))
  }, [exams]);

  //

  const crudResultsCOmputed = async (payl) => {
  
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'exam-analysis/crudResultsComputed', payl, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {

          searchResults()

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }


  const submitResultsToCompute = async () => {
    setGenerateNewReport(false)
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'exam-analysis/submitResultsComputed', payl, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {

          searchResults()

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }






  // Function to add a new object to the array
  const addObject = (newObject) => {
    // Create a copy of the array to avoid mutating the state directly
    const newArray = [...examsIncludedInResults];
    newArray.push(newObject);
    // Update the state with the new array
    setExamsIncludedInResults(newArray);
  };

  // Function to remove an object from the array by its index
  const removeObjectAtIndex = (indexToRemove) => {
    // Create a copy of the array to avoid mutating the state directly
    const newArray = [...examsIncludedInResults];
    // Remove the object at the specified index
    newArray.splice(indexToRemove, 1);
    // Update the state with the new array
    setExamsIncludedInResults(newArray);
  };


  // const addObject = (initialArray , newObject) => {
  //   console.log('initialArray',initialArray)
  //   // Create a copy of the array to avoid mutating the state directly
  //   const newData = [...initialArray];

  //   // Add a new object to the array
  //   newData.push(newObject);
  //   console.log('newData',newData)

  //   // Update the state with the new array
  //   return newData;
  // };


  // const removeObjectAtIndex = (indexToRemove , initialArray) => {
  //   // Create a copy of the array to avoid mutating the state directly
  //   const newData = [...initialArray];

  //   // Remove the object at the specified index
  //   newData.splice(indexToRemove, 1);

  //   // Update the state with the new array
  //   return newData;
  // };


  function updateExamIncludedPercentage(exam, percenntage) {
    setExamsIncludedInResults(prevData => {
      return prevData.map(examToEdit => {
        if (examToEdit === exam) {
          return {
            ...examToEdit,
            coveragePercentage: percenntage
          };
        }
        return examToEdit;
      });
    });

  }

  const searchResults = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', getSearchString('exam-analysis/searchResults', searchResultsParams), null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          //setClassSubjects(response.body.content);
          //arr.filter(obj => obj.status !== statusToRemove);
          setResultsDetailsComputedArray(response.body.data.filter(obj => obj.status !== DELETED_STATUS))
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }


  const fetchBlockClasses = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/getSchoolBlockClasses/' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY), null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSchoolBlockClasses(response.body);

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }


  const fetchResultDocuments = async (resultsId) => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'exam-analysis/getResultsAllGeneratedFiles/' + resultsId, null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setGeneratedReports(response.body)
          setViewAllAnalysisOptions(true)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }

  //regenerateAnalysis

  const regenerateAnalysis = async (results) => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'exam-analysis/regenerateAnalysis/' + results.resultsId, null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {

          searchResults()
          setSuccessMessage("Successfull. Please hold as we process your request , you will be notified at " + schoolData.email);
          setShowSuccessMessage(true);

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }

  function removeDuplicates(arr) {
    // Use a Set to automatically remove duplicates
    const uniqueSet = new Set(arr);
  
    // Convert the Set back to an array
    const uniqueArray = [...uniqueSet];
  
    return uniqueArray;
  }


  const fetchBlockExams = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/getSchoolAcademicBlockExams/' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + '/' + selectedSchoolBlockClass.academicBlockId, null, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          // setExams(response.body);
          setExams(response.body.filter((exam) => exam.classId === selectedSchoolBlockClass.classId));
          // const examsFoSelectedClass = response.body.filter((exam) => exam.classId === selectedSchoolBlockClass.classId);
          // setExamsDropDownOptions( extractStringsFromArray(examsFoSelectedClass, 'examName'))
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }



  const sendResultsViaSms = async (results) => {
    setPageAction('')
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'exam-analysis/requestSendResultsViaSms', selectedReport, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {

          setSuccessMessage(response.header.customerMessage);
          setShowSuccessMessage(true);

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  }





  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmAction = () => {
    if (pageAction === pageActions[0]) {
      sendResultsViaSms()
    }
    if (pageAction === pageActions[1]) {
      selectedReport.status= DELETED_STATUS;
      crudResultsCOmputed(selectedReport)
    }
    setIsConfirmationOpen(false);
  };






  useEffect(() => {
    // Log the updated state whenever generateNewReport changes
    if (generateNewReport) {
      setCapturingNewReportDetails(true)
    }
  }, [generateNewReport]);

  const saveReportDetails = () => {

    //verify 1.results title , 2.selectedSchoolBlockClass 3.selectedTerm 4.selectedYear 5.selectedComputationOption 6.totalSubjects
    if (isNull(resultsTitle)) { setGenerateNewReport(false); setShowError(true); setErrorMessage("Ensure Results title is set"); }
    else if (isNull(selectedSchoolBlockClass.classId)) { setGenerateNewReport(false); setShowError(true); setErrorMessage("Ensure you have selected class"); }
    else if (isNull(selectedTerm)) { setGenerateNewReport(false); setShowError(true); setErrorMessage("You haven't selected term"); }
    else if (isNull(selectedYear)) { setGenerateNewReport(false); setShowError(true); setErrorMessage("You haven't selected year"); }
    else if (isNull(selectedComputationOption)) { setGenerateNewReport(false); setShowError(true); setErrorMessage("You haven't computation option"); }
    else if (isNull(totalSubjects)) { setGenerateNewReport(false); setShowError(true); setErrorMessage("You haven't set the total subjects"); }
    else {
      setCapturingNewReportDetails(false)
    }

  }

  const openNewTab = (url) => {
    window.open(url, '_blank');
  }



  return (
    <div className='p-5 h-screen bg-gray-100 w-full overflow-y-auto'>
      <Modal isVisible={isSessionExpired} onClose={() => setIsSessionExpired(false)}>
        <div className='flex flex-col p-4'>
          <p className={`text-xs text-red-500`}>Session expired, please login  again to refresh your session</p>
          <div className='grid grid-cols-1 md:grid-cols-2 mt-2 gap-1 md:gap-2'>
            <div className="flex flex-col py-2 ">
              <label className=" text-black text-xs">Phone number</label>
              <input
                value={loggedInUser.mobileNumber}
                id="phone"
                className={` border-red-500 text-xs rounded-lg bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none`}
                type={"phone"}
              ></input>

            </div>
            <div className="flex flex-col py-2">
              <label className="text-black text-xs">Password</label>
              <input
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                id="password"
                className="rounded-lg text-xs bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none"
                type={"password"}
              ></input>
            </div>
          </div>
          <CustomButton
            // bg-gradient-to-b from-[#FEE6F7] to-[#f8aee2]
            buttonStyle={
              "w-full my-5 py-4 bg-blue-400 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
            }
            buttonText={"Login"}
            onClick={() => {
              processLoginAndAccessToken()
            }}
          />

        </div>
      </Modal>
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmAction}
        message={confirmationMessage}
      />
      <LoadingDialog message={'Processing'} isOpen={isLoading} />
      <ErrorDialog message={errorMessage} isOpen={showError} onClose={() => setShowError(false)} />
      <SuccessfulDialog message={successMessage} isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} />

      <Modal isVisible={generateNewReport} onClose={() => setGenerateNewReport(false)}>
        <div id='report-details-view' className={`flex flex-col space-y-3 ${capturingNewReportDetails ? 'fade-in' : ' fade-out'}`}>

          <p className='text-sm border-b-2 p-2'>Generate new report</p>

          <div className="relative mt-3">
            <input value={resultsTitle} onChange={(e) => setResultsTitle(e.target.value)} type="text" name='subjectGroupName' id="subjectGroupName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
            <label htmlFor="subjectGroupName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Results Title</label>
          </div>

          <div className='w-full'>
            <p className='text-sm'>Select Class</p>
            <CustomDropdown
              options={schoolBlockClassesDropdownOptions}
              onOptionSelect={(valueSelected) => {
                const selected = pickSelectedObject(schoolBlockClasses, 'className', valueSelected);
                setSelectedSchoolBlockClass(selected);
              }}
              style={'w-full'} />
          </div>

          <div className='grid grid-cols-2 gap-2 mx-1'>
            <div className='w-full'>
              <p className='text-sm'>Term</p>
              <CustomDropdown options={schoolAcademicTerms}
                onOptionSelect={(value) => setSelectedTerm(value)}
                style={'w-full'} />
            </div>
            <div className='w-full'>
              <p className='text-sm'>Select Year</p>
              <CustomDropdown options={academicYears}
                onOptionSelect={(value) => setSelectedYear(value)}
                style={'w-full '} />
            </div>
          </div>

          <div className='flex flex-row items-end space-x-2'>
            <div className='w-1/2'>
              <p className='text-sm'>Computation Option</p>
              <CustomDropdown options={computationOptions}
                onOptionSelect={(value) => {
                  setSelectedComputationOption(value)
                  if (value == computationOptions[0]) {
                    setComputeToHundred(true)
                  } else {
                    setComputeToHundred(false)
                  }
                }}
                style={'w-full'} />
            </div>


            <div className="relative w-1/2 mb-2.5">
              <input value={totalSubjects} onChange={(e) => settotalSubjects(e.target.value)} type="text" name='subjectGroupName' id="subjectGroupName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
              <label htmlFor="subjectGroupName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Total L . Areas</label>
            </div>

          </div>


          <CustomButton
            buttonStyle={
              "w-full md:w-auto text-sm text-white px-2 py-3 my-2 md:my-auto bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
            }
            buttonText={"Save"}
            onClick={() => saveReportDetails()}
          />
        </div>
        <div id='add-exams-view' className={`flex flex-col fade-transition  ${capturingNewReportDetails ? 'fade-out' : 'fade-in'}`}>

          <div className=' border-b-2 p-2 '>
            <span className='text-sm'>Add Exams to be included in computation</span>
          </div>
          <div className='w-full mt-3'>
            <p className='text-sm'>Select Exam</p>
            <CustomDropdown
              options={examsDropDownOptions}
              onOptionSelect={(valueSelected) => {
                const selected = pickSelectedObject(exams, 'examName', valueSelected);
                console.log('piced exam', selected)
                // selected.examName = selectedSchoolBlockClass.className + " " + selected.examName;
                selected.examName = selected.examName;
                setSelectedExam(selected);
              }}
              style={'w-full'} />
          </div>
          <div className='grid grid-cols-2 gap-2 mx-1 mb-4'>
            <div className='w-full'>
              <p className='text-sm'>Select Year</p>
              <CustomDropdown options={academicYears}
                onOptionSelect={(value) => {
                  setExamToPickYear(value)

                }}
                style={'w-full '} />
            </div>
            <div className='w-full flex flex-row items-end'>
              <div className='w-full'>
                <p className='text-sm'>Select Term</p>
                <CustomDropdown options={schoolAcademicTerms}
                  onOptionSelect={(value) => {
                    setExamToPickTerm(value)
                  }}
                  style={'w-full'} />
              </div>

            </div>

          </div>
          <CustomButton
            buttonStyle={
              "w-full md:w-auto text-sm text-white px-2 py-3 my-2 md:my-auto bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
            }
            buttonText={"Add"}
            onClick={() => {

              if (isNull(selectedExam.examId)) { setGenerateNewReport(false); setShowError(true); setErrorMessage("Exam not selected"); }
              else if (isNull(examToPickYear)) { setGenerateNewReport(false); setShowError(true); setErrorMessage("Exam year not selected"); }
              else if (isNull(examToPickTerm)) { setGenerateNewReport(false); setShowError(true); setErrorMessage("Exam term not selected"); }
              else {

                // Check if the object with specific examId, year, and term already exists
                const exists = examsIncludedInResults.some(item =>
                  item.examId === selectedExam.examId &&
                  item.year === examToPickYear &&
                  item.term === examToPickTerm
                );
                if (!exists) {
                  const selectedExamToAdd =
                  {
                    "examToBeComputedId": 0,
                    "resultsId": 0,
                    "examId": selectedExam.examId,
                    "year": examToPickYear,
                    "term": examToPickTerm,
                    "coveragePercentage": 0,
                    "examName": selectedExam.examName,
                    "status": ACTIVE_STATUS
                  };
                  addObject(selectedExamToAdd);
                }
              }

            }}

          />

          <table className="w-full mb-10">
            <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
              <tr>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">Exam Name</th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">{selectedComputationOption === computationOptions[0] ? '%' : ''}</th>
                <th className="p-3 text-sm font-semibold tracking-wide text-left">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">

              {examsIncludedInResults.length == 0 ?

                (<tr className="" >
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    <p className='text-sm ml-1 mt-1'>No Exams added</p>
                  </td></tr>)

                : examsIncludedInResults.map((exam, index) => (
                  <tr className="bg-white" key={index} >
                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{exam.examName}</span>
                      <p className='text-sm ml-1 mt-1'>Term {exam.term} {exam.year}</p>
                    </td>
                    <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                      <input onChange={e => updateExamIncludedPercentage(exam, e.target.value)} type="text" value={exam.coverage} className={`p-2 border w-14 rounded-lg ${selectedComputationOption !== computationOptions[0] && 'hidden'} `} />
                    </td>
                    <td className="p-3 flex space-x-2">
                      {/* onClick={toggleShowStudentDetailsModal} */}
                      <button onClick={() => removeObjectAtIndex(index)} className="border p-2 rounded-lg text-sm">Remove</button>
                    </td>
                  </tr>
                ))}

            </tbody>
          </table>


          <CustomButton
            buttonStyle={
              "w-full md:w-auto text-sm text-white px-2 py-3 my-2 md:my-auto bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
            }
            buttonText={"Submit"}
            onClick={() => {
              // Calculate the sum of coveragePercentage values
              //const sumCoveragePercentage = examsIncludedInResults.reduce((sum, item) => sum + item.coveragePercentage, 0);
              //const sumCoveragePercentage=0;
              const sumCoveragePercentage = examsIncludedInResults.reduce((sum, exam) => {
                const added = sum + parseInt(exam.coveragePercentage, 10);
                console.log('added--------> ', added)
                return added;
              }, 0);

              console.log('sumCoveragePercentage', sumCoveragePercentage);
              // Check if the sum equals 100
              const isSumEqual100 = sumCoveragePercentage === 100;
              if (!isSumEqual100 && selectedComputationOption === computationOptions[0]) {
                setGenerateNewReport(false)
                setErrorMessage("Coverage for selected exams for computation must add up to 100%")
                setShowError(true);
              } else {
                submitResultsToCompute()
              }

            }}
          />
        </div>
      </Modal>
      <div className='flex flex-col'>
        <div id="menu-controller" className="flex w-full justify-end space-x-2 p-2 ">
          {/* <div onClick={() => setOpenSearchOptions(!openSearchOptions)} className="flex items-center rounded-lg border p-2 cursor-pointer text-sm bg-white">
            <p className="text-sm text-gray-600">Search options</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openSearchOptions && "rotate-180"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div> */}
          <div onClick={() => setOpenActionButtons(!openActionButtons)} className="flex items-center rounded-lg border cursor-pointer p-2 text-sm bg-white">
            <p className="text-sm text-gray-600">Actions</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openActionButtons && "rotate-180"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
        </div>
        <div id="class-filters" className="flex flex-col md:flex-row  items-end space-x-0  md:space-x-4 justify-end  w-full md:w-auto">
          <div className={`flex flex-col md:flex-row items-end space-x-0 md:space-x-4 w-full md:w-auto transition-opacity duration-300 ${!openSearchOptions && "hidden"}`}>
            <div className='text-sm w-full md:w-auto'>
              <p>Select Academic Level</p>
              <CustomDropdown options={classLevels} style={'w-full md:w-52'} />
            </div>
            <div className='text-sm w-full md:w-auto'>
              <p>Select Class</p>
              <CustomDropdown options={blockClasses} style={'w-full md:w-52'} />
            </div>
            <div className='text-sm w-full md:w-auto'>
              <p>Select Term</p>
              <CustomDropdown options={schoolAcademicTerms}

                style={'w-full md:w-52'} />
            </div>
            <div className='text-sm w-full md:w-auto'>
              <p>Select Year</p>
              <CustomDropdown
                options={academicYears}

                style={'w-full md:w-52'} />
            </div>
            <div className="rounded-lg m-2 py-4 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full md:w-52 bg-green-600 text-white space-x-1">
              <IoSearchSharp />
              <p>View</p>
            </div>
          </div>
          <div className={`${!openActionButtons && "hidden"} w-full flex flex-col md:flex-row space-x-0  md:space-x-4 items-center justify-end`}>
            <div onClick={() => {
              if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                setShowError(true)
              } else {
                setGenerateNewReport(true)
              }
            }
            } className={` rounded-lg m-2 py-3.5 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full md:w-52 bg-green-600 text-white space-x-1`}>
              <FaPlus />
              <p>Generate new reports</p>
            </div>
          </div>
        </div>
      </div>

      <Modal isVisible={viewAllAnalysisOptions} onClose={() => setViewAllAnalysisOptions(false)}>
        <div className="m-6 flex flex-col">
          <div className=' border-b-4'>
            <p className='text-sm mb-3'>Download Analysis Files</p>
          </div>
          {/* <div className='flex flex-col text-xs'>
            <p>Recipient Email</p>
            <div className="relative w-1/2 mb-2.5 mt-5">
              <input value={recipientEmail} onChange={(e) => setRecipientEmail(e.target.value)} type="text" name='recipientEmail' id="recipientEmail" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
              <label htmlFor="recipientEmail" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Recipient Email</label>
            </div>
          </div> */}
          <div className='max-h-96 overflow-auto grid grid-cols-1 md:grid-cols-2 gap-3 mt-3'>
            {/* <div className='flex flex-wrap mt-3'> */}

            {generatedReports.map((generatedReport, index) => (
              <div
                onClick={() => {
                  console.log('generatedReport details ', generatedReport)
                  setViewAllAnalysisOptions(false)
                  if (generatedReport.status == PROCESSED_STATUS) {
                    //hit download funnction here
                    openNewTab(FILES_DOWNLOAD_LINK + generatedReport.generatedDocument)
                  } else {

                    setErrorMessage("File not ready for download")
                    setShowError(true)
                  }
                }}
                className={`shadow rounded-lg border-2 px-6 py-10  ${generatedReport.status !== PROCESSED_STATUS ? 'bg-grey-200 text-black' : 'bg-blue-700 text-white cursor-pointer'} `}
                key={index}
              >
                <p className='text-xs flex-wrap'>{generatedReport.examAnalysisOption}</p>
                <p className={`${!generatedReport.filterByClassStream && 'hidden'} text-xs`}> ({generatedReport.streamName})</p>
              </div>
            ))}
          </div>
        </div>
      </Modal>

      <div className="w-full overflow-auto rounded-lg shadow ">
        <table className="w-full mb-10">
          <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Results Title</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Period</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Computation</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Exams Included</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Total L. Areas</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Ranking Option</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Progress Status</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Status</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {resultsDetailsComputedArray.length == 0 ?

              (<tr className=""  >
                <td className="p-3 text-xs text-gray-700 whitespace-nowrap ">
                  <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">No results to display</span>
                </td></tr>)



              : resultsDetailsComputedArray.map((results, index) =>
                <tr className="bg-white" key={index} >
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap ">
                    <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{results.resultsTitle}</span>
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    {"Term " + results.term + "," + results.year}
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    <span className={`p-1.5 text-xs font-medium uppercase tracking-wider text-green-800  ${results.computeToHundred ? 'bg-green-200' : 'bg-blue-400'} rounded-lg bg-opacity-50`}>{results.computeToHundred ? 'To 100%' : 'Exam total score'}</span>
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    {results.examsIncludedInResults.map((exam, index) =>

                      <p>
                        <span className='p-1 font-serif font-semibold'>{index + 1}. {exam.examName}</span>
                        <p className='text-xs'>Term {exam.term} Year {exam.year}</p>
                      </p>
                    )}
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    {results.totalRankingSubjects}
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    By {results.rankingOption}
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    <div className='flex flex-row space-x-2 items-center'>
                      <p>{results.status}</p>
                      {results.status == PENDING_STATUS && (<IoHourglassOutline className="rotate-180 animate-spin h-4 w-4 text-gray-500 transition-transform duration-500 transform " />)}
                      {results.status == PROCESSED_STATUS && (<BsFillCheckCircleFill className=" h-4 w-4 text-gray-500 transition-transform duration-500 transform " />)}
                      {results.status == FAILED_STATUS && (<BsXCircleFill className="  h-4 w-4 text-gray-500 " />)}
                    </div>
                  </td>
                  <td className="p-3 text-xs text-gray-700 whitespace-nowrap">
                    <span>{results.statusDescription}</span>
                  </td>
                  <td className="p-3 flex space-x-2">
                    {/* onClick={toggleShowStudentDetailsModal} */}

                    <button
                      onClick={() => {
                        if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                          setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                          setShowError(true)
                        } else {
                          setSelectedReport(results)
                          regenerateAnalysis(results)
                        }
                      }}
                      className="border p-2 rounded-lg text-xs">Regenerate</button>

                    <button
                      onClick={() => {
                        if (results.status !== PROCESSED_STATUS) {
                          const messaage = results.status === PENDING_STATUS ?
                            "Your files arent ready for download, please hold on as we process. Thank you" :
                            "We couldnt process your data, please try to regenerate , if problem persists kindly contact us for assistance. Thank you";
                          setErrorMessage(messaage)
                          setShowError(true);
                        } else {
                          setSelectedReport(results)
                          setRecipientEmail(schoolData.email)
                          fetchResultDocuments(results.resultsId)
                        }

                      }}
                      className="border p-2 rounded-lg text-xs">Download Files</button>
                    <button
                      onClick={() => {
                        if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                          setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                          setShowError(true)
                        } else {
                          if (results.status !== PROCESSED_STATUS) {
                            setErrorMessage("You can only send sms after results are fully generated.")
                            setShowError(true);
                          } else {
                            setSelectedReport(results)
                            setPageAction(pageActions[0])
                            setIsConfirmationOpen(true)
                            setConfirmationMessage("Do you really want to send this messages to guardians?")
                          }
                        }
                      }}
                      className='border p-2 rounded-lg text-xs'>Send Sms</button>
                      <button
                      onClick={() => {
                        if (loggedInUser.primaryRole !== ADMIN__ROLE) {
                          setErrorMessage(ONLY_PERFORMED_BY_ADMIN_MESSAGE)
                          setShowError(true)
                        } else {
                          setSelectedReport(results)
                          setPageAction(pageActions[1])
                          setIsConfirmationOpen(true)
                          setConfirmationMessage("Confirm you want to delete "+results.resultsTitle)
                        }
                      }}
                      className='border p-2 rounded-lg text-xs'>Delete</button>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    </div>

  )
}

export default Reports
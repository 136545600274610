import React, { useEffect, useState } from 'react'
import CustomButton from "../../components/CustomButton";
import CustomDropdown from "../../components/CustomDropdown"
import ConfirmationDialog from "../../components/ConfirmationDialog"
import { FaPlus } from "react-icons/fa6";
import Modal from '../../components/Modal';
import { CiSearch } from "react-icons/ci";
import { IoSearchSharp } from "react-icons/io5";
import { BsArrowRepeat, BsCheck, BsPlus } from "react-icons/bs";
import { getFromLocalStorage, saveToLocalStorage } from '../../services/localStorageService';
import { ACADEMIC_BLOCK_ID_844, ADMIN__ROLE, FORBIDEN_MESSAGE, INVALID_API_RESPONSE, LOGGED_IN_USER_TOKEN_DETAILS, ONLY_PERFORMED_BY_ADMIN_MESSAGE, SCHOOL_ACADEMIC_BLOCKS_LOCALSTORAGE_KEY, SCHOOL_ID_LOCALSTORAGE_KEY, SCHOOL_STREAMS_LOCALSTORAGE_KEY, cbcClasses } from '../../configs/constants';
import httpService from '../../services/HttpService';
import { extractStringsFromArray, extractStringsFromArrayWithActiveStatus, filterArrayByKeyValue, isNull, pickSelectedObject } from '../../services/Utilities';
import SuccessfulDialog from '../../components/SuccessfulDialog';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import CustomDropdownWithCancelIcon from '../../components/CustomDropdownWithCancelIcon';

function SubjectMappings() {



  const [openSearchOptions, setOpenSearchOptions] = useState(false);
  const [openActionButtons, setOpenActionButtons] = useState(false);
  const classLevels = ['Pre Primary', 'Lower Primary', 'Upper primary', 'Junior Secondary', 'Senior Secondary'];
  const blockClasses = ['Play Group', 'PP1', 'PP2'];
  // const subjectStudents = [
  //   { student: 'James Waweru', subjectId: 1, status: 'Active', subjectName: 'English', className: 'PP1', streamId: 1 },
  //   { student: 'Grace Kariuki', subjectId: 2, status: 'Inactive', subjectName: 'Mathematics', className: 'PP2', streamId: 2 },
  //   { student: 'John Mwangi', subjectId: 3, status: 'Active', subjectName: 'Science', className: 'PP3', streamId: 1 }
  // ];


  const [learningAreaText, setLearningAreaText] = useState('Learning Area');
  const [selectText, setSelectText] = useState('Select All')
  const [subjectStudents, setSubjectStudents] = useState([]);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const searchInitialParams = [{ key: 'schoolId', value: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) }, { key: 'classId', value: 0 }, { key: 'streamId', value: 0 }, { key: 'classSubjectId', value: 0 }];
  const [searchStudentSubjectsParams, setSearchStudentSubjectsParams] = useState(searchInitialParams)
  const [classStudents, setClassStudents] = useState([]);
  const [classSubjectStudents, setClassSubjectStudents] = useState([]);
  const [filteredClassStudents, setFilteredClassStudents] = useState([]);
  const [schoolBlockClasses, setSchoolBlockClasses] = useState([]); //getFromLocalStorage(BLOCK_CLASSES_LOCALSTORAGE_KEY)
  const [schoolBlockClassesDropdownOptions, setSchoolBlockClassesDropdownOptions] = useState([]);
  const [schoolBlockClassesDropdownOption, setSchoolBlockClassesDropdownOption] = useState('');
  const [selectedBlockClassOption, setSelectedBlockClassOption] = useState('');
  const [selectedBlockClass, setSelectedBlockClass] = useState({});
  const [selectedSchoolBlockClass, setSelectedSchoolBlockClass] = useState({})
  const [classStreams, setClassStreams] = useState([]);
  const [classStreamsOptions, setClassStreamsOptions] = useState([])
  const [selectedClassStreamOption, setSelectedClassStreamOption] = useState('')
  const [selectedClassStream, setSelectedClassStream] = useState({})
  const [searchSubjectParams, setSearchSubjectParams] = useState([{ key: 'schoolId', value: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) },
  { key: 'size', value: '50' }, { key: 'page', value: '0' }, { key: 'status', value: 'Active' }, { key: 'classId', value: '0' }])
  const [classSubjects, setclassSubjects] = useState([]);
  const [classSubjectsFormOptions, setClassSubjectsFormOptions] = useState([])
  const [selectedClassSubject, setSelectedClassSubject] = useState({});

  const [loggedInUser, setLoggedInUser] = useState(getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS));
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [userPassword, setUserPassword] = useState("");
  const [allClassStreams , setAllClassStreams] = useState([])

  const processLoginAndAccessToken = async () => {

    if (userPassword.length == 0) {
      setErrorMessage('Please enter your password')
      setShowError(true)
      return;
    }

    setIsLoading(true)

    try {
      const payLoad = {
        "username": loggedInUser.mobileNumber,
        "password": userPassword
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken //this token isnt really needed
      };
      const response = await httpService.request('POST', 'auth/schoolStaffLogin', payLoad, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          saveToLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS, response.body)
          setLoggedInUser(response.body)
          setIsSessionExpired(false)

          setSuccessMessage("Successfully logged in")
          setShowSuccessMessage(true)

          //action after refreshing token
          fetchBlockClasses()

        }

        else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      // setErrorMessage(INVALID_API_RESPONSE);
      // setShowError(true)

      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }


    }
  };


  useEffect(() => {
    setSchoolBlockClassesDropdownOptions(extractStringsFromArray(schoolBlockClasses, "className"))
  }, [schoolBlockClasses]);

  useEffect(() => {
    console.log('searchStudentSubjectsParams', searchStudentSubjectsParams)
  }, [searchStudentSubjectsParams]);


  useEffect(() => {
    console.log('selectedSchoolBlockClass', selectedSchoolBlockClass)
    //do something after selecting class
    //setClassStreamsOptions(extractStringsFromArray(classStreams, 'streamName'))
    if (!isNull(selectedSchoolBlockClass.classId)) {
      if(selectedSchoolBlockClass.academicBlockId === ACADEMIC_BLOCK_ID_844){
        setLearningAreaText('Subject')
        
      }else{
        setLearningAreaText('Learning Area')
      }
      // if(selectedSchoolBlockClass.classId !== null && selectedSchoolBlockClass.classId !== undefined){
      updateOrAddSearchSubjectsParams('classId', { key: 'classId', value: selectedSchoolBlockClass.classId });
      updateOrAddSearchStudentParams('classId', { key: 'classId', value: selectedSchoolBlockClass.classId });
    }//selectedSchoolBlockClass.classId

    if (allClassStreams.length < 1) {
      fetchSchoolStreams()
    }

    setClassStreams(allClassStreams.filter(data => data.classId === selectedSchoolBlockClass.classId))


  }, [selectedSchoolBlockClass]);


  useEffect(() => {
    console.log('selectedClassStreamOption', selectedClassStreamOption)
    if (isNull(selectedClassStreamOption)) {
      updateOrAddSearchStudentParams('streamId', { key: 'streamId', value: 0 })
    } else {
      const filteredStreams = classStreams.filter((stream)=> stream.classId === selectedSchoolBlockClass.classId && stream.schoolAcademicBlockId !== 0);
      setSelectedClassStream(pickSelectedObject(filteredStreams, 'streamName', selectedClassStreamOption))
    }
  }, [selectedClassStreamOption]);

  useEffect(() => {
    console.log('selectedClassStream', selectedClassStream)
    if (selectedClassStream.streamId !== null && selectedClassStream.streamId !== undefined) {
      updateOrAddSearchStudentParams('streamId', { key: 'streamId', value: selectedClassStream.streamId })
    }
  }, [selectedClassStream]);

  useEffect(() => {
    console.log('{{--------}}', searchSubjectParams)
    searchSubjects()
  }, [searchSubjectParams]);

  useEffect(() => {
    setClassSubjectsFormOptions(extractStringsFromArray(classSubjects, 'subjectName'))
  }, [classSubjects]);

  useEffect(() => {
    if (!isNull(selectedClassSubject.classSubjectId)) {
      updateOrAddSearchStudentParams('classSubjectId', { key: 'classSubjectId', value: selectedClassSubject.classSubjectId })
    }
  }, [selectedClassSubject]);





  useEffect(() => {
    console.log('updated subjectStudents ', subjectStudents)
  }, [subjectStudents]);






  const updateOrAddSearchSubjectsParams = (keyToUpdate, newObject) => {
    const index = searchSubjectParams.findIndex(obj => {
      return obj.key === keyToUpdate;
    });

    if (index !== -1) {
      // If exists, update the existing object
      setSearchSubjectParams(prevParams => [
        ...prevParams.slice(0, index),
        { ...prevParams[index], ...newObject },
        ...prevParams.slice(index + 1),
      ]);
    } else {
      // If doesn't exist, add the new object
      setSearchSubjectParams(prevParams => [...prevParams, newObject]);
    }
  };



  const updateOrAddSearchStudentParams = (keyToUpdate, newObject) => {
    const index = searchStudentSubjectsParams.findIndex(obj => {
      return obj.key === keyToUpdate;
    });

    if (index !== -1) {
      // If exists, update the existing object
      setSearchStudentSubjectsParams(prevParams => [
        ...prevParams.slice(0, index),
        { ...prevParams[index], ...newObject },
        ...prevParams.slice(index + 1),
      ]);
    } else {
      // If doesn't exist, add the new object
      setSearchStudentSubjectsParams(prevParams => [...prevParams, newObject]);
    }
  };

  const getSearchString = (item, searchParams) => {
    const searchString = searchParams.map((param) => `${param.key}=${param.value}`).join('&');
    return `${item}?${searchString}`; //i.e item="search?"
  };

  const fetchSubjectStudents = async () => {
    //localhost:8080/v1/api/crud/SubjectGradeScale
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', getSearchString('onboarding/searchStudentSubjects', searchStudentSubjectsParams), null, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSubjectStudents(response.body)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }

    }
  }

  function toggleAllStudentSubjects(isSelected) {
    const dataToLoop = subjectStudents;
    setSubjectStudents([])
    console.log('Im selected --> ', isSelected)
    if (isSelected) {
      setSelectText('Remove All')
    } else {
      setSelectText('Select All')
    }

    const updatedData = dataToLoop.map(item => ({ ...item, status: isSelected ? 'Active' : 'Inactive' }));
    setSubjectStudents(updatedData);
  }

  function toggleStudentSubjects(studentSubjectId, selected) {
    setSubjectStudents(subjectStudents => {
      return subjectStudents.map(subjectStudent => {
        if (subjectStudent.studentSubjectId === studentSubjectId) {
          return { ...subjectStudent, status: selected ? "Active" : "Inactive" };
        }

        return subjectStudent;
      });
    });

  }

  useEffect(() => {
    fetchBlockClasses();
  }, []);

  useEffect(() => {
    console.log('classStreams to filter from', classStreams)
    console.log('selectedSchoolBlockClass object->', selectedSchoolBlockClass)
    const filteredStreams = classStreams.filter((stream)=> stream.classId === selectedSchoolBlockClass.classId && stream.schoolAcademicBlockId !== 0);
    console.log('streams after filters ', filteredStreams)
    //const actualStreamsToDisplay = filterArrayByKeyValue(classStreams , 'schoolAcademicBlockId', selectedSchoolAcademicBlock.schoolAcademicBlockId)
    //console.log('selectedSchoolAcademicBlock ((--))', selectedSchoolAcademicBlock)
    // setClassStreamsOptions(extractStringsFromArray(classStreams, 'streamName'))
    setClassStreamsOptions(extractStringsFromArray(filteredStreams, 'streamName'))
  }, [classStreams]);

  const searchSubjects = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', getSearchString('onboarding/searchSubjects', searchSubjectParams), null, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setclassSubjects(response.body.content);
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }

    }
  }

  const fetchBlockClasses = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/getSchoolBlockClasses/' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY), null, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSchoolBlockClasses(response.body);

        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }

    }
  }

  const crudAllStudentSubjects = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'onboarding/crud/AllStudentSubjects', subjectStudents, headers);
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          setSuccessMessage('Successfully updated')
          setShowSuccessMessage(true)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }

    }
  }


  const fetchSchoolStreams = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('GET', 'onboarding/searchSchoolStream?schoolId=' + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + '&status=Active', null, headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          //setClassStreams(response.body.content)
          setAllClassStreams(response.body.content)
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if (!isSessionExpired) {
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }

    }
  }



  return (
    <div className='p-5 h-screen bg-gray-100 w-full overflow-y-auto'>
      <Modal isVisible={isSessionExpired} onClose={() => setIsSessionExpired(false)}>
        <div className='flex flex-col p-4'>
          <p className={`text-xs text-red-500`}>Session expired, please login  again to refresh your session</p>
          <div className='grid grid-cols-1 md:grid-cols-2 mt-2 gap-1 md:gap-2'>
            <div className="flex flex-col py-2 ">
              <label className=" text-black text-xs">Phone number</label>
              <input
                value={loggedInUser.mobileNumber}
                id="phone"
                className={` border-red-500 text-xs rounded-lg bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none`}
                type={"phone"}
              ></input>

            </div>
            <div className="flex flex-col py-2">
              <label className="text-black text-xs">Password</label>
              <input
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                id="password"
                className="rounded-lg text-xs bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none"
                type={"password"}
              ></input>
            </div>
          </div>
          <CustomButton
            // bg-gradient-to-b from-[#FEE6F7] to-[#f8aee2]
            buttonStyle={
              "w-full my-5 py-4 bg-blue-400 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
            }
            buttonText={"Login"}
            onClick={() => {
              processLoginAndAccessToken()
            }}
          />

        </div>
      </Modal>
      <LoadingDialog message={'Processing'} isOpen={isLoading} />
      <ErrorDialog message={errorMessage} isOpen={showError} onClose={() => setShowError(false)} />
      <SuccessfulDialog message={successMessage} isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} />
      <div className='flex flex-col'>
        <div id="menu-controller" className="flex w-full justify-end space-x-2 p-2 items-center">
          {/* <div className='flex flex-row space-x-2'>
            <span className=' bg-slate-500 text-white text-sm p-2 rounded-lg'>Term 1</span>
            <span className='bg-slate-500 text-white text-sm p-2 rounded-lg'>Year 2023</span>
          </div> */}
          <div onClick={() => setOpenSearchOptions(!openSearchOptions)} className="flex items-center rounded-lg border p-2 cursor-pointer text-sm bg-white">
            <p className="text-sm text-gray-600">Search options</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openSearchOptions && "rotate-180"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
          <div onClick={() => setOpenActionButtons(!openActionButtons)} className="flex items-center rounded-lg border cursor-pointer p-2 text-sm bg-white">
            <p className="text-sm text-gray-600">Actions</p>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openActionButtons && "rotate-180"}`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </div>
        </div>
        <div id="class-filters" className="flex flex-col md:flex-row  items-end space-x-0  md:space-x-4 justify-end  w-full md:w-auto">
          <div className={` flex flex-col md:flex-row items-end space-x-0 md:space-x-4 w-full md:w-auto transition-opacity duration-700 ${!openSearchOptions ? "fade-out" : "fade-in"}`}>
            {/* <div className='text-sm w-full md:w-auto'>
              <p>Select Academic Level</p>
              <CustomDropdown options={schoolAcademicBlocksOptions} onOptionSelect={setSelectedAcademicBlockOption} style={'w-full md:w-40'} />
            </div> */}
            <div className='text-sm w-full md:w-auto'>
              <p>Select Class</p>
              <CustomDropdown options={schoolBlockClassesDropdownOptions} onOptionSelect={(valueSelected) => {
                //setSelectedPageCrudAction(pageCrudActions[6]);
                //console.log('valueSelected', valueSelected)
                //console.log('schoolBlockClasses', schoolBlockClasses)
                const selected = pickSelectedObject(schoolBlockClasses, 'className', valueSelected);
                //console.log('selected',selected)
                //updateOrAddSearchStudentParams('classId', { key: 'classId', value: selected.classId });
                setSelectedSchoolBlockClass(selected);
                //searchSubjects()

              }} style={'w-full md:w-40'} />
            </div>
            <div className='text-sm w-full md:w-auto'>
              <p>Select Stream</p>
              {/* <CustomDropdown options={classStreamsOptions} onOptionSelect={setSelectedClassStreamOption}  style={'w-full md:w-40'} /> */}
              <CustomDropdownWithCancelIcon
                options={classStreamsOptions}
                onOptionSelect={setSelectedClassStreamOption}
                style={'w-full md:w-40'}
                isOptionSelected={!isNull(selectedClassStreamOption)}
              />
            </div>
            <div className='text-sm w-full md:w-auto'>
              <p>Select {learningAreaText}</p>
              <CustomDropdown options={classSubjectsFormOptions}
                onOptionSelect={(valueSelected) => {
                  setSelectedClassSubject(pickSelectedObject(classSubjects, 'subjectName', valueSelected))
                }} style={'w-full md:w-40'} />
            </div>
            <div onClick={() => fetchSubjectStudents()} className="rounded-lg m-2 py-4 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full  md:w-32 bg-green-600 text-white space-x-1">
              <IoSearchSharp />
              <p>View</p>
            </div>
          </div>
          <div className={`${!openActionButtons ? "fade-out" : "fade-in"} flex flex-col md:flex-row space-x-0  w-full md:w-auto md:space-x-4 items-center justify-end`}>
            <div onClick={() => {
             crudAllStudentSubjects()
            }

            } className={` rounded-lg m-2 py-3.5 px-2 border flex flex-row items-center justify-center text-sm cursor-pointer w-full md:w-32 bg-green-600 text-white space-x-1`}>
              <FaPlus />
              <p>Save</p>
            </div>
          </div>
        </div>
      </div>


      <div className="w-full overflow-auto rounded-lg shadow ">
        <table className="w-full mb-10">
          <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Student</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Admission No</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Subject</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Class</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">
                <input type='checkbox' onChange={(e) => toggleAllStudentSubjects(e.target.checked)} />
                <span className='ml-2'>{selectText}</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">


            {subjectStudents.length == 0 ?

              (<tr>
                <td>No data to display</td>
              </tr>)

              : subjectStudents.map((subjectStudent, index) =>

                <tr className="bg-white" key={index} >
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap ">
                    <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">
                      {subjectStudent.student.firstName} {subjectStudent.student.otherNames} {subjectStudent.student.lastName}</span>
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    {subjectStudent.student.schoolAdmissionNumber}
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    {subjectStudent.classSubject.subjectName}
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    {subjectStudent.blockClass.className}
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    {subjectStudent.status === 'Active' ? (
                      <div className="flex items-center space-x-2">
                        <input type="checkbox" id="myCheckbox" value={subjectStudent.status}
                          checked={subjectStudent.status == 'Active' ? true : false}
                          onChange={e => toggleStudentSubjects(subjectStudent.studentSubjectId, e.target.checked)}
                          className="form-checkbox text-blue-500 h-5 w-5" defaultChecked />
                        <label htmlFor="myCheckbox" className="text-gray-700"></label>
                      </div>
                    ) : (
                      <div className="flex items-center space-x-2">
                        <input type="checkbox" id="myCheckbox" value={subjectStudent.status}
                          checked={subjectStudent.status == 'Active' ? true : false}
                          onChange={e => toggleStudentSubjects(subjectStudent.studentSubjectId, e.target.checked)}
                          className="form-checkbox text-blue-500 h-5 w-5" />
                        <label htmlFor="myCheckbox" className="text-gray-700"></label>
                      </div>
                    )}
                  </td>


                </tr>
              )}
          </tbody>
        </table>
      </div>

    </div>
  )
}

export default SubjectMappings
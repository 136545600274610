import React, { useEffect, useState } from 'react';
import CustomButton from "../../components/CustomButton";
import Modal from '../../components/Modal';
import { ACTIVE_STATUS, ADMIN_TEACHER_ROLE, EDIT_ACTION, FORBIDEN_MESSAGE, LOGGED_IN_USER_TOKEN_DETAILS, SCHOOLDATA_LOCALSTORAGE_KEY } from '../../configs/constants';
import { INVALID_API_RESPONSE } from '../../configs/constants';
import ErrorDialog from '../../components/ErrorDialog';
import LoadingDialog from '../../components/LoadingDialog';
import { getFromLocalStorage, saveToLocalStorage } from '../../services/localStorageService';
import { SCHOOL_ID_LOCALSTORAGE_KEY } from '../../configs/constants';
import httpService from '../../services/HttpService';
import CustomDropdown from '../../components/CustomDropdown';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { addKeysAndValuesToArray, convertCSVToJson, isNull, removeObjectsWithNullKey } from '../../services/Utilities';
import SuccessfulDialog from '../../components/SuccessfulDialog';
import CustomDropdownWithCancelIcon from '../../components/CustomDropdownWithCancelIcon';


function Teachers() {

  const [isLoading, setIsLoading] = useState(false);
  const [openSearchOptions, setOpenSearchOptions] = useState(false);
  const [openActionButtons, setOpenActionButtons] = useState(false);
  const [isEditTeacherData, setIsEditTeacherData] = useState(false);
  const [isAddTeacherData, setIsAddTeacherData] = useState(false)
  const [selectedTeacher, setSelectedTeacher] = useState({});
  const [actionSelected, setActionSelected] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [teachers, setTeachers] = useState([]);
  const [teacherFirstNameToSearch, setTeacherFirstNameToSearch] = useState('');
  const [teacherLastNameToSearch, setTeacherLastNameToSearch] = useState('');
  const [teacherMobileNumberToSearch, setTeacherMobileNumberToSearch] = useState('');

  const pageActions = ['AddTeacher', 'EditTeacher', 'DeleteTeacher'];
  const [pageAction, setPageAction] = useState('');


  const searchTeachersDefaultParams = [{ key: 'size', value: '10' }, { key: 'page', value: '0' }, { key: 'schoolId', value: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) },
  { key: 'status', value: 'Active' }, { key: 'firstName', value: teacherFirstNameToSearch }, { key: 'lastName', value: teacherLastNameToSearch }, { key: 'mobileNumber', value: teacherMobileNumberToSearch }];
  const [searchTeachersResultsParams, setSearchTeachersResultsParams] = useState(searchTeachersDefaultParams);
  const [teacherFirstName, setTeacherFirstName] = useState('');
  const [teacherLastName, setTeacherLastName] = useState('');
  const [teacherIdNumber, setTeacherIdNumber] = useState('');
  const [teaacherMobileNumber, setTeaacherMobileNumber] = useState('');
  const [teacherPassword, setTeacherPassword] = useState('');
  const teacherRoles = ['Admin', 'Teacher', 'Bursar'];
  const [selectedTeacherRole, setSelectedTeacherRole] = useState('');
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [importJsonData, setImportJsonData] = useState([]);
  const [finalCsvData, setFinalCsvData] = useState([])
  const [schoolData, setSchoolData] = useState(getFromLocalStorage(SCHOOLDATA_LOCALSTORAGE_KEY))


  const [loggedInUser, setLoggedInUser] = useState(getFromLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS));
  const [isSessionExpired, setIsSessionExpired] = useState(false)
  const [userPassword, setUserPassword] = useState("");
  const processLoginAndAccessToken = async () => {

    if (userPassword.length == 0) {
      setErrorMessage('Please enter your password')
      setShowError(true)
      return;
    }

    setIsLoading(true)


    try {
      const payLoad = {
        "username": loggedInUser.mobileNumber,
        "password": userPassword
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', 'auth/schoolStaffLogin', payLoad , headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {
          saveToLocalStorage(LOGGED_IN_USER_TOKEN_DETAILS, response.body)
          setLoggedInUser(response.body)
          setIsSessionExpired(false)
          //rerun this , its supposed to be called once page is called
          processSearchTeachers()
          setSuccessMessage("Successfully logged in")
          setShowSuccessMessage(true)
        }

        else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      // setErrorMessage(INVALID_API_RESPONSE);
      // setShowError(true)

      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }


    }
  };


  function clearSelectedTeacher() {
    setSelectedTeacher(prevData => {
      return {
        ...prevData,
        email: '',
        idNumber: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
        idNumber: '',
        selectedTeacherRole: '',
        password: ''
      };
    });
  }
  




  const updateOrAddSearchTeacherPaarams = (keyToUpdate, newObject) => {
    // Check if an object with the provided key already exists
    const index = searchTeachersResultsParams.findIndex(obj => {
      return obj.key === keyToUpdate;
    });

    if (index !== -1) {
      // If exists, update the existing object
      setSearchTeachersResultsParams(prevParams => [
        ...prevParams.slice(0, index),
        { ...prevParams[index], ...newObject },
        ...prevParams.slice(index + 1),
      ]);
    } else {
      // If doesn't exist, add the new object
      setSearchTeachersResultsParams(prevParams => [...prevParams, newObject]);
    }
  };




  const getResultsSearchTeacherString = () => {
    const searchString = searchTeachersResultsParams.map((param) => `${param.key}=${param.value}`).join('&');
    return `onboarding/searchTeacher?${searchString}`;
  };


  function pickSelectedTeacher(teacher, action) {
    setPageAction(pageActions[1]);
    setSelectedTeacher(teacher);
    setActionSelected(action)
    setIsEditTeacherData(true);
    //console.log(teacher);
  }

  useEffect(() => {
    processSearchTeachers()
  }, []);


  useEffect(() => {
    console.log('searchTeachersResultsParams', searchTeachersResultsParams)
  }, [searchTeachersResultsParams]);

  useEffect(() => {
    saveToLocalStorage(SCHOOLDATA_LOCALSTORAGE_KEY , schoolData)
  }, [schoolData]);





  const processSearchTeachers = async (url = null) => {
    console.log('url', url)
    const endpoint = url != null ? url : getResultsSearchTeacherString();
    console.log("endpoint", endpoint)
    setIsLoading(true)

    try {

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };


      const completeSearchString = endpoint;
      const response = await httpService.request('GET', completeSearchString , null , headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        if (response.header.responseCode === 200) {

          setTeachers(response.body);
          //update school dara
          setSchoolData((prevData) => ({
            ...prevData,
            totalTeachers : response.body.length,
          }));
          
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };


  useEffect(() => {
    // Log the updated state whenever selectedTeacher changes
    console.log("selectedTeacher", selectedTeacher);
  }, [selectedTeacher]);


  const handleInputChange = (e) => {
    // Update the state with the new value
    setSelectedTeacher((prevTeacher) => ({
      ...prevTeacher,
      [e.target.name]: e.target.value,
    }));
  };

  const processCrudTeacher = () => {
    console.log("processCrudTeacher action: ", pageAction)
    if (pageAction == pageActions[0]) { //new

      const payLoad = selectedTeacher;
      payLoad['schoolId'] = getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY);
      payLoad['status'] = "Active";
      payLoad['role'] = selectedTeacherRole;
      payLoad['password'] = "1234"

      console.log("Final add new teaher payload:", payLoad)
      crudTeacher(payLoad);
      setIsAddTeacherData(false);
    }
    if (pageAction == pageActions[1]) { //edit
      crudTeacher(selectedTeacher);
      setIsEditTeacherData(false);
    }
    if (pageAction == pageActions[2]) { //delete

    }
  }


  const handleCSVInputChange = (event) => {

    //validateGradesCsvUpload();

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;
      const jsonData = convertCSVToJson(csvData);

      //remove object that has grade as null
      const filteredArray = removeObjectsWithNullKey(jsonData, 'firstName');
      console.log('filtered array: ', filteredArray);

      setImportJsonData(filteredArray);
      // Reset the value of the file input to clear it
      event.target.value = "";
    };

    reader.readAsText(file);
  };

  useEffect(() => {
    console.log(importJsonData)

    const keysToAdd = [
      { key: "schoolId", value: getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) },
      { key: "status", value: ACTIVE_STATUS }
    ];

    const newArray = addKeysAndValuesToArray(importJsonData, keysToAdd);
    console.log('new array', newArray);
    // if(newArray.length>0){

    // }
    setFinalCsvData(newArray)

  }, [importJsonData]);

  useEffect(() => {
    if (finalCsvData.length > 0) {
      setTeachers(finalCsvData);
    }

  }, [finalCsvData]);

  useEffect(() => {
    console.log('selectedTeacherRole >>>>> ', selectedTeacherRole)
  }, [selectedTeacherRole]);


 

  

  //selectedTeacherRole


  useEffect(() => {
    if(isAddTeacherData){
      clearSelectedTeacher()
    }

  }, [isAddTeacherData]);

  useEffect(() => {
    if(!isEditTeacherData){
      clearSelectedTeacher()
    }

  }, [isEditTeacherData]);


  
  



  const importTeachersData = async () => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', "onboarding/upload/Teachers", finalCsvData , headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          //setTeachers(response.body.content);
          setSuccessMessage(response.body.body.length+' teachers added. Always ensure phone number used isn\'t registered already.  ')
          setShowSuccessMessage(true)
          processSearchTeachers("onboarding/searchTeacher?size=10&page=0&schoolId=" + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + "&status=Active");
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };



  const crudTeacher = async (data) => {
    setIsLoading(true)
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + loggedInUser.accessToken
      };
      const response = await httpService.request('POST', "onboarding/crud/Teacher", data , headers);
      setIsLoading(false)
      console.log('Server Response:', response);
      if (response && response.header) {
        setIsLoading(false)
        if (response.header.responseCode === 200) {
          //setTeachers(response.body.content);
          setShowSuccessMessage(true)
          setSuccessMessage(response.header.customerMessage)
          processSearchTeachers("onboarding/searchTeacher?size=10&page=0&schoolId=" + getFromLocalStorage(SCHOOL_ID_LOCALSTORAGE_KEY) + "&status=Active");
        } else {
          setErrorMessage(response.header.customerMessage)
          setShowError(true)
        }
      } else {
        setIsLoading(false)
        setErrorMessage(INVALID_API_RESPONSE);
        setShowError(true)
      }


    } catch (error) {
      setIsLoading(false)
      // Handle errors here
      // console.error('Exception:' + error)
      // setErrorMessage(INVALID_API_RESPONSE);
      // setShowError(true)
      console.error('Exception:' + error)
      if (error.response) {
        const statusCode = error.response.status;
        console.log('sttausCode inside exception:', statusCode)
        if (statusCode === 403) {
          setShowError(true)
          setErrorMessage(FORBIDEN_MESSAGE);
        } else if (statusCode === 401) {
          if(!isSessionExpired){
            setIsSessionExpired(true)
          }
        } else {
          setShowError(true)
          setErrorMessage(INVALID_API_RESPONSE);
        }
      } else {
        setShowError(true)
        setErrorMessage(INVALID_API_RESPONSE);
      }
    }
  };


  const openDialogToAddNewTeacher = () => {
    setPageAction(pageActions[0]) //add new teacher
    setSelectedTeacher({});
    setIsAddTeacherData(true);
  }

  const pickTeacherToDelete = (teacher) => {
    setPageAction(pageActions[2]);
    setSelectedTeacher(teacher);
    setConfirmationMessage("Confirm you want to delete " + teacher.firstName + "?")
    setIsConfirmationOpen(true);
  }


  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleConfirmAction = () => {
    // Your confirmation action logic here //const pageActions = ['crudBlockClass','crudSchoolStream'];
    if (pageAction == pageActions[2]) { //delete
      selectedTeacher.status = "Inactive";
      crudTeacher(selectedTeacher);
      setPageAction('');
    }
    console.log('Confirmed action');
    setIsConfirmationOpen(false);
  };





  return (
    <div className="p-5 h-screen bg-gray-100 w-full overflow-y-auto">
      <Modal isVisible={isSessionExpired} onClose={() => setIsSessionExpired(false)}>
        <div className='flex flex-col p-4'>
          <p className={`text-xs text-red-500`}>Session expired, please login  again to refresh your session</p>
          <div className='grid grid-cols-1 md:grid-cols-2 mt-2 gap-1 md:gap-2'>
            <div className="flex flex-col py-2 ">
              <label className=" text-black text-xs">Phone number</label>
              <input
                value={loggedInUser.mobileNumber}
                id="phone"
                className={` border-red-500 text-xs rounded-lg bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none`}
                type={"phone"}
              ></input>

            </div>
            <div className="flex flex-col py-2">
              <label className="text-black text-xs">Password</label>
              <input
                value={userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
                id="password"
                className="rounded-lg text-xs bg-gray-400 mt-2 p-4 text-white focus:border-blue-500 focus:bg-gray-500 focus:outline-none"
                type={"password"}
              ></input>
            </div>
          </div>
          <CustomButton
            // bg-gradient-to-b from-[#FEE6F7] to-[#f8aee2]
            buttonStyle={
              "w-full my-5 py-4 bg-blue-400 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
            }
            buttonText={"Login"}
            onClick={() => {
              processLoginAndAccessToken()
            }}
          />

        </div>
      </Modal>
      {/* Confirmation Dialog */}
      <ConfirmationDialog
        isOpen={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmAction}
        message={confirmationMessage}
      />

      {/*add stream to academic block levels*/}
      <LoadingDialog message={'Processing'} isOpen={isLoading} />
      <ErrorDialog message={errorMessage} isOpen={showError} onClose={() => setShowError(false)} />
      <SuccessfulDialog message={successMessage} isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)} />
      <div id="menu-controller" className="flex w-full justify-end space-x-2 p-2 ">
        <div onClick={() => setOpenSearchOptions(!openSearchOptions)} className="flex items-center rounded-lg border p-2 cursor-pointer text-sm bg-white">
          <p className="text-sm text-gray-600">Search options</p>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openSearchOptions && "rotate-180"}`}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
          </svg>
        </div>
        <div onClick={() => setOpenActionButtons(!openActionButtons)} className="flex items-center rounded-lg border cursor-pointer p-2 text-sm bg-white">
          <p className="text-sm text-gray-600">Actions</p>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`h-6 w-6 p-1 duration-500 ${!openActionButtons && "rotate-180"}`}>
            <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
          </svg>
        </div>
      </div>
      <div id="top-bar-desktop" className="m-2 flex w-full flex-col justify-end md:flex-row md:space-x-2 ">
        <div id="search-fields" className={`flex-col border p-2 md:flex-row md:space-x-2 bg-white rounded ${!openSearchOptions && "hidden"}`}>
          <input value={teacherFirstNameToSearch}
            onChange={(e) => {
              setTeacherFirstNameToSearch(e.target.value)
              updateOrAddSearchTeacherPaarams('firstName', { key: 'firstName', value: e.target.value })
            }
            }
            className="my-2 w-full border p-3 md:w-auto rounded-lg text-sm focus:outline-none border-gray-500 shadow-sm" placeholder="By Firstname" type="search" />
          <input value={teacherLastNameToSearch}
            onChange={(e) => {
              updateOrAddSearchTeacherPaarams('lastName', { key: 'lastName', value: e.target.value })
              setTeacherLastNameToSearch(e.target.value)
            }}
            className="my-2 w-full border p-3 md:w-auto rounded-lg text-sm focus:outline-none border-gray-500 shadow-sm" placeholder="By Lastname" type="search" />
          <input value={teacherMobileNumberToSearch}
            onChange={(e) => {
              setTeacherMobileNumberToSearch(e.target.value)
              updateOrAddSearchTeacherPaarams('mobileNumber', { key: 'mobileNumber', value: e.target.value })
            }} className="my-2 w-full border p-3 md:w-auto rounded-lg text-sm focus:outline-none border-gray-500 shadow-sm" placeholder="By Phonenumber" type="search" />
          {/* <input onChange={(e)=>{}} className="my-2 w-full border p-3 md:w-auto rounded-lg text-sm focus:outline-none border-gray-500 shadow-sm" placeholder="By Phonenumber" type="search" /> */}
          <CustomButton
            buttonStyle={
              "w-full md:w-auto text-sm text-white px-2 my-5 py-3 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
            }
            buttonText={"Search"}
            onClick={() => processSearchTeachers()}
          />

        </div>
        <div id="actions" className={`mt-5 flex flex-col border p-2 md:mt-0 md:flex-row md:space-x-2 bg-white rounded ${!openActionButtons && "hidden"}`}>

          <CustomButton
            buttonStyle={
              "w-full md:w-auto text-sm text-white px-2  py-3 my-2 md:my-auto  bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
            }
            buttonText={"Add teacher"}
            onClick={() => openDialogToAddNewTeacher()}
          />

          <div>
            <input className={` my-2 w-full border p-2 md:w-auto rounded-lg text-sm focus:outline-none border-gray-500 shadow-sm`} placeholder="By Lastname" type="file" accept=".csv" onChange={handleCSVInputChange} />
          </div>
          <CustomButton
            buttonStyle={
              "w-full md:w-auto text-sm text-white px-2 py-3 my-2 md:my-auto bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
            }
            buttonText={"Save Teachers"}
            onClick={() => importTeachersData()}
          />

        </div>
      </div>

      <div className="w-full overflow-auto rounded-lg shadow hidden md:block">

        <table className="w-full mb-10">
          <thead className="bg-gray-50 border-b-2 border-gray-200 w-full">
            <tr>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">No.</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Name</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Category</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Phone No.</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Email</th>
              <th className="p-3 text-sm font-semibold tracking-wide text-left">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {teachers.length == 0 ?
              (
                <tr className="" >
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    <p>No Data teaachers found</p>
                  </td>
                </tr>
              )
              :
              teachers.map((teacher, index) => (
                <tr className="bg-white" key={index}>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    <a href="#" className="font-bold text-blue-500 hover:underline">{index + 1}</a>
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    {teacher.firstName} {teacher.lastName}
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">
                    <span className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-green-200 rounded-lg bg-opacity-50">{teacher.role}</span>
                  </td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{teacher.mobileNumber}</td>
                  <td className="p-3 text-sm text-gray-700 whitespace-nowrap">{teacher.email}</td>
                  <td className="p-3 flex space-x-2">
                    {/* onClick={toggleShowStudentDetailsModal} */}
                    <button onClick={() => {
                       clearSelectedTeacher()
                      if (teacher.firstName === "School Admin") {
                        setErrorMessage("You cant edit this account, please contact us for assistance");
                        setShowError(true);
                      }else{
                        pickSelectedTeacher(teacher, EDIT_ACTION)
                      }
                    }
                    } className="border p-2 rounded-lg text-sm">Edit</button>
                    <button onClick={() => {
                       clearSelectedTeacher()
                      if (teacher.role !== ADMIN_TEACHER_ROLE) {
                        pickTeacherToDelete(teacher)
                      }

                    }} className="border p-2 rounded-lg text-sm">Delete</button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4  md:hidden overflow-y-auto">
        {teachers.map((teacher, index) => (
          <div key={teacher.idNumber} className="bg-white space-y-3 p-4 rounded-lg shadow">
            <p className="text-blue-500 font-bold hover:underline">{teacher.firstName} {teacher.lastName}</p>
            <div className="flex items-center text-gray-500 space-x-2 text-sm">
              <p className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-purple-200 rounded-lg bg-opacity-50">{teacher.idNumber}</p>
              <p className="p-1.5 text-xs font-medium uppercase tracking-wider text-green-800 bg-blue-200 rounded-lg bg-opacity-50">{teacher.mobileNumber}</p>
            </div>
            <div className="flex justify-end items-center space-x-2">
              <button onClick={() => {
                clearSelectedTeacher()
                pickSelectedTeacher(teacher, EDIT_ACTION)
              }
                } className="border p-2 rounded-lg text-sm">Edit</button>
              <button onClick={() => {
                 clearSelectedTeacher()
                pickTeacherToDelete(teacher)
              }
              } 
                className="border p-2 rounded-lg text-sm">Delete</button>
              <button className="border p-2 rounded-lg text-sm">Other</button>
            </div>
          </div>
        ))}
      </div>
     

      <Modal isVisible={isEditTeacherData} onClose={() => setIsEditTeacherData(false)}>
        <div className='flex flex-col'>
          <p className=' text-sm ml-2'>Edit teacher details</p>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-2 p-4'>

            <div>
              <p className='text-sm'>First Name</p>
              <div className="relative mt-2">
                <input type="text" name='firstName' onChange={handleInputChange} value={selectedTeacher.firstName} id="firstName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="firstName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">First name</label>
              </div>
            </div>

            <div>
              <p className='text-sm'>Last Name</p>
              <div className="relative mt-2">
                <input type="text" name='lastName' onChange={handleInputChange} value={selectedTeacher.lastName} id="lastName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="lastName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Last name</label>
              </div>
            </div>


            <div>
              <p className='text-sm'>Mobile number</p>
              <div className="relative mt-2">
                <input type="number" name='mobileNumber' onChange={handleInputChange} value={selectedTeacher.mobileNumber} id="mobileNumber" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="mobileNumber" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Mobile number</label>
              </div>
            </div>

            <div>
              <p className='text-sm'>Tr number</p>
              <div className="relative mt-2">
                <input type="text" name='idNumber' onChange={handleInputChange} value={selectedTeacher.idNumber} id="idNumber" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="idNumber" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Tr number</label>
              </div>
            </div>
            <div>
              <p className='text-sm mt-6'>Tr Email</p>
              <div className="relative mt-2">
                <input type="email" name='email' onChange={handleInputChange} value={selectedTeacher.email} id="email" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="email" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Tr Email</label>
              </div>
            </div>
            <div className=" w-full flex flex-col text-gray-400 py-2 mt-3">
              <p className="text-black">Select teacher role</p>
              <CustomDropdown options={teacherRoles} style={'w-full'} onOptionSelect={setSelectedTeacherRole} />
            </div>

          </div>
          <CustomButton
            buttonStyle={
              "w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
            }
            buttonText={"Save"}
            onClick={() => processCrudTeacher()}
          />
        </div>

      </Modal>

      <Modal isVisible={isAddTeacherData} onClose={() => setIsAddTeacherData(false)}>
        <div className='flex flex-col'>
          <p className=' text-sm ml-2'>Add teacher</p>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-2 p-4'>

            <div>
              <p className='text-sm'>First Name</p>
              <div className="relative mt-2">
                <input type={"text"} name='firstName' onChange={handleInputChange} value={selectedTeacher.firstName} id="firstName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="firstName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">First name</label>
              </div>
            </div>

            <div>
              <p className='text-sm'>Last Name</p>
              <div className="relative mt-2">
                <input type={"text"} name='lastName' onChange={handleInputChange} value={selectedTeacher.lastName} id="lastName" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="lastName" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Last name</label>
              </div>
            </div>


            <div>
              <p className='text-sm'>Mobile number</p>
              <div className="relative mt-2">
                <input type={"number"} name='mobileNumber' onChange={handleInputChange} value={selectedTeacher.mobileNumber} id="mobileNumber" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="mobileNumber" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Mobile number</label>
              </div>
            </div>

            <div>
              <p className='text-sm'>Tr number</p>
              <div className="relative mt-2">
                <input type={"text"} name='idNumber' onChange={handleInputChange} value={selectedTeacher.idNumber} id="idNumber" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="idNumber" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Tr number</label>
              </div>
            </div>
            <div>
              <p className='text-sm mt-4'>Tr Email</p>
              <div className="relative mt-2">
                <input type={"email"} name='email' onChange={handleInputChange} value={selectedTeacher.email} id="email" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="email" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Email</label>
              </div>
            </div>
            <div>
              <p className='text-sm mt-4'>Password</p>
              <div className="relative mt-2">
                <input readOnly type={"password"} name='password' onChange={handleInputChange} value="1234" id="password" className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-2 border-black appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
                <label htmlFor="password" className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Teacher password</label>
              </div>
            </div>
            <div className=" w-full flex flex-col text-gray-400 py-2 mt-1">
              <p className="text-black">Role</p>
              {/* <CustomDropdown options={teacherRoles} 
              style={'w-full'}
               onOptionSelect={(value)=>{
                console.log('selected teacher role', value)
                setSelectedTeacherRole(value)
               }} 
               /> */}
               <CustomDropdownWithCancelIcon
                options={teacherRoles}
                style={'w-full'}
                onOptionSelect={(selectedValue) => {
                  setSelectedTeacherRole(selectedValue)
                }}
                isOptionSelected={!isNull(selectedTeacherRole)}
              />
            </div>

          </div>
          <CustomButton
            buttonStyle={
              "w-full my-5 py-2 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg"
            }
            buttonText={"Save"}
            onClick={() => {
              setPageAction(pageActions[0])
              processCrudTeacher()
            }
              
              }
          />
        </div>

      </Modal>



    </div>

  );
}

export default Teachers;

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import loginImage from "../assets/element5-digital-OyCl7Y4y0Bk-unsplash.jpg";
import { INVALID_API_RESPONSE, SCHOOL_LEVELS_ARRAY } from '../configs/constants';
import CustomButton from "../components/CustomButton";
import httpService from '../services/HttpService';
import LoadingDialog from '../components/LoadingDialog';
import ErrorDialog from '../components/ErrorDialog';
import SuccessfulDialog from '../components/SuccessfulDialog';

function Register() {
    const navigate = useNavigate()
    const [userPhone, setUserPhone] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [email, setEmail] = useState();
    const [adminPassword, setaAminPassword] = useState("")

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [hasRegistered , setHasRegistered] = useState(false)
    useEffect(() => {
        if(!showSuccessMessage && hasRegistered){
            navigate('/auth')
        }
    }, [showSuccessMessage]);

    


    // const [todos, setTodos] = useState(() => {
    //     const localValue = localStorage.getItem("ITEMS")
    //     if (localValue == null) return []

    //     return JSON.parse(localValue)
    //   });


    // "schoolName": "Test School",
    //             "email": "email@test.com",
    //             "phoneNumber": "0726765977",



    const [schoolLevels, setSchoolLevels] = useState(SCHOOL_LEVELS_ARRAY);

    function toggleSchoolLevels(academicBlockId, selected) {
        setSchoolLevels(currentSchoolLevels => {
            return currentSchoolLevels.map(schoolLevel => {
                if (schoolLevel.academicBlockId === academicBlockId) {
                    return { ...schoolLevel, selected, status: selected ? "Active" : "Inactive" };
                }

                return schoolLevel;
            });
        });

    }


    useEffect(() => {
        console.log(schoolLevels);
    }, [schoolLevels]);


    function handleSubmit(e) {
        e.preventDefault();
        console.log("form submitted");
        setUserPassword("");
        setUserPhone("");
        registerSchool();
    }

    const registerSchool = async () => {
        
        setIsLoading(true)
        try {
            const data = {
                "schoolName": schoolName,
                "email": email,
                "phoneNumber": userPhone,
                "adminPassword": adminPassword,
                "logo": "",
                "motto": "Strive for excellence",
                "schoolTerm": "1",
                "schoolYear": "2024",
                "status": "Active",
                "academicBlocks": schoolLevels
            };

            const response = await httpService.request('POST', 'onboarding/registerSchool', data);

            if (response && response.header) {
                setIsLoading(false)
                if (response.header.responseCode === 200) {

                    setShowSuccessMessage(true);
                    setHasRegistered(true)
                    setSuccessMessage("Successfull registered! Proceed to loging now")
                    
                } else {
                    setErrorMessage(response.header.customerMessage)
                    setShowError(true)
                }
            } else {
                setIsLoading(false)
                setErrorMessage(INVALID_API_RESPONSE);
                setShowError(true)
            }



            console.log('Server Response:', response);
        } catch (error) {
            // Handle errors here
            console.error('Register School Error:', error);
            setIsLoading(false)
            // Handle errors here
            console.error('Exception:' + error)
            setErrorMessage(INVALID_API_RESPONSE);
            setShowError(true)
        }
    };

    return (
        <div className=" grid grid-cols-1 sm:grid-cols-2 h-screen w-full">
            <LoadingDialog message={'Processing'} isOpen={isLoading} />
            <ErrorDialog message={errorMessage} isOpen={showError} onClose={() => setShowError(false)} />
            <SuccessfulDialog message={successMessage} isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)}/>
            <div className=" hidden sm:block">
                <img
                    className=" w-full h-screen object-cover"
                    src={loginImage}
                    alt="login"
                ></img>
            </div>

            <div className=" bg-dark-purple flex flex-col items-start justify-center p-6">
                <div className="w-3/4 mx-auto">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className={`w-6 h-6 bg-amber-300 text-4xl rounded cursor-pointer block float-left mr-2 duration-500 `}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0 0 12 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75Z"
                        />
                    </svg>
                    <h1
                        className={`text-white origin-left font-medium text-2xl duration-300 s`}
                    >
                        DigiSchool
                    </h1>
                </div>
                <form
                    onSubmit={handleSubmit}
                    className="w-3/4 mx-auto p-8 px-8 bg-light-white text-black mt-4 rounded-lg"
                >
                    <h2 className=" text-4xl text-white font-bold text-start ">
                        Register
                    </h2>
                    <div className='flex flex-row space-x-2'>
                        <div className=" w-1/2 flex flex-col  py-2 mt-1">
                            <label className='text-xs text-white'>School Name</label>
                            <input
                                value={schoolName}
                                onChange={(e) => setSchoolName(e.target.value)}
                                id="schoolName"
                                className="rounded-lg text-black bg-gray-200 mt-2 p-2 focus:border-blue-500 focus:bg-gray-400 focus:outline-none"
                                type={"text"}
                            ></input>
                        </div>
                        <div className=" w-1/2 flex flex-col py-2 mt-1">
                            <label className='text-xs text-white'>School Email</label>
                            <input
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                id="email"
                                className="rounded-lg text-black bg-gray-200 mt-2 p-2 focus:border-blue-500 focus:bg-gray-400 focus:outline-none"
                                type={"email"}
                            ></input>
                        </div>
                    </div>

                    <div className='flex flex-row space-x-2'>
                        <div className=" w-1/2 flex flex-col  py-2 mt-1">
                            <label className='text-xs text-white'>School Phone Number</label>
                            <input
                                value={userPhone}
                                onChange={(e) => setUserPhone(e.target.value)}
                                id="phone"
                                className="rounded-lg text-black bg-gray-200 mt-2 p-2 focus:border-blue-500 focus:bg-gray-400 focus:outline-none"
                                type={"phone"}
                            ></input>
                        </div>
                        <div className=" w-1/2 flex flex-col  py-2 mt-1">
                            <label className='text-xs text-white'>School Admin Password</label>
                            <input
                                value={adminPassword}
                                onChange={(e) => setaAminPassword(e.target.value)}
                                id="adminPassword"
                                className="rounded-lg text-black bg-gray-200 mt-2 p-2 focus:border-blue-500 focus:bg-gray-400 focus:outline-none"
                                type={"password"}
                            ></input>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <p className='text-white  text-xs  md:text-sm'>Select Academic Levels Offered</p>
                        {/* md:flex md:flex-wrap md:mt-2 */}
                        <div className=' grid grid-cols-2 md:grid-cols-3 gap-2 mt-2'>
                            
                            {/* <div  className='flex flex-col py-1 md:py-2 border rounded-lg  shadow-md bg-slate-50 hover:bg-slate-400 cursor-pointer'>
                                    <div className='w-full flex flex-row justify-end'>
                                        <div className="flex items-center">
                                            <input  type="checkbox" id="myCheckbox" className="form-checkbox text-blue-500 h-2 w-2 md:h-3 md:w-3 rounded-full mr-2"  />
                                        </div>
                                    </div>
                                    <p className=" text-xs md:text-sm p-1 md:p-2 font-semibold"></p>
                                </div> */}

                                
                            {schoolLevels.map((schoolLevel, index) =>
                                <div onClick={() => toggleSchoolLevels(schoolLevel.academicBlockId, !schoolLevel.selected)} className='flex flex-col py-1 md:py-2 border rounded-lg  shadow-md bg-slate-50 hover:bg-slate-400 cursor-pointer'>
                                    <div className='w-full flex flex-row justify-end'>
                                        <div className="flex items-center">
                                            <input onChange={e => toggleSchoolLevels(schoolLevel.academicBlockId, e.target.checked)} type="checkbox" id="myCheckbox" className="form-checkbox text-blue-500 h-2 w-2 md:h-3 md:w-3 rounded-full mr-2" checked={schoolLevel.selected} />
                                        </div>
                                    </div>
                                    <p className=" text-xs md:text-sm p-1 md:p-2 font-semibold">{schoolLevel.blockName}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <CustomButton
                        buttonStyle={
                            "w-full my-1 py-4 mt-5 bg-teal-500 shadow-lg shadow-teal-500/50 hover:shadow-teal-500/40 rounded-lg text-white text-xs"
                        }
                        buttonText={"Register"}
                        //onClick={() => { registerSchool() }}
                    />
                    <div className="w-full flex flex-row justify-center mt-3 text-xs text-white space-x-2">
                        <p className="font-semibold">Already Registered?</p>
                        <span onClick={() => { navigate('/auth') }} className=" border-b-2 cursor-pointer">Login here</span>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Register
import React from "react";

function Privacy() {
  return (
    <div className="p-5 h-screen bg-gray-100 w-full overflow-y-auto">
      <h1 className="text-3xl font-bold mb-5">
        Privacy Policy for Digischool.app
      </h1>
      <p className="mb-4 text-gray-600">Last updated: October 14, 2024</p>
      <p className="mb-4">
        This Privacy Policy describes our policies and procedures on the
        collection, use, and disclosure of your information when you use the
        Service and tells you about your privacy rights and how the law protects
        you.
      </p>
      <p className="mb-4">
        We use your personal data to provide and improve the service. By using
        the service, you agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-3">
        Interpretation and Definitions
      </h2>
      <h3 className="text-xl font-semibold mb-2">Interpretation</h3>
      <p className="mb-4">
        The following definitions shall have the same meaning regardless of
        whether they appear in singular or in plural.
      </p>

      <h3 className="text-xl font-semibold mb-2">Definitions</h3>
      <ul className="list-disc list-inside space-y-2">
        <li>
          <strong>Account:</strong> A unique account created for you to access
          our service or parts of our service.
        </li>
        <li>
          <strong>Application:</strong> The software program provided by the
          company downloaded by you on any electronic device, named
          Digischool.app
        </li>
        <li>
          <strong>Company:</strong> (referred to as either "the Company", "We",
          "Us" or "Our" in this Agreement) refers to Asapay Limited.
        </li>
        <li>
          <strong>Country:</strong> Kenya
        </li>
        <li>
          <strong>Device:</strong> Any device that can access the service such
          as a computer, a cellphone, or a digital tablet.
        </li>
        <li>
          <strong>Personal Data:</strong> Any information that relates to an
          identified or identifiable individual.
        </li>
        <li>
          <strong>Service:</strong> Refers to the Application.
        </li>
        <li>
          <strong>Service Provider:</strong> Any natural or legal person who
          processes the data on behalf of the company.
        </li>
        <li>
          <strong>Usage Data:</strong> Data collected automatically, either
          generated by the use of the service or from the service infrastructure
          itself.
        </li>
        <li>
          <strong>You:</strong> The individual accessing or using the service,
          or the company, or other legal entity on behalf of which such
          individual is accessing or using the service.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-3">
        Collecting and Using Your Personal Data
      </h2>
      <h3 className="text-xl font-semibold mb-2">Types of Data Collected</h3>

      <h4 className="text-lg font-semibold mb-2">Personal Data</h4>
      <p className="mb-4">
        While using our service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you. Personally identifiable information may include, but is
        not limited to:
      </p>
      <ul className="list-disc list-inside mb-4 space-y-2">
        <li>Name</li>
        <li>Phone number</li>
        <li>Usage Data</li>
        <li>Email address</li>
      </ul>

      {/* Continue adding sections here in a similar format */}

      <h4 className="text-lg font-semibold mb-2">Image Data</h4>
      <p className="mb-4">
        The App allows you to upload images for processing. These images may
        contain personal data, including biometric data (such as facial
        features).
      </p>

      <h4 className="text-lg font-semibold mb-2">Usage Data</h4>
      <p className="mb-4">
        Usage Data is collected automatically when using the Service. Usage Data
        may include information such as your Device's Internet Protocol address
        (e.g. IP address), browser type, browser version, the pages of our
        Service that you visit, the time and date of your visit, the time spent
        on those pages, unique device identifiers and other diagnostic data.
        When you access the service by or through a mobile device, We may
        collect certain information automatically, including, but not limited
        to, the type of mobile device You use, Your mobile device unique ID, the
        IP address of your mobile device, Your mobile operating system, the type
        of mobile Internet browser you use, unique device identifiers and other
        diagnostic data. We may also collect information that your browser sends
        whenever you visit our service or when you access the service by or
        through a mobile device. Use of Your Personal Data The Company may use
        Personal Data for the following purposes:{" "}
      </p>

      <ul className="list-disc list-inside mb-4 space-y-2">
        <li>
          To provide and maintain our Service, including monitoring the usage of
          our service.
        </li>
        <li>
          To manage your account: to manage your registration as a user of the
          service. The personal data you provide can give you access to
          different functionalities of the service that are available to you as
          a registered user.
        </li>
        <li>
          For the performance of a contract: the development, compliance and
          undertaking of the purchase contract for the products, items, or
          services you have purchased or of any other contract with us through
          the Service.
        </li>
        <li>
          To contact you: To contact you by email, telephone calls, SMS, or
          other equivalent forms of electronic communication, such as a mobile
          application's push notifications regarding updates or informative
          communications related to the functionalities, products, or contracted
          services, including security updates, when necessary or reasonable for
          their implementation.
        </li>
        <li>
          To provide you with news, special offers, and general information
          about other goods, services, and events which we offer that are
          similar to those that you have already purchased or enquired about
          unless you have opted not to receive such information.
        </li>
        <li>
          To manage your requests: To attend and manage your requests to us.
        </li>
        <li>
          For business transfers: We may use your information to evaluate or
          conduct a merger, divestiture, restructuring, reorganization,
          dissolution, or other sale or transfer of some or all of Our assets,
          whether as a going concern or as part of bankruptcy, liquidation, or
          similar proceeding, in which personal data held by us about our
          service users is among the assets transferred.
        </li>
        <li>
          For other purposes: We may use your information for other purposes,
          such as data analysis, identifying usage trends, determining the
          effectiveness of our promotional campaigns, and evaluating and
          improving our Service, products, services, marketing, and your
          experience.
        </li>
      </ul>

      <p className="mb-4">
        We do not share, sell, or rent your personal information or uploaded
        images to third parties except: We may share your personal information
        in the following situations:We do not share, sell, or rent your personal
        information or uploaded images to third parties except: We may share
        your personal information in the following situations:
      </p>
      <ul className="list-disc list-inside">
        <li>
          With Service Providers: We may share your personal information with
          service providers to monitor and analyze the use of our service, and
          to contact you.
        </li>
        <li>
          For business transfers: We may share or transfer your personal
          information in connection with, or during negotiations of, any merger,
          sale of company assets, financing, or acquisition of all or a portion
          of our business to another company.
        </li>
        <li>
          With Affiliates: We may share your information with our affiliates, in
          which case we will require those affiliates to honor this Privacy
          Policy. Affiliates include our parent company and any other
          subsidiaries, joint venture partners, or other companies that we
          control or that are under common control with us.
        </li>
        <li>
          With business partners: We may share your information with our
          business partners to offer you certain products, services, or
          promotions.
        </li>
        <li>
          With other users: When you share personal information or otherwise
          interact in the public areas with other users, such information may be
          viewed by all users and may be publicly distributed outside.
        </li>
        <li>
          With Your consent: We may disclose your personal information for any
          other purpose with your consent.
        </li>
      </ul>

      <p className="mb-4">
        Retention of Your Personal Data The Company will retain your personal
        data only for as long as is necessary for the purposes set out in this
        Privacy Policy. We will retain and use your personal data to the extent
        necessary to comply with our legal obligations (for example, if we are
        required to retain your data to comply with applicable laws), resolve
        disputes, and enforce our legal agreements and policies. The Company
        will also retain usage data for internal analysis purposes. Usage data
        is generally retained for a shorter period of time, except when this
        data is used to strengthen the security or to improve the functionality
        of Our Service, or We are legally obligated to retain this data for
        longer time periods. Transfer of Your Personal Data Your information,
        including personal data, is processed at the company's operating offices
        and in any other places where the parties involved in the processing are
        located. It means that this information may be transferred to and
        maintained on computers located outside of your state, province, country
        or other governmental jurisdiction where the data protection laws may
        differ than those from your jurisdiction. Your consent to this Privacy
        Policy followed by your submission of such information represents your
        agreement to that transfer. The company will take all steps reasonably
        necessary to ensure that your data is treated securely and in accordance
        with this Privacy Policy and no transfer of your personal data will take
        place to an organization or a country unless there are adequate controls
        in place including the security of your data and other personal
        information. Disclosure of Your Personal Data Business Transactions If
        the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before your
        personal data is transferred and becomes subject to a different Privacy
        Policy.
      </p>

      <h4 className="text-lg font-semibold mb-2">Law enforcement</h4>
      <p className="mb-4">
      Under certain circumstances, the company may be required to disclose your personal data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
Other legal requirements
The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
      </p>
      <ul className="list-disc list-inside">
  <li>With Service Providers: We may share your personal information with service providers to monitor and analyze the use of our service, and to contact you.</li>
  <li>For business transfers: We may share or transfer your personal information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
  <li>With Affiliates: We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include our parent company and any other subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.</li>
  <li>With business partners: We may share your information with our business partners to offer you certain products, services, or promotions.</li>
  <li>With other users: When you share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</li>
  <li>With Your consent: We may disclose your personal information for any other purpose with your consent.</li>
</ul>

<h4 className="text-lg font-semibold mb-2">Security of Your Personal Data</h4>
<p className="mb-4">
The security of your personal data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal data, We cannot guarantee its absolute security.
</p>

<h4 className="text-lg font-semibold mb-2">Children's Privacy</h4>
<p className="mb-4">
Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If you are a parent or guardian and you are aware that your child has provided us with personal data, please contact us. If we become aware that we have collected personal data from anyone under the age of 13 without verification of parental consent, we take steps to remove that information from our servers.
If we need to rely on consent as a legal basis for processing your information and your country requires consent from a parent, we may require your parent's consent before we collect and use that information.
</p>

<h4 className="text-lg font-semibold mb-2">Changes to this Privacy Policy</h4>
<p className="mb-4">
We may update Our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
We will let you know via email and/or a prominent notice on our service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.
You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
</p>




      <h2 className="text-2xl font-semibold mt-6 mb-3">Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, you can contact us:
      </p>
      <ul className="list-disc list-inside mt-4 space-y-2">
        <li>
          By email:{" "}
          <a href="mailto:digischoolke@gmail.com" className="text-blue-500">
            digischoolke@gmail.com
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Privacy;

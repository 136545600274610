import React, { Children } from 'react'

function Modal({isVisible , onClose , children}) {

    const handleCloseDialog = (e) => {
        if(e.target.id === 'modal') {
          onClose();
        }
      }

    // if(!isVisible){return null}

  return (
    <div onClick={handleCloseDialog} id="modal" className={`fixed inset-0 bg-black bg-opacity-25  z-50 backdrop-blur-sm flex justify-center items-center transition-opacity duration-300 ${
      isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
     {/* <div onClick={handleCloseDialog} id="modal" className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center transition-opacity duration-600"> */}
            <div className="w-[600px] flex flex-col  m-14 p-5 md:p-1">
                <button onClick={onClose} className="text-sm bg-white rounded-full w-6 h-6 p-1 m-2 flex items-center place-self-end">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>

                </button>
                <div className="bg-white p-2 rounded">
                    {children}
                </div>
            </div> 
        </div>
  )
}

export default Modal